@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
/* Using example */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.position_sticky_poste {
  position: sticky;
  width: 100%;
  background-color: #ffffff;
  z-index: 9;
  top: 6.2rem;
}
.link_footerPage {
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #3F3F51;
}
.link_footerPage:hover {
  text-decoration: underline;
  color: #3F3F51;
}
.padding-right-loupe {
  padding-right: 23px !important;
}
.icones_pause_repas_view {
  margin-left: 1rem;
}
.no_margin_top {
  margin-top: 0 !important;
}
.disable-element {
  background: #F9FAFC !important;
  background-color: #F9FAFC !important;
}
.transparent {
  opacity: 0;
}
.pre-wrap {
  white-space: pre-wrap;
}
.webcam_hidden {
  opacity: 0 !important;
  position: absolute;
  top: 0;
  z-index: 6;
}
.break_text {
  white-space: break-spaces;
}
.break_text_wrap {
  white-space: pre-wrap !important;
}
.no_break_text {
  white-space: nowrap;
}
.no_border_radius {
  border-radius: 0 !important;
}
.icones_upload_file {
  width: 344px;
  height: 39px;
  margin-bottom: -25px;
}
.inherit_from_parent {
  height: inherit !important;
}
.btn_close_custom {
  top: -2rem;
  right: -1rem;
}
.avatar_infopers {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container_repartition_custom {
  min-height: fit-content !important;
  padding-bottom: 0 !important;
  margin-bottom: 2.813rem;
}
.shape_open {
  transform: rotate(90deg);
}
.shape_down {
  transform: rotate(90deg);
}
.hidden {
  display: none;
}
.display_grid {
  display: grid;
}
.margin_top_hours {
  margin-top: -38px;
}
.no_padding {
  padding: inherit !important;
}
.padding_0 {
  padding: 0 !important;
}
.margin_0 {
  margin: 0 !important;
}
.cursor_pointer {
  cursor: pointer !important;
}
.main-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}
.main-content ::-webkit-scrollbar-track {
  background: var(--whiteColor) !important;
}
.alert.position_alert {
  z-index: 9;
}
.text_align_center {
  text-align: center !important;
}
.content_invalid {
  position: relative;
}
.content_invalid .color_invalid {
  position: absolute;
  bottom: -1.3rem;
  left: 0;
}
.content_invalid .color_invalidParameter {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: small;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
.view_less_more_app {
  color: #005c9c;
  border: 0;
  background: transparent;
  margin: 0.7rem;
  font-size: 12px;
}
.OrangeThemeSelect::-moz-selection {
  /* Code for Firefox */
  background-color: var(--selectTextOrange) !important;
}
.OrangeThemeSelect::selection {
  background-color: var(--selectTextOrange) !important;
}
.GreenThemeSelect::-moz-selection {
  /* Code for Firefox */
  background-color: var(--loaderGreen) !important;
}
.GreenThemeSelect::selection {
  background-color: var(--loaderGreen) !important;
}
.BlueThemeSelect::-moz-selection {
  /* Code for Firefox */
  background-color: var(--loaderBlue) !important;
}
.BlueThemeSelect::selection {
  background-color: var(--loaderBlue) !important;
}
.BlueParamThemeSelect::-moz-selection {
  /* Code for Firefox */
  background-color: var(--loaderParams) !important;
}
.BlueParamThemeSelect::selection {
  background-color: var(--loaderParams) !important;
}
.table_list_vacation_bottom {
  margin-bottom: 5rem !important;
}
/*CheckBox*/
.form_checkbox {
  display: flex;
  flex: 1;
  align-items: center;
}
.form_checkbox .form_group {
  margin-bottom: none;
}
.form_checkbox .form_group label {
  position: relative;
  cursor: pointer;
}
.form_checkbox .form_group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form_checkbox .form_group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--secondColor);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 2px;
}
.form_checkbox .form_group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--secondColor);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/*End CheckBox*/
/* Message de notifications */
.alert {
  z-index: 9;
}
.alert p {
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 14px;
  letter-spacing: 0.02rem;
  margin: 0;
}
/* End Message de notifications */
.input_number_shape #shapeUp {
  transform: none;
  top: 0.75rem;
  right: 2rem;
}
/* End Message de notifications */
.right_space_btn {
  margin-right: 1.5rem;
}
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.flex-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.flex-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.flex-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.flex-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.flex-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.flex-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.flex-align-strecth {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}
.flex-align-self-center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-self-end {
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
.flex-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-one {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.text_align_end {
  text-align: end !important;
}
.position_relative {
  position: relative;
}
.position_absolute {
  position: absolute;
}
.position_fixed {
  position: fixed;
}
.no-outline-decoration {
  outline: none;
}
.capitalise {
  text-transform: capitalize;
}
.underline {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
.no_white_space {
  white-space: nowrap;
}
.white_space_wrap {
  white-space: pre-wrap;
}
.no_border {
  border: 0 !important;
}
.no_border_bottom {
  border-bottom: 0 !important;
}
.fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.fit-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.span_left_comment {
  margin-left: 50%;
}
.link_support_arh {
  bottom: 135px;
  right: 0;
  text-decoration: none;
  width: 49px;
  z-index: 9;
}
.link_support_arh img {
  width: 100%;
}
.status.actif {
  background-color: #49d4ab;
}
.status.temporaire {
  background-color: #f5d04b;
}
.status.inactif {
  background-color: #f36242;
}
.status.blue {
  background-color: #4a98e0;
}
.status.yellow {
  background-color: #fdd548;
}
.status.red {
  background-color: #f36242;
}
.status.babyblue {
  background-color: #125594;
}
.status.inactive {
  background-color: #b4b6b5;
}
.status.purple {
  background-color: #d549c7;
}
.status_with_select {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  right: 0.6rem;
}
.visible {
  display: block;
}
.not_visible {
  display: none;
}
.modal-backdrop {
  background-color: #535d5f !important;
}
.btn_connexion {
  background-color: var(--firstColor);
  border: 0;
  width: 100%;
  padding: 10px 95px;
  color: var(--whiteColor);
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.btn_connexion:hover {
  background-color: #FFD1BC;
}
.not_hover_add_hours:hover {
  background-color: var(--firstColor) !important;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.timezone-picker {
  width: 100%;
}
.timezone-picker .timezone-picker-textfield .form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.625rem 0.75rem;
  font-family: "Poppins_Regular";
  font-size: 12px;
  line-height: 1.5;
  color: #8898aa;
  background-color: var(--whiteColor);
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  box-shadow: none;
  transition: all 0.2s;
}
#root .alert {
  position: fixed;
  top: 0;
  left: 0;
  border: 0;
  border-radius: 0;
  color: var(--whiteColor);
  font-size: 15px;
  padding: 0.3rem 1.5rem;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}
.warning_for_parameter {
  padding: 0.3rem 1.5rem;
  border: 1px solid #0277bd;
  border-radius: 0;
  font-size: 15px;
  width: 25%;
  top: 0;
  position: absolute;
  text-align: center;
  color: #0277bd;
  z-index: 1;
  margin-bottom: 0;
}
.dropdown_select.maxHeight_161 {
  max-height: 10rem;
}
.main-content {
  position: initial !important;
}
.alert-danger {
  color: var(--whiteColor);
  background-color: #e9b043 !important;
}
.name_brand {
  margin-left: 1.5rem;
}
.nothingImageNews {
  height: 0 !important;
}
.badgeNewsCompte {
  background-color: var(--firstColor);
  margin: 0 1rem;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: var(--whiteColor);
  font-size: 12px;
}
.btn_action_pointage_header .loader {
  display: none;
  background: transparent;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}
.btn_action_pointage_header .loader .loaderBlue {
  background: var(--loaderBlue);
}
.btn_action_pointage_header .loader .loaderOrange {
  background: var(--loaderOrange);
}
.btn_action_pointage_header .loader .loaderGreen {
  background: var(--loaderGreen);
}
.btn_action_pointage_header .loader .loaderWhite {
  background: var(--whiteColor);
}
.btn_action_pointage_header .loader > div {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 2px;
  animation: loader 0.6s infinite alternate;
}
.btn_action_pointage_header .loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.btn_action_pointage_header .loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes loader {
  to {
    transform: translate(0, 5px);
  }
}
.btn_action_pointage_header {
  margin-right: 1rem;
  border: 1px solid var(--btnNotifBorder);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem 0.5rem 0.5rem 0;
  background: linear-gradient(0deg, var(--whiteColor), var(--whiteColor)), rgba(252, 182, 152, 0.2);
  width: 13.5rem;
  border-left: 0.5rem solid rgba(233, 73, 22, 0.87);
}
.btn_action_pointage_header .text_btn_pointage {
  color: var(--darkColor);
  font-size: 12px;
}
.btn_action_pointage_header.active {
  border-left: 1px solid var(--btnNotifBorder);
  padding: 0.5rem;
}
.btn_action_pointage_header.active .loader {
  display: flex;
}
.headerMenu_items.parametersColorMenu:hover,
.headerMenu_items.parametersColorMenu:focus,
.headerMenu_items.parametersColorMenu.active {
  border-bottom: 7px solid var(--loaderParams) !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}
.headerMenu_items.parametersColorMenu:hover:hover:before,
.headerMenu_items.parametersColorMenu:focus:hover:before,
.headerMenu_items.parametersColorMenu.active:hover:before,
.headerMenu_items.parametersColorMenu:hover:focus:before,
.headerMenu_items.parametersColorMenu:focus:focus:before,
.headerMenu_items.parametersColorMenu.active:focus:before,
.headerMenu_items.parametersColorMenu:hover.active:before,
.headerMenu_items.parametersColorMenu:focus.active:before,
.headerMenu_items.parametersColorMenu.active.active:before {
  right: 0;
}
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:hover,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:hover,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:hover,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:focus,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:focus,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:focus,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu.active,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu.active,
.headerMenu_items.parametersColorMenu.active.employerColorMenu.active {
  border-bottom: 7px solid var(--loaderGreen) !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:hover:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:hover:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:hover:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:focus:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:focus:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:focus:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu.active:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu.active:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu.active:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: var(--loaderGreen);
  height: 7px;
  -webkit-transition-property: right;
  -o-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:hover:hover:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:hover:hover:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:hover:hover:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:focus:hover:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:focus:hover:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:focus:hover:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu.active:hover:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu.active:hover:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu.active:hover:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:hover:focus:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:hover:focus:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:hover:focus:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:focus:focus:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:focus:focus:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:focus:focus:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu.active:focus:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu.active:focus:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu.active:focus:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:hover.active:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:hover.active:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:hover.active:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu:focus.active:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu:focus.active:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu:focus.active:before,
.headerMenu_items.parametersColorMenu:hover.employerColorMenu.active.active:before,
.headerMenu_items.parametersColorMenu:focus.employerColorMenu.active.active:before,
.headerMenu_items.parametersColorMenu.active.employerColorMenu.active.active:before {
  right: 0;
}
.parametersColor {
  background-color: var(--loaderParams) !important;
  border-color: var(--loaderParams) !important;
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.parametersColor:hover {
  background-color: var(--loaderBlue) !important;
  border-color: transparent !important;
}
.parametersColor_disabled {
  background-color: var(--greyColor) !important;
  color: #AFAFAF !important;
  border-color: #AFAFAF !important;
}
.employerBackColor {
  background-color: var(--loaderGreen) !important;
  border-color: var(--loaderGreen) !important;
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.employerBackColor:hover {
  border-color: transparent;
  background-color: #5eb1a2 !important;
}
.close_modals_employer {
  background-color: var(--loaderGreen) !important;
}
.input_file_theme_employer .progressSize {
  background-color: var(--loaderGreen) !important;
}
.newsSvgColor svg path {
  fill: var(--loaderBlue);
}
.newsSvgColor .custom_radio.active {
  border: 1px solid var(--loaderBlue) !important;
}
.newsSvgColor .custom_radio:after {
  background-color: var(--loaderBlue) !important;
}
.newsSvgColorPath svg #path1 {
  fill: var(--loaderBlue);
}
.newBackTheme {
  background-color: var(--loaderBlue) !important;
  border-color: var(--loaderBlue) !important;
}
.newBackTheme:hover {
  background-color: var(--loaderParams) !important;
  border-color: transparent !important;
}
.switch_color_theme_news .MuiSwitch-track {
  background-color: var(--loaderBlue) !important;
}
.switch_color_theme_news .MuiSwitch-colorSecondary {
  color: var(--loaderBlue) !important;
}
.switch_color_theme_news .MuiFormControl-root {
  width: auto !important;
}
.switch_color_theme_document .MuiSwitch-track {
  background-color: var(--greenSwitch) !important;
}
.switch_color_theme_document .MuiSwitch-colorSecondary {
  color: var(--greenColor) !important;
}
.input_file_theme_news .progressSize {
  background-color: var(--loaderBlue) !important;
}
.input_file_theme_organisator .progressSize {
  background-color: #95C5E7 !important;
}
.pays_add_employer_place .input_with_loupe svg {
  top: 21px;
}
.spaceRight {
  margin-right: 1rem;
}
.spaceBottom {
  margin-bottom: 4rem;
}
.custom_top_emplacement_multiple {
  margin-top: 1.438rem;
}
.title_reset_pasword {
  font-size: 25px;
  text-align: center;
  margin-bottom: 1rem;
}
.spaceHeader_webcam {
  margin-bottom: 2rem;
}
.dropdown_select.fill_available_width {
  width: -webkit-fill-available;
}
.dzu-dropzone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  flex-direction: unset !important;
}
.dzu-inputLabel {
  font-weight: unset;
}
.dzu-inputLabelWithFiles {
  padding: 0 2%;
  width: auto;
  border-radius: 2px;
  color: var(--whiteColor) !important;
  white-space: nowrap;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0 14px;
  min-height: 32px;
  border: none;
  font-family: 'Poppins', sans-serif;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 45px !important;
}
.news_theme_upload .dzu-inputLabelWithFiles {
  background: var(--loaderBlue);
}
.default_theme_upload .dzu-inputLabelWithFiles {
  background: var(--firstColor);
}
.employer_theme_upload .dzu-inputLabelWithFiles {
  background: var(--loaderGreen);
}
.width_loader {
  width: 100%;
}
.btn_showMiniMenu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  left: -2.7%;
  background: var(--whiteColor);
  border-radius: 3px;
  border: 0;
  width: 1.75rem;
  height: 1.625rem;
}
#sidenav-main.active_mini_sidebar {
  max-width: 70px;
}
#sidenav-main.active_mini_sidebar .container-fluid .logo_brand_arh {
  position: absolute;
  left: -2rem;
  top: -1rem;
  width: 5.188rem;
  height: 4.75rem;
}
#sidenav-main.active_mini_sidebar .container-fluid .navbar-nav {
  padding: 0;
  margin-top: 3.438rem;
}
#sidenav-main.active_mini_sidebar .name_menu_sidebar {
  display: none;
  color: var(--colorSideBar) !important;
}
#sidenav-main.active_mini_sidebar .log_out_text {
  display: none;
}
#sidenav-main.active_mini_sidebar .title_menu_sidebar {
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
}
#sidenav-main.active_mini_sidebar .title_menu_sidebar_enterprise {
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
}
#sidenav-main.active_mini_sidebar .nav-link {
  padding: 0.5rem 0 0.5rem 1.5rem !important;
}
#sidenav-main.active_mini_sidebar .button_logout_footer {
  padding: 0 !important;
}
#sidenav-main.active_mini_sidebar .button_logout_footer .logout_footer {
  margin-right: 0 !important;
}
#navbar-main.navBar_mini_sideBar {
  width: calc((100% - 70px) - 3.5%);
}
.active_mini_sidebar.sidebar_arh .nav-link {
  padding: 0.5rem 0 0.5rem 1.5rem !important;
}
.navbar-vertical.navbar-expand-md.fixed-left + .main-content.active_mini_sidebar_container {
  margin-left: 70px;
  width: calc(100% - 70px);
}
.navbar-vertical {
  scrollbar-width: none;
}
.navbar-vertical ::-webkit-scrollbar-track {
  background: var(--whiteColor) !important;
}
.color_blue_for_employee_definitely_inactive {
  background-color: #ccffff;
}
.bg_white {
  background-color: var(--whiteColor);
}
.row-gap-45 {
  row-gap: 45px;
}
.mt-38 {
  margin-top: 2.375rem;
}
.p-53 {
  padding: 0 3.313rem;
}
.w-48 {
  width: 48%;
}
.mb-11 {
  margin-bottom: 0.688rem;
}
.mb-36 {
  margin-bottom: 2.25rem;
}
.margx-auto {
  margin: 0 auto !important;
}
.w-auto {
  width: auto !important;
}
.text-underlined {
  text-decoration: underline !important;
}
/* .navbar-nav {
  .title_menu_sidebar {
    font-family: 'PoppinsSemiBold', sans-serif;
  }
} */
.pb-53 {
  padding-bottom: 3.313rem;
}
/* **** 
   Media query datepicker 
   **** 
*/
@media only screen and (max-width: 1600px) {
  .datepicker_modals_parcours .react-datepicker-popper {
    width: 140%;
  }
  .datepicker_modals_parcours .react-datepicker {
    width: 100%;
  }
}
/* **** 
   Media query datepicker 
   **** 
*/
.text-center {
  text-align: center !important;
}
.react-form-builder .react-form-builder-preview .edit-form {
  color: var(--darkColor);
  font-family: Poppins_Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: left;
}
.react-form-builder .react-form-builder-preview .edit-form .form-group > label {
  display: none;
}
.react-form-builder .react-form-builder-preview .edit-form h4 {
  color: var(--darkColor);
  font-weight: 500;
}
.react-form-builder .react-form-builder-preview .edit-form .dismiss-edit {
  font-size: 16px;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list .col-sm-1 {
  display: none !important;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list .col-sm-2 {
  display: none !important;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list .col-sm-4 {
  display: none !important;
}
.react-form-builder .react-form-builder-preview.is-editing .edit-form {
  border-right: none !important;
  background: #f9fafc;
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.07) !important;
}
.font_blacks {
  color: #292929;
}
/** Color Flash **/
.colorFlash {
  -webkit-animation: color 7s;
  -moz-animation: color 7s;
  animation: color 7s;
}
@-webkit-keyframes color {
  100% {
    background: var(--greyColor);
  }
}
@-moz-keyframes color {
  100% {
    background: var(--greyColor);
  }
}
@keyframes color {
  100% {
    background: var(--greyColor);
  }
}
/**End Coor Flash **/
.modal-content .alert_message_error {
  top: 0;
  width: 100%;
  right: 0;
  margin: 0 !important;
  padding: 0.4rem !important;
  border: 0 !important;
  border-radius: 0 !important;
  position: absolute !important;
}
.modal-content .alert_message_error p {
  text-align: center;
}
.modal_recruit_candidates_altert .alert p {
  color: #fff !important;
}
.content_liste_etiquette {
  margin-bottom: 8px;
}
.content_liste_etiquette .listItem {
  padding: 14px 40px 15px 14px !important;
  margin-bottom: 0 !important;
}
.content_liste_etiquette .listItem .MuiRadio-root {
  padding: 0 !important;
  margin-right: 7px;
}
.content_liste_etiquette .delete_etiquette_candidates {
  color: #44796f;
  cursor: pointer;
  width: 20px;
  right: 4px;
  height: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.rbc-day-slot.activeScroll {
  box-shadow: 0 0.5rem 2rem 0.5rem rgba(0, 0, 0, 0.2);
}
.no_border_table {
  border-bottom: 0 !important;
  border-top: 0 !important;
  border: 0 !important;
}
.no_border_top {
  border-top: 0 !important;
}
.no_padding_table {
  padding: 0 !important;
}
.float_right {
  float: right;
}
.msg_padding {
  padding: 2rem 6rem 0;
}
.p-36 {
  padding: 2.25rem;
  margin-bottom: 18px;
}
.p-2rem {
  padding: 2rem;
}
.p-badge-content {
  flex-wrap: wrap;
  margin-left: 25px;
  padding-top: 2px;
  display: flex;
}
.p-badge {
  flex-wrap: wrap;
  padding: 5px 1rem !important;
  margin: 6px !important;
  height: auto !important;
  font-size: 12px;
  border-radius: 23px;
}
.p-badge span {
  white-space: pre-wrap;
  padding-right: 10px;
}
.p-badge-green {
  color: #44796f;
  background: rgba(115, 199, 183, 0.42);
}
.p-badge-purple {
  background-color: rgba(225, 72, 176, 0.2);
  color: #e148b0;
}
.p-badge-blue {
  color: #4a75a3;
  background: rgba(122, 186, 231, 0.42);
}
.padding_groupe {
  padding: 0.7rem !important;
}
.ellipse_poste {
  max-width: 20rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.custom_tooltip {
  color: white !important;
}
.custom_tooltip h3 {
  color: white !important;
}
.link_new_affichage a {
  max-width: 100% !important;
  width: 100%;
  border: 2px solid var(--whiteColor);
}
.link_new_affichage .react_tinylink_card_content_description,
.link_new_affichage .react_tinylink_card_content_header_description,
.link_new_affichage p {
  word-break: break-all !important;
  word-break: keep-all !important;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: var(--whiteColor) !important;
  border-radius: 9px !important;
  border: none !important;
  padding: 0.625rem;
  max-height: 13rem;
  overflow-y: scroll;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
}
.container_datePickerDropdown .react-datepicker__month-container {
  font-family: 'Poppins_Semibold';
}
.container_datePickerDropdown .react-datepicker__year-dropdown,
.container_datePickerDropdown .react-datepicker__month-dropdown,
.container_datePickerDropdown .react-datepicker__month-year-dropdown {
  background-color: #ffff !important;
  border-radius: 2px !important;
  border: 1px solid var(--whiteColor) !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  max-height: 14rem;
  width: 100%;
  left: 0;
  top: 4.688rem;
  overflow-y: scroll;
}
.container_datePickerDropdown .react-datepicker__year-option,
.container_datePickerDropdown .react-datepicker__month-option,
.container_datePickerDropdown .react-datepicker__month-year-option {
  font-weight: 600;
  color: #313131;
  padding: 0.625rem 1.5rem;
  margin: 0;
  text-align: start !important;
  background: #fff;
  font-size: 0.875rem;
  text-transform: capitalize;
  border-radius: 3px;
}
.container_datePickerDropdown .react-datepicker__year-option:hover,
.container_datePickerDropdown .react-datepicker__month-option:hover,
.container_datePickerDropdown .react-datepicker__month-year-option:hover {
  background: #f9fafc !important;
  cursor: pointer;
}
.container_datePickerDropdown .react-datepicker__header__dropdown {
  font-size: 0.875rem;
  text-transform: capitalize;
  margin: 0.2rem 0 0.6rem;
  padding: 0;
}
.container_datePickerDropdown .react-datepicker__month-read-view--selected-month,
.container_datePickerDropdown .react-datepicker__year-read-view--selected-year {
  font-size: 0.875rem;
}
.container_datePickerDropdown .react-datepicker__day:hover {
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
}
.li_list_hover:hover {
  background: #F9FAFC !important;
  color: #313133 !important;
  border-radius: 0 !important;
}
.li_list {
  position: relative;
  height: 38px;
  align-items: center;
  font-size: 13px;
  padding-left: 1rem;
  cursor: pointer;
}
.li_list:hover {
  background: #F9FAFC;
  color: #313133;
  border-radius: 0;
}
.li_list-active {
  background: var(--loaderParams);
  border-radius: 0;
  color: white;
}
.table-no-sticky-shadow .table-materials.table_employer th:first-child,
.table-no-sticky-shadow .table-materials.table_employer td:first-child {
  position: relative !important;
  box-shadow: none !important;
}
.table-no-sticky-shadow .table-materials.table_employer th:first-child::before,
.table-no-sticky-shadow .table-materials.table_employer td:first-child::before {
  display: none;
}
.content_modalColor_pipeline {
  padding: 2.063rem 0;
}
.content_modalColor_pipeline .form_group {
  width: 100%;
}
.footBtn_modal--link {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.5rem;
}
.footBtn_modal--note {
  padding: 0 2.5rem !important;
}
.footBtn_modal--createHours {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 3rem 0.6rem 0;
}
.footBtn_modal--conge {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1.5rem;
}
.modalDeplace_folder_file .content_body_permission {
  width: 83%;
}
.footBtn_modal--deleteAnif {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footBtn_modal--deleteAnif .alt_modalFooter_container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 0;
}
.footBtn_modal--deleteAnif .modalFooter {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footBtn_modal--timeZone {
  padding: 0 7rem;
  margin-bottom: 3rem;
}
.alt_modalFooter_container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 0;
}
.alt_modalFooter_container .loader_addConge {
  display: flex;
  justify-content: center;
  width: 100%;
}
.modalFooter,
.content-btn {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.modalFooter--loading {
  justify-content: center !important;
}
.modalFooter--hours {
  padding: 2.688rem 0 3.375rem;
}
.modalFooter--hours .btn_hours {
  font-size: 14px;
  height: 2.813rem;
}
.modalFooter--noBorder,
.content-btn {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.modalFooter--noBorder .modal-btn.active,
.content-btn .modal-btn.active {
  margin-left: 0;
}
.padding_modal_timeZone {
  padding: 0 8rem 5rem !important;
}
.ellipse_3lines {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.fade-enter {
  opacity: 0;
}
.fade-appear {
  opacity: 0;
}
.fade-appear-active {
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0, 0.5, 1, 1);
  transition-duration: 200ms;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0, 0.5, 1, 1);
  transition-duration: 200ms;
}
.react-datepicker-wrapper input::placeholder {
  color: #9b9ea0 !important;
}
.arh-border-spacing-y-2 {
  border-spacing: 0 0.5rem;
}
.field_invalid {
  background-color: var(--backgroundInputError) !important;
  border-color: #F60A20 !important;
}
.field_invalid::placeholder {
  color: #F60A20 !important;
}
.field_invalid::-moz-placeholder {
  color: #F60A20 !important;
}
/* Progression  */
.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #929292;
  height: 4rem;
}
.steps > .step {
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 0.875rem;
  color: #6D6875;
}
.steps .stepOne.is-complete:after {
  content: '';
  position: absolute;
  display: block;
  background: linear-gradient(270deg, #A7EBDE 0%, #D3F0C9 39.15%, #F8F1B6 55.93%, #F7F7F7 75.1%, #F7F7F7 100%);
  width: 300%;
  height: 20px;
  top: 1rem;
  left: 50%;
  border-radius: 100px;
}
.steps {
  margin-bottom: 3;
}
/* End progression detail evaluation */

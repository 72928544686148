@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.cdnfonts.com/css/bebas-neue");

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .org-btn {
    @apply arh-w-full arh-border arh-border-solid arh-border-greenColor arh-bg-cardGreen arh-text-[11px] arh-text-greenColor arh-rounded-[5px] hover:arh-bg-lightGreenHover hover:arh-border-lightGreenHover hover:arh-text-textBlackBtnHover arh-py-2 arh-pointer-events-auto arh-cursor-pointer arh-transition arh-duration-200 arh-ease-in;
  }

  .arh-toolbar-nav {
    @apply arh-flex arh-justify-center arh-items-center arh-size-8 arh-border arh-border-solid arh-border-greyBorderInput arh-rounded-full arh-transition-colors hover:arh-border-loaderOrange;
  }
}

/* Height:moins du mesure du top par rapport au parent */
.arh-height-collapse-response {
  height: calc(100% - 7.8rem);
}

/* Invalid label */
.error_label {
  color: #F60A20 !important;
}

/* End invalid label */

.arh-avatar-assigned ~ .avatar_employer {
  margin-left: -0.5rem;
}

.arh-content-protocole .form_group_pointeuse .input_pointeuse.active {
  border: 1px solid #73C7B7;
}

.arh-content-protocole .content_items_pointeuse.active {
  border-left: 5px solid #73C7B7;
}

.arh-upload-createTask {
  margin: 0 0 1.5rem !important;
}

.arh-upload-createTask .titleUpload_file--big {
  display: none !important;
}

.arh-list-etiquette-fullPosition {
  top: 0 !important;
  right: 0 !important;
}

.arh-text-align-right-table {
  text-align: right !important;
}

.arh-pr-14-table {
  padding-right: 80px !important;
}

.arh-datePicker-entreprise-sittings input {
  font-size: 0.75rem !important;
  padding-left: 1.5rem !important;
}

input.arh-datePicker-entreprise-sittings {
  font-size: 0.75rem !important;
  padding-left: 1.5rem !important;
}

.arh-py-table {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.arh-bg-orange {
  background-color: #FFEFE8;
}

.arh-text-orange {
  color: #DA5014;
}

.arh-bg-blue {
  background-color: #E2EBF9;
}

.arh-text-blue {
  color: #1D3E71;
}

.arh-bg-green {
  background-color: #E6F8EF;
}

.arh-text-green {
  color: #295143;
}

.arh-bg-blue-cyan {
  background-color: #D2DDE1;
}

.arh-text-blue-cyan {
  color: #203D48;
}

.arh-text-baby-blue {
  color: #1A4E63;
}

.arh-bg-baby-blue {
  background-color: #D3E3F1;
}

.arh-transition-btnAdd {
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}


/* Nouvelle affichage */
.fourImage1 {
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
}

.fourImage2 {
  right: 0;
  top: 0;
  width: 50%;
  height: 50%;
}

.fourImage3 {
  left: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
}

.fourImage4 {
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
}

.oneImage {
  width: 100%;
  height: 100%;
}

.twoImage1,
.twoImage2 {
  width: 50%;
  height: 100%;
}

.threeImage1 {
  width: 50%;
  height: 100%;
}

.threeImage2 {
  width: 50%;
  height: 50%;
}

.threeImage3 {
  width: 50%;
  height: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.arh-max-h-new-right {
  max-height: calc(100vh - 13rem);
}

.arh-w-laneDivider {
  width: calc(100% + 100px);
}

.arh-add-news-editor .floating-text-format-popup {
  top: 0 !important;
  transform: inherit !important;
}

.view_news .editor-listitem {
  pointer-events: none;
}

.news-lexical .editor-listitem {
  pointer-events: none;
}

/* End Nouvelle affichage */

/* color checkbox document Ma fiche*/
.content_body_permission .rct-collapse-btn .rct-icon-expand-open {
  background-color: var(--greenColor);
  border: 1px solid var(--greenColor);
}

.content_body_permission .rct-icons-fa4 .rct-icon-uncheck::before {
  color: var(--greenColor);
}

.content_body_permission .rct-icons-fa4 .rct-icon-check::before,
.rct-icons-fa4 .rct-icon-half-check::before {
  color: var(--greenColor);
}

/* End color checkbox document Ma fiche*/

.theme-blue .content_body_permission .rct-collapse-btn .rct-icon-expand-open {
  background-color: var(--loaderParams) !important;
  border: 1px solid var(--loaderParams) !important;
}

.theme-blue .content_body_permission .rct-icons-fa4 .rct-icon-uncheck::before {
  color: var(--loaderParams) !important;
}

.theme-blue .content_body_permission .rct-icons-fa4 .rct-icon-check::before,
.rct-icons-fa4 .rct-icon-half-check::before {
  color: var(--loaderParams) !important;
}

/* Les inputs */
.focus_blue_param:focus {
  border: 1px solid var(--loaderParams) !important;
  caret-color: var(--loaderParams);
}

.field_invalid {
  border: 1px solid rgba(246, 10, 32, 0.5) !important;
  background-color: #FFF3F4 !important;
  color: #F60A20 !important;
}

.field_invalid::placeholder {
  color: #F60A20 !important;
}

/* End les inputs */

/* pipeline */
.arh-widthCalc-filter {
  width: calc(50% - 1.625rem);
}

.arh-widthCalc-filter-modele {
  width: calc(50% - 23px);
}

/* End pipeline */

/* Connexion */
.arh-logo-connexion svg {
  width: 1.063rem;
  height: 1.063rem;
}


.arh-btn-connexion::after {
  display: inline-block;
  padding-left: 8px;
  content: "\0279E";
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.arh-btn-connexion:hover {
  color: #D15825;
  background-color: #FFD1BC;
}

.arh-btn-connexion:hover::after {
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  -ms-transform: translateX(4px);
  -o-transform: translateX(4px);
  transform: translateX(4px);
}

.arh-btn-register:hover {
  color: #D15825;
  background-color: #FFD1BC;
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

/* End Connexion */

/*hide scroll image to print */
.imgToPrint .pg-viewer-wrapper {
  overflow-y: inherit !important;
}

.arh-file canvas {
  object-fit: contain;
}

/* Calendar Green select */
/* .DateRangePicker_customisation--green .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range.react-datepicker__day--today {
  border-radius: 0 !important;
  background-color: var(--rangeDateGreen) !important;
  color: #292929;
  position: relative;
} */
/* End Calendar green select */

/* Collapse slide matériel et employer importation */
.arh-slide-collapse {
  transform: translateX(535px);
  transition: 300ms ease-in-out;
}

.arh-slide-collapse--expanded {
  transform: translateX(0);
}

.arh-slide-collapse-icon {
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.arh-slide-collapse-icon-active svg path {
  fill: #616161;
}

.arh-modal-btn {
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.arh-modal-btn[disabled] {
  color: #5A5A5A;
  background-color: var(--greyColor) !important;
  border: solid 1px var(--borderLoginColor);
  border-radius: 3px;
  cursor: not-allowed;
  opacity: 0.7;
}


/* END Collapse slide matériel et employer importation */

/* Folder Employer */
.arh-icones-timer-no {
  transform: rotate(-90deg);
}

/* End Folder Employer */

.arh-rotate-custom-0 {
  --tw-rotate: 0;
  /* transform: rotate(var(--tw-rotate)); */
}

.arh-rotate-custom-90 {
  --tw-rotate: 90deg;
  /* transform: rotate(var(--tw-rotate)); */
}


/* Lexical Editor */
.bordered-lexical .editor-input {
  max-height: 9rem;
  min-height: 250px;
  overflow-y: auto;
}

.bordered-lexical .editor-input iframe {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}

.arh-boxShadow-lexical_editor {
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

/* End Lexical Editor */


.arh-max-w-name-table {
  width: calc(100% - 1.5rem);
  max-width: calc(100% - 1.5rem);
}

.arh-max-w-parent-table-evaluation {
  width: calc(100% - 12px);
  max-width: calc(100% - 12px);
}


/* Evaluation Details */
.arh-detail-canvasSvg canvas,
.arh-detail-canvasSvg svg {
  width: 100% !important;
}

.arh-max-h-commentAssesment {
  max-height: calc(100vh + 7.5rem);
}

/* End Evaluation Details */

/* *Media Query indispensable */
@media screen and (max-height: 700px) {
  .arh-max-h-new-right {
    max-height: calc(100vh + 2.8rem);
  }

  .arh-overflow-inherit {
    overflow: inherit !important;
  }
}

/* End Media Query */

/*Eliminate i-frame on error*/
body > iframe[style*="2147483647"] {
  display: none !important;
}

.arh-tree ol {
  position: relative;
  border-left: 2px dotted var(--colorgrey);
  margin-left: 36px !important;
}

.arh-tree li {
  position: relative;
}

.arh-tree .react-checkbox-tree {
  margin-bottom: 2rem;
}

.arh-tree label {
  font-size: 13px;
  margin-left: 1.5rem;
}

.arh-tree .rct-node-parent ol .rct-node-leaf .rct-title {
  font-family: "Poppins";
  font-style: auto;
  font-weight: 400;
  font-size: 12px;
}

.arh-tree .rct-title {
  margin-left: 0.5rem;
}

.arh-tree .rct-node {
  margin-bottom: 0.25rem;
}

.arh-tree .rct-node-parent .rct-title,
.arh-tree .rct-node-leaf .rct-title {
  font-family: "Poppins";
  font-style: auto;
  font-weight: 500;
  font-size: 12px;
}

.arh-tree .rct-node-parent label,
.arh-tree .rct-node-leaf label,
.arh-tree .rct-node-parent > ol label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.arh-tree .rct-collapse .rct-icon-expand-close,
.arh-tree .rct-collapse .rct-icon-expand-open {
  opacity: 1;
  width: 16px;
  height: 16px;
  background-color: #73C7B7;
  color: white;
  border-radius: 50%;
  border: 1px solid #73C7B7;
  font-size: 11px;
  margin-bottom: 3px;
  position: absolute;
  top: -0.7rem;
  left: -0.5rem;
  z-index: 1;
}

.arh-tree .rct-icons-fa4 .rct-icon-uncheck::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}

.arh-tree.arh-tree-invalid .rct-icons-fa4 .rct-icon-uncheck::before {
  border-color: #F79CA7;
}

.arh-tree .rct-icon {
  position: relative;
}

.arh-tree .rct-icons-fa4 .rct-icon-check::before,
.arh-tree .rct-icons-fa4 .rct-icon-half-check::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}

.arh-tree .rct-icons-fa4 .rct-icon-check::after,
.arh-tree .rct-icons-fa4 .rct-icon-half-check::after {
  content: "" !important;
  display: block;
  background: #73C7B7;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.arh-tree .react-checkbox-tree label:hover {
  background: transparent !important;
}


/* Bar progress in Evaluation */

/*///////////////////////////////////////////////////
    // Skill Bars \\ 
///////////////////////////////////////////////////*/

.arh-skillBarValue {
  float: left;
  width: 0;
  --target-width: 0;
  -webkit-animation: slideIn 2s ease-out forwards;
  -moz-animation: slideIn 2s ease-out forwards;
  -o-animation: slideIn 2s ease-out forwards;
  animation: slideIn 2s ease-out forwards;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/
@-webkit-keyframes slideIn {
  0% {
    width: 0;
  }

  25% {
    width: 100%;
  }

  100% {
    width: var(--target-width);
  }
}

@-moz-keyframes slideIn {
  0% {
    width: 0;
  }

  25% {
    width: 100%;
  }

  100% {
    width: var(--target-width);
  }
}

@-ms-keyframes slideIn {
  0% {
    width: 0;
  }

  25% {
    width: 100%;
  }

  100% {
    width: var(--target-width);
  }
}

@-o-keyframes slideIn {
  0% {
    width: 0;
  }

  25% {
    width: 100%;
  }

  100% {
    width: var(--target-width);
  }
}

@keyframes slideIn {
  0% {
    width: 0;
  }

  25% {
    width: 100%;
  }

  100% {
    width: var(--target-width);
  }
}

.arh-rotate-180-main {
  transform: rotate(-180deg);
}

/* End Bar progress in Evaluation */

/* MuiDivider-root */
.MuiDivider-root {
  padding: 0 !important;
  display: none !important;
}

/* End MuiDivider-root */

/* Linear Gradient */
.arh-bg-gradient-progression {
  background: linear-gradient(270deg, #A7EBDE 0%, #D3F0C9 39.15%, #F8F1B6 55.93%, #F7F7F7 75.1%, #F7F7F7 100%);
}

/* End Linear Gradient */

/* Bar de progression border negative = rondue de sens contraire */
/* arh-bg-[#E4ECEE] */
.arh-bar-border-negation {
  background-image: radial-gradient(circle at 2px 10px, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 11px, #E4ECEE 13px);
}

/*code check anime*/

.arh-stepConfirmation svg {
  width: 100%;
  margin: 3.49vw auto;
}

@keyframes outline {
  from {
    stroke-dasharray: 0, 345.576px;
  }

  to {
    stroke-dasharray: 345.576px, 345.576px;
  }
}

#outline {
  animation: 0.38s ease-in outline;
  transform: rotate(0deg);
  transform-origin: center;
}

@keyframes circle {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

#white-circle {
  animation: 0.35s ease-in 0.35s forwards circle;
  transform: none;
  transform-origin: center;
}

@keyframes check {
  from {
    stroke-dasharray: 0, 75px;
  }

  to {
    stroke-dasharray: 75px, 75px;
  }
}

#check {
  animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
  stroke-dasharray: 0, 75px;
}

@keyframes check-group {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.09);
  }

  to {
    transform: scale(1);
  }
}

#check-group {
  animation: 0.32s ease-in-out 1.03s check-group;
  transform-origin: center;
}

/* End  */

.arh-divider-login:before {
  color: #ECECEC;
  content: "";
  flex: 1 1;
  box-shadow: 0 -2px;
  height: 1px;
  margin: auto 0.25rem;
  margin-right: 1rem;
}

.arh-divider-login:after {
  color: #ECECEC;
  content: "";
  flex: 1 1;
  box-shadow: 0 -2px;
  height: 1px;
  margin: auto 0.25rem;
  margin-left: 1rem;
}

.arh-other-connexion > div {
  width: 48%;
}

@media screen and (max-height: 1280px) {
  .arh-other-connexion > div {
    width: 100%;
  }
}

#react-org-chart svg rect {
  height: 200px
}


/* Disclosure */
.arh-collapse-param .arh-collapse-param-child {
  padding: 0 4rem 2rem 6rem;
}

.arh-scrollable-content:we­bkit-scrollbar {
  scrollbar-color: white;
}

.arh-scrollable-content:-we­bkit-scrollbar-thumb­ {
  background-color: white;
}

.arh-scrollable-content::-webkit-scrollbar-track {
  background-color: white;
}

.arh-h-SideCollapseQuestionDev {
  height: calc(100% - 7.8rem);
}

.arh-whitespace-break-spaces {
  white-space: break-spaces;
}

/* End Disclosure */
.lexical iframe {
  width: 100%;
  max-width: 36.125rem;
}

/* Customisation Date range picker dans acceptation congé */

.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__year-read-view--selected-year .react-datepicker__day-name,
.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__day,
.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__time-name {
  width: 65px;
  height: 63px;
}

.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__month-read-view--selected-month {
  height: 40px;
}

.arh-dateRange-congeReplies .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after,
.arh-dateRange-saveHours .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after {
  background-color: unset;
}

.arh-dateRange-congeReplies .react-datepicker__day--outside-month {
  color: #B0BEC5 !important;
}

.DateRangePicker_customisation .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after,
.arh-dateRange-saveHours .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after {
  background-color: unset;
}

/* .arh-dateRange-congeReplies .DateRangePicker_customisation--orange .react-datepicker__day--range-start:before,
.arh-dateRange-congeReplies .DateRangePicker_customisation--orange .react-datepicker__day--range-end:before {
  background-color: #FA966B;
} */
/* END Customisation Date range picker dans acceptation congé */


/* PWA */
@media screen and (max-width: 768px) {
  .arh-mobile-avatar-sidebar .arh-avatar-mobile {
    width: 100%;
    justify-content: center;
  }

  .arh-mobile-avatar-sidebar .arh-avatar-mobile p {
    max-width: 9rem;
  }
}

/* END PWA */

/* Time Picker */
.arh-timePicker .react-datepicker-wrapper {
  width: 100%;
}

.arh-timePicker .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  border: 0;
  cursor: pointer;
  font-weight: 400 !important;
  font-size: .75rem !important;
  font-family: Poppins !important;
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.arh-timePicker .react-datepicker__header--time--only {
  display: none;
}

.arh-timePicker .react-datepicker__triangle {
  display: none;
}

.arh-timePicker .react-datepicker.react-datepicker--time-only {
  width: 100% !important;
  border: 0 !important;
}

.arh-timePicker .react-datepicker__time-container.react-datepicker__time-container--with-today-button {
  position: initial !important;
  border: 1px solid #ECECEC;
  border-radius: 6px;
}

.arh-timePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #73C7B7 !important;
}

.arh-timePicker .react-datepicker-popper {
  top: 0.5rem !important;
  left: -8.7rem !important;
}

/* End Time Picker */


/* Invalid for timePicker Input */
.arh-invalid-input-timePicker input {
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.arh-invalid-input-timePicker input::placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.arh-invalid-input-timePicker input {
  background: transparent !important;
}

/* End Invalid for timePicker Input */


/* Notification Warning not Less */
.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--warning {
  border-color: #FFB201;
  background-color: #F8F1E2;
  color: #D89804;
}

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage .progress-bar--warning {
  background-color: #FFB201;
}

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage.calloutMessage--warning {
  padding: 2rem 3.5rem 2rem 1.5rem !important;
}

.calloutMessage--warning .arh-w-full.arh-h-\[calc\(100\%-8px\)\] .arh-space-x-5 {
  height: 100%;
}

.arh-toastify .Toastify__toast-container {
  height: auto !important;
  min-height: 120px;
}

.arh-toastify .Toastify__toast-container .Toastify__toast.arh-cursor-default {
  height: 100% !important;
  max-height: inherit !important;
  min-height: 120px !important;
  border: 0 !important;
}

.arh-toastify .Toastify__toast-container .calloutMessage {
  height: auto !important;
  padding: 1.5rem !important;
}

/* Correction collage notification pour error et success */
.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body {
  height: inherit !important;
  padding: 0 !important;
  margin: 0 !important;
}

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--error,
.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--success,
.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--info {
  position: absolute !important;
  height: 100% !important;
}

/* End Correction collage notification */

/* End Notification Warning not Less */

.react-datepicker__day--keyboard-selected:not(:hover) {
  background-color: unset !important;
  color: #000 !important;
}

.react-datepicker__day--keyboard-selected:not(:hover).react-datepicker__day--today {
  /* background-color: #F8F8FA !important; */
  color: #000 !important;
  border-radius: 50%;
}

/* ToolBar for formulaire Recrutement */
.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header {
  z-index: initial !important;
}

/* End ToolBar for formulaire Recrutement */

/* Lexical Editor for Modele formulaire  */
.react-form-builder .react-form-builder-preview label {
  margin-top: 2rem;
}

/* Lexical Editor for Modele formulaire */

.Modal__modal {
  font-family: Poppins;
}

.Modal__modal .Input__wrapper {
  flex-direction: column;
  align-items: start;
}

.Modal__modal .Input__label {
  color: #292929;
}

.Modal__modal .Input__input {
  font-size: .75rem;
  padding: .75rem 1.5rem;
  border-color: #ECECEC;
  flex: 1 0;
  width: 100%;
}

.Modal__modal .Button__root {
  background-color: white;
  border: 1px solid #D7D9DD;
  color: #292929;
  transition: background 200ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: .75rem 1.5rem;
  font-size: .75rem;
}

.Modal__modal .Button__root:hover {
  background-color: #F5F5F5;
}

.Modal__modal .Button__root:active {
  background-color: #EFEFF0;
}

.arh-time .react-datepicker-wrapper {
  width: 100px !important;
}

.arh-year-range .react-datepicker__year-wrapper {
  max-width: 300px !important;
  /* min-height: 150px; */
}

.arh-year-range-green div.react-datepicker__year-text.react-datepicker__year-text--selected {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #73C7B7 !important;
  border-radius: 4px !important;
}

textarea {
  scroll-padding-block: 12px;
}

.arh-year-range-green div.react-datepicker__year-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem !important;
}

.arh-year-range-green div.react-datepicker__year-text:hover {
  background-color: var(--loaderGreen);
  color: var(--darkColor);
  border-radius: 4px !important;
}

.DateRangePicker_customisation .react-datepicker__day--outside-month {
  opacity: 0;
}

@layer utilities {
  /* Style de la scrollbar */
  .arh-scrollbar-thin::-webkit-scrollbar {
    height: 8px; /* Modifie l'épaisseur de la scrollbar */
  }

  /* Style de la partie qui glisse (thumb) */
  .arh-scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #EFEFEF; /* Couleur de la barre */
    border-radius: 999px; /* Bord arrondi */
  }

  .arh-scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: #E9E9E9; /* Couleur de la barre de défilement au survol */
    cursor: pointer;
    /*height: 30px; /* Augmentez temporairement la largeur lors du survol */
  }

  /* Facultatif : style de la zone de fond */
  .arh-scrollbar-thin::-webkit-scrollbar-track {
    background-color: white; /* Couleur de l'arrière-plan */
  }

  .arh-display-scroll-with-none {
    scrollbar-width: none;
  }

  .arh-display-scroll-with-none::-webkit-scrollbar {
    height: 0; /* Épaisseur de la barre de défilement verticale */
  }

  /*Scrollbar hide*/
  .arh-hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }
}


/* Custom working hours importation */
.arh-importation-colorArrow--white {
  span svg path {
    fill: #FFFFFF !important
  }
}

.arh-importation-colorArrow--orange {
  span svg path {
    fill: #F55611 !important
  }
}

/* End Custom working hours importation  */


/* Radius */
.arh-radius-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.arh-radius-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* END Radius */

.arh-widthDetailed {
  width: calc(100vw - 24.688rem);
}

/* New Style Permission*/

.arh-new-style-permission ol {
  position: relative;
  border-left: 2px dotted var(--colorgrey);
  margin-left: 13px !important;
}

.arh-new-style-permission li {
  position: relative;
}

.arh-new-style-permission .react-checkbox-tree {
  margin-bottom: 2rem;
}

.arh-new-style-permission label {
  font-size: 13px;
  margin-left: 1.5rem;
}

.arh-new-style-permission .rct-node-parent ol .rct-node-leaf .rct-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.arh-new-style-permission .rct-title {
  margin-left: 0.5rem;
  text-align: start;
}

.arh-new-style-permission .rct-node {
  margin-bottom: 0.25rem;
}

.arh-new-style-permission .rct-node-parent .rct-title,
.arh-new-style-permission .rct-node-leaf .rct-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.arh-new-style-permission .rct-node-parent label,
.arh-new-style-permission .rct-node-leaf label,
.arh-new-style-permission .rct-node-parent>ol label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.arh-new-style-permission .rct-collapse .rct-icon-expand-close,
.arh-new-style-permission .rct-collapse .rct-icon-expand-open {
  opacity: 1;
  width: 16px;
  height: 16px;
  background-color: var(--loaderParams);
  color: white;
  border-radius: 50%;
  border: 1px solid var(--loaderParams);
  font-size: 11px;
  margin-bottom: 3px;
  position: absolute;
  top: -0.7rem;
  left: -0.5rem;
  z-index: 1;
}

.arh-new-style-permission .rct-icons-fa4 .rct-icon-uncheck::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}



.arh-new-style-permission .rct-icon {
  position: relative;
}

.arh-new-style-permission .rct-icons-fa4 .rct-icon-check::before,
.arh-new-style-permission .rct-icons-fa4 .rct-icon-half-check::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}

.arh-new-style-permission .rct-icons-fa4 .rct-icon-check::after,
.arh-new-style-permission .rct-icons-fa4 .rct-icon-half-check::after {
  content: "" !important;
  display: block;
  background: var(--loaderParams);
  border-radius: 2px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.arh-new-style-permission .react-checkbox-tree label:hover {
  background: transparent !important;
}
.arh-new-style-permission {
  padding: 0.5rem !important;
  width: 100%;
  height: 380px;
  border: 2px solid #ebebeb;
  margin-top: 1rem;
  max-height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
  color: #292929;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
}

/*End New Style*/

/* Custom dropZone File Upload */
.arh-custom-fileUpload-disciplinary {
 margin: inherit !important;
}
.arh-custom-fileUpload-disciplinary .dzu-dropzone {
  border: 0 !important;
}


/* TimePicker Green */
.arh-timePicker-green.input_detail .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected {
  background-color: var(--greenColor);
}
/* End TimePicker Green */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.cdnfonts.com/css/bebas-neue");

*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

.org-btn{
  pointer-events: auto;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(115 199 183 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(238 246 247 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 11px;
  --tw-text-opacity: 1;
  color: rgb(115 199 183 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.calloutMessage--warning .org-btn.arh-h-\[calc\(100\%-8px\)\] .arh-space-x-5 {
  height: 100%;
}

.org-btn:hover{
  --tw-border-opacity: 1;
  border-color: rgb(163 221 210 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(163 221 210 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(17 20 45 / var(--tw-text-opacity));
}

.arh-toolbar-nav{
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(228 236 248 / var(--tw-border-opacity));
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.arh-toolbar-nav:hover{
  --tw-border-opacity: 1;
  border-color: rgb(250 150 107 / var(--tw-border-opacity));
}

.arh-pointer-events-none{
  pointer-events: none;
}

.arh-pointer-events-auto{
  pointer-events: auto;
}

.arh-invisible{
  visibility: hidden;
}

.arh-static{
  position: static;
}

.arh-fixed{
  position: fixed;
}

.\!arh-absolute{
  position: absolute !important;
}

.arh-absolute{
  position: absolute;
}

.arh-relative{
  position: relative;
}

.arh-sticky{
  position: sticky;
}

.arh-inset-0{
  inset: 0px;
}

.arh-inset-y-0{
  top: 0px;
  bottom: 0px;
}

.arh-inset-y-1\/2{
  top: 50%;
  bottom: 50%;
}

.arh-inset-y-auto{
  top: auto;
  bottom: auto;
}

.\!-arh-bottom-\[10px\]{
  bottom: -10px !important;
}

.\!-arh-top-28{
  top: -7rem !important;
}

.\!arh-left-48{
  left: 12rem !important;
}

.\!arh-left-\[23rem\]{
  left: 23rem !important;
}

.\!arh-left-\[30rem\]{
  left: 30rem !important;
}

.\!arh-left-auto{
  left: auto !important;
}

.\!arh-right-4{
  right: 1rem !important;
}

.\!arh-right-\[0\.5rem\]{
  right: 0.5rem !important;
}

.\!arh-right-\[1\.7rem\]{
  right: 1.7rem !important;
}

.\!arh-top-0{
  top: 0px !important;
}

.\!arh-top-\[1\.55rem\]{
  top: 1.55rem !important;
}

.\!arh-top-\[3rem\]{
  top: 3rem !important;
}

.\!arh-top-\[4\.4rem\]{
  top: 4.4rem !important;
}

.\!arh-top-auto{
  top: auto !important;
}

.-arh-bottom-\[2rem\]{
  bottom: -2rem;
}

.-arh-left-14{
  left: -3.5rem;
}

.-arh-left-\[0\.5rem\]{
  left: -0.5rem;
}

.-arh-left-\[23px\]{
  left: -23px;
}

.-arh-right-1{
  right: -0.25rem;
}

.-arh-right-14{
  right: -3.5rem;
}

.-arh-right-3{
  right: -0.75rem;
}

.-arh-right-4{
  right: -1rem;
}

.-arh-right-\[0\.8rem\]{
  right: -0.8rem;
}

.-arh-top-3{
  top: -0.75rem;
}

.-arh-top-\[1px\]{
  top: -1px;
}

.-arh-top-\[1rem\]{
  top: -1rem;
}

.-arh-top-\[5px\]{
  top: -5px;
}

.arh-bottom-0{
  bottom: 0px;
}

.arh-bottom-10{
  bottom: 2.5rem;
}

.arh-bottom-12{
  bottom: 3rem;
}

.arh-bottom-9{
  bottom: 2.25rem;
}

.arh-bottom-\[-2px\]{
  bottom: -2px;
}

.arh-bottom-\[0\.688rem\]{
  bottom: 0.688rem;
}

.arh-bottom-\[3\.125rem\]{
  bottom: 3.125rem;
}

.arh-bottom-\[40px\]{
  bottom: 40px;
}

.arh-bottom-full{
  bottom: 100%;
}

.arh-left-0{
  left: 0px;
}

.arh-left-1{
  left: 0.25rem;
}

.arh-left-1\.5{
  left: 0.375rem;
}

.arh-left-1\/2{
  left: 50%;
}

.arh-left-2{
  left: 0.5rem;
}

.arh-left-2\.5{
  left: 0.625rem;
}

.arh-left-9{
  left: 2.25rem;
}

.arh-left-\[0\.438rem\]{
  left: 0.438rem;
}

.arh-left-\[0\.938rem\]{
  left: 0.938rem;
}

.arh-left-\[140px\]{
  left: 140px;
}

.arh-left-\[350px\]{
  left: 350px;
}

.arh-right-0{
  right: 0px;
}

.arh-right-1{
  right: 0.25rem;
}

.arh-right-12{
  right: 3rem;
}

.arh-right-2{
  right: 0.5rem;
}

.arh-right-3{
  right: 0.75rem;
}

.arh-right-3\.5{
  right: 0.875rem;
}

.arh-right-4{
  right: 1rem;
}

.arh-right-5{
  right: 1.25rem;
}

.arh-right-6{
  right: 1.5rem;
}

.arh-right-8{
  right: 2rem;
}

.arh-right-9{
  right: 2.25rem;
}

.arh-right-\[-2px\]{
  right: -2px;
}

.arh-right-\[0\.625rem\]{
  right: 0.625rem;
}

.arh-right-\[0\.75rem\]{
  right: 0.75rem;
}

.arh-right-\[1\.5rem\]{
  right: 1.5rem;
}

.arh-right-\[1\.6rem\]{
  right: 1.6rem;
}

.arh-right-\[12\.5rem\]{
  right: 12.5rem;
}

.arh-right-\[1px\]{
  right: 1px;
}

.arh-right-\[1rem\]{
  right: 1rem;
}

.arh-right-\[2\.5vw\]{
  right: 2.5vw;
}

.arh-right-\[25px\]{
  right: 25px;
}

.arh-right-\[3\.562rem\]{
  right: 3.562rem;
}

.arh-right-\[4\.688rem\]{
  right: 4.688rem;
}

.arh-right-\[8px\]{
  right: 8px;
}

.arh-top-0{
  top: 0px;
}

.arh-top-0\.5{
  top: 0.125rem;
}

.arh-top-1{
  top: 0.25rem;
}

.arh-top-1\/2{
  top: 50%;
}

.arh-top-12{
  top: 3rem;
}

.arh-top-14{
  top: 3.5rem;
}

.arh-top-16{
  top: 4rem;
}

.arh-top-2{
  top: 0.5rem;
}

.arh-top-2\/4{
  top: 50%;
}

.arh-top-4{
  top: 1rem;
}

.arh-top-6{
  top: 1.5rem;
}

.arh-top-8{
  top: 2rem;
}

.arh-top-9{
  top: 2.25rem;
}

.arh-top-\[-1px\]{
  top: -1px;
}

.arh-top-\[0\.4rem\]{
  top: 0.4rem;
}

.arh-top-\[0\.8rem\]{
  top: 0.8rem;
}

.arh-top-\[0rem\]{
  top: 0rem;
}

.arh-top-\[1\.4rem\]{
  top: 1.4rem;
}

.arh-top-\[1\.5rem\]{
  top: 1.5rem;
}

.arh-top-\[1\.7rem\]{
  top: 1.7rem;
}

.arh-top-\[18px\]{
  top: 18px;
}

.arh-top-\[1px\]{
  top: 1px;
}

.arh-top-\[1rem\]{
  top: 1rem;
}

.arh-top-\[2\.25rem\]{
  top: 2.25rem;
}

.arh-top-\[2\.5rem\]{
  top: 2.5rem;
}

.arh-top-\[3\.75rem\]{
  top: 3.75rem;
}

.arh-top-\[3rem\]{
  top: 3rem;
}

.arh-top-\[4\.2rem\]{
  top: 4.2rem;
}

.arh-top-\[4\.375rem\]{
  top: 4.375rem;
}

.arh-top-\[45\%\]{
  top: 45%;
}

.arh-top-\[50\%\]{
  top: 50%;
}

.arh-top-\[6rem\]{
  top: 6rem;
}

.arh-top-\[7\.8rem\]{
  top: 7.8rem;
}

.arh-top-full{
  top: 100%;
}

.arh-top-px{
  top: 1px;
}

.arh-left-3{
  left: 0.75rem;
}

.\!arh-z-10{
  z-index: 10 !important;
}

.\!arh-z-20{
  z-index: 20 !important;
}

.\!arh-z-\[3\]{
  z-index: 3 !important;
}

.arh-z-0{
  z-index: 0;
}

.arh-z-10{
  z-index: 10;
}

.arh-z-20{
  z-index: 20;
}

.arh-z-30{
  z-index: 30;
}

.arh-z-40{
  z-index: 40;
}

.arh-z-50{
  z-index: 50;
}

.arh-z-\[-1\]{
  z-index: -1;
}

.arh-z-\[11\]{
  z-index: 11;
}

.arh-z-\[13\]{
  z-index: 13;
}

.arh-z-\[1\]{
  z-index: 1;
}

.arh-z-\[2\]{
  z-index: 2;
}

.arh-z-\[3\]{
  z-index: 3;
}

.arh-z-\[4\]{
  z-index: 4;
}

.arh-z-\[5\]{
  z-index: 5;
}

.arh-z-\[60\]{
  z-index: 60;
}

.arh-z-\[6\]{
  z-index: 6;
}

.arh-z-\[8\]{
  z-index: 8;
}

.arh-z-\[999\]{
  z-index: 999;
}

.arh-z-\[9\]{
  z-index: 9;
}

.arh-col-span-1{
  grid-column: span 1 / span 1;
}

.arh-col-span-2{
  grid-column: span 2 / span 2;
}

.arh-col-span-4{
  grid-column: span 4 / span 4;
}

.arh-col-start-1{
  grid-column-start: 1;
}

.arh-col-start-4{
  grid-column-start: 4;
}

.arh-col-start-5{
  grid-column-start: 5;
}

.arh-col-start-7{
  grid-column-start: 7;
}

.arh-col-end-12{
  grid-column-end: 12;
}

.arh-col-end-3{
  grid-column-end: 3;
}

.arh-col-end-4{
  grid-column-end: 4;
}

.arh-col-end-6{
  grid-column-end: 6;
}

.arh-col-end-7{
  grid-column-end: 7;
}

.arh-float-right{
  float: right;
}

.arh-float-left{
  float: left;
}

.\!arh-m-0{
  margin: 0px !important;
}

.arh-m-0{
  margin: 0px;
}

.arh-m-4{
  margin: 1rem;
}

.arh-m-5{
  margin: 1.25rem;
}

.arh-m-\[0\.125rem\]{
  margin: 0.125rem;
}

.arh-m-\[0\.5rem\]{
  margin: 0.5rem;
}

.arh-m-\[4px\]{
  margin: 4px;
}

.arh-m-\[7px\]{
  margin: 7px;
}

.\!arh-my-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.\!arh-my-2{
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.\!arh-my-4{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.arh-mx-0{
  margin-left: 0px;
  margin-right: 0px;
}

.arh-mx-12{
  margin-left: 3rem;
  margin-right: 3rem;
}

.arh-mx-14{
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.arh-mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.arh-mx-2\.5{
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.arh-mx-3{
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.arh-mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}

.arh-mx-5{
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.arh-mx-8{
  margin-left: 2rem;
  margin-right: 2rem;
}

.arh-mx-\[0\.3rem\]{
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.arh-mx-\[0\.5rem\]{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.arh-mx-\[0\.7rem\]{
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.arh-mx-\[1\.125rem\]{
  margin-left: 1.125rem;
  margin-right: 1.125rem;
}

.arh-mx-\[10px\]{
  margin-left: 10px;
  margin-right: 10px;
}

.arh-mx-\[1rem\]{
  margin-left: 1rem;
  margin-right: 1rem;
}

.arh-mx-\[2px\]{
  margin-left: 2px;
  margin-right: 2px;
}

.arh-mx-\[2rem\]{
  margin-left: 2rem;
  margin-right: 2rem;
}

.arh-mx-\[4\%\]{
  margin-left: 4%;
  margin-right: 4%;
}

.arh-mx-auto{
  margin-left: auto;
  margin-right: auto;
}

.arh-my-0{
  margin-top: 0px;
  margin-bottom: 0px;
}

.arh-my-0\.5{
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.arh-my-1\.5{
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.arh-my-10{
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.arh-my-14{
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.arh-my-16{
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.arh-my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.arh-my-2\.5{
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.arh-my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.arh-my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.arh-my-5{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.arh-my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.arh-my-7{
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.arh-my-8{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.arh-my-9{
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.arh-my-\[0\.375rem\]{
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.arh-my-\[0\.3rem\]{
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.arh-my-\[0\.438rem\]{
  margin-top: 0.438rem;
  margin-bottom: 0.438rem;
}

.arh-my-\[0\.5rem\]{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.arh-my-\[0\.625rem\]{
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.arh-my-\[0\.75rem\]{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.arh-my-\[0\.813rem\]{
  margin-top: 0.813rem;
  margin-bottom: 0.813rem;
}

.arh-my-\[0\.875rem\]{
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.arh-my-\[0\.938rem\]{
  margin-top: 0.938rem;
  margin-bottom: 0.938rem;
}

.arh-my-\[1\.063rem\]{
  margin-top: 1.063rem;
  margin-bottom: 1.063rem;
}

.arh-my-\[1\.5rem\]{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.arh-my-\[1\.625rem\]{
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
}

.arh-my-\[1\.75rem\]{
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.arh-my-\[1\.875rem\]{
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.arh-my-\[10px\]{
  margin-top: 10px;
  margin-bottom: 10px;
}

.arh-my-\[1rem\]{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.arh-my-\[2\%\]{
  margin-top: 2%;
  margin-bottom: 2%;
}

.arh-mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.\!-arh-mt-12{
  margin-top: -3rem !important;
}

.\!-arh-mt-4{
  margin-top: -1rem !important;
}

.\!-arh-mt-8{
  margin-top: -2rem !important;
}

.\!arh-mb-0{
  margin-bottom: 0px !important;
}

.\!arh-mb-1{
  margin-bottom: 0.25rem !important;
}

.\!arh-mb-11{
  margin-bottom: 2.75rem !important;
}

.\!arh-mb-2{
  margin-bottom: 0.5rem !important;
}

.\!arh-mb-4{
  margin-bottom: 1rem !important;
}

.\!arh-mb-5{
  margin-bottom: 1.25rem !important;
}

.\!arh-mb-7{
  margin-bottom: 1.75rem !important;
}

.\!arh-mb-\[1\.5rem\]{
  margin-bottom: 1.5rem !important;
}

.\!arh-mb-\[3rem\]{
  margin-bottom: 3rem !important;
}

.\!arh-ml-0{
  margin-left: 0px !important;
}

.\!arh-ml-2\.5{
  margin-left: 0.625rem !important;
}

.\!arh-ml-\[-4\.5rem\]{
  margin-left: -4.5rem !important;
}

.\!arh-ml-\[15px\]{
  margin-left: 15px !important;
}

.\!arh-mr-\[2rem\]{
  margin-right: 2rem !important;
}

.\!arh-mr-\[3rem\]{
  margin-right: 3rem !important;
}

.\!arh-mt-0{
  margin-top: 0px !important;
}

.\!arh-mt-1{
  margin-top: 0.25rem !important;
}

.\!arh-mt-11{
  margin-top: 2.75rem !important;
}

.\!arh-mt-16{
  margin-top: 4rem !important;
}

.\!arh-mt-4{
  margin-top: 1rem !important;
}

.\!arh-mt-5{
  margin-top: 1.25rem !important;
}

.\!arh-mt-8{
  margin-top: 2rem !important;
}

.\!arh-mt-9{
  margin-top: 2.25rem !important;
}

.\!arh-mt-\[1\.5rem\]{
  margin-top: 1.5rem !important;
}

.\!arh-mt-\[1rem\]{
  margin-top: 1rem !important;
}

.\!arh-mt-\[26rem\]{
  margin-top: 26rem !important;
}

.\!arh-mt-\[2px\]{
  margin-top: 2px !important;
}

.\!arh-mt-\[3rem\]{
  margin-top: 3rem !important;
}

.-arh-mb-\[1rem\]{
  margin-bottom: -1rem;
}

.-arh-ml-2{
  margin-left: -0.5rem;
}

.-arh-ml-8{
  margin-left: -2rem;
}

.-arh-ml-\[112px\]{
  margin-left: -112px;
}

.-arh-ml-\[50px\]{
  margin-left: -50px;
}

.-arh-mt-2{
  margin-top: -0.5rem;
}

.-arh-mt-2\.5{
  margin-top: -0.625rem;
}

.-arh-mt-6{
  margin-top: -1.5rem;
}

.-arh-mt-7{
  margin-top: -1.75rem;
}

.-arh-mt-\[2rem\]{
  margin-top: -2rem;
}

.-arh-mt-\[3px\]{
  margin-top: -3px;
}

.arh-mb-0{
  margin-bottom: 0px;
}

.arh-mb-1{
  margin-bottom: 0.25rem;
}

.arh-mb-1\.5{
  margin-bottom: 0.375rem;
}

.arh-mb-10{
  margin-bottom: 2.5rem;
}

.arh-mb-12{
  margin-bottom: 3rem;
}

.arh-mb-14{
  margin-bottom: 3.5rem;
}

.arh-mb-18{
  margin-bottom: 1.125rem;
}

.arh-mb-2{
  margin-bottom: 0.5rem;
}

.arh-mb-2\.5{
  margin-bottom: 0.625rem;
}

.arh-mb-24{
  margin-bottom: 6rem;
}

.arh-mb-3{
  margin-bottom: 0.75rem;
}

.arh-mb-4{
  margin-bottom: 1rem;
}

.arh-mb-5{
  margin-bottom: 1.25rem;
}

.arh-mb-6{
  margin-bottom: 1.5rem;
}

.arh-mb-7{
  margin-bottom: 1.75rem;
}

.arh-mb-8{
  margin-bottom: 2rem;
}

.arh-mb-9{
  margin-bottom: 2.25rem;
}

.arh-mb-\[0\.25rem\]{
  margin-bottom: 0.25rem;
}

.arh-mb-\[0\.375rem\]{
  margin-bottom: 0.375rem;
}

.arh-mb-\[0\.438rem\]{
  margin-bottom: 0.438rem;
}

.arh-mb-\[0\.563rem\]{
  margin-bottom: 0.563rem;
}

.arh-mb-\[0\.5rem\]{
  margin-bottom: 0.5rem;
}

.arh-mb-\[0\.625rem\]{
  margin-bottom: 0.625rem;
}

.arh-mb-\[0\.688rem\]{
  margin-bottom: 0.688rem;
}

.arh-mb-\[0\.75rem\]{
  margin-bottom: 0.75rem;
}

.arh-mb-\[0\.7rem\]{
  margin-bottom: 0.7rem;
}

.arh-mb-\[0\.875rem\]{
  margin-bottom: 0.875rem;
}

.arh-mb-\[0\.938rem\]{
  margin-bottom: 0.938rem;
}

.arh-mb-\[1\.063rem\]{
  margin-bottom: 1.063rem;
}

.arh-mb-\[1\.125rem\]{
  margin-bottom: 1.125rem;
}

.arh-mb-\[1\.188rem\]{
  margin-bottom: 1.188rem;
}

.arh-mb-\[1\.313rem\]{
  margin-bottom: 1.313rem;
}

.arh-mb-\[1\.375rem\]{
  margin-bottom: 1.375rem;
}

.arh-mb-\[1\.3rem\]{
  margin-bottom: 1.3rem;
}

.arh-mb-\[1\.563rem\]{
  margin-bottom: 1.563rem;
}

.arh-mb-\[1\.5rem\]{
  margin-bottom: 1.5rem;
}

.arh-mb-\[1\.75rem\]{
  margin-bottom: 1.75rem;
}

.arh-mb-\[1\.7rem\]{
  margin-bottom: 1.7rem;
}

.arh-mb-\[1\.875rem\]{
  margin-bottom: 1.875rem;
}

.arh-mb-\[1\.938rem\]{
  margin-bottom: 1.938rem;
}

.arh-mb-\[10px\]{
  margin-bottom: 10px;
}

.arh-mb-\[11px\]{
  margin-bottom: 11px;
}

.arh-mb-\[13px\]{
  margin-bottom: 13px;
}

.arh-mb-\[18px\]{
  margin-bottom: 18px;
}

.arh-mb-\[1px\]{
  margin-bottom: 1px;
}

.arh-mb-\[1rem\]{
  margin-bottom: 1rem;
}

.arh-mb-\[2\%\]{
  margin-bottom: 2%;
}

.arh-mb-\[2\.125rem\]{
  margin-bottom: 2.125rem;
}

.arh-mb-\[2\.5rem\]{
  margin-bottom: 2.5rem;
}

.arh-mb-\[2\.625rem\]{
  margin-bottom: 2.625rem;
}

.arh-mb-\[2\.75rem\]{
  margin-bottom: 2.75rem;
}

.arh-mb-\[2\.911rem\]{
  margin-bottom: 2.911rem;
}

.arh-mb-\[2px\]{
  margin-bottom: 2px;
}

.arh-mb-\[2rem\]{
  margin-bottom: 2rem;
}

.arh-mb-\[3\.06rem\]{
  margin-bottom: 3.06rem;
}

.arh-mb-\[3\.125rem\]{
  margin-bottom: 3.125rem;
}

.arh-mb-\[3\.25rem\]{
  margin-bottom: 3.25rem;
}

.arh-mb-\[3\.563rem\]{
  margin-bottom: 3.563rem;
}

.arh-mb-\[3px\]{
  margin-bottom: 3px;
}

.arh-mb-\[3rem\]{
  margin-bottom: 3rem;
}

.arh-mb-\[4\%\]{
  margin-bottom: 4%;
}

.arh-mb-\[40px\]{
  margin-bottom: 40px;
}

.arh-mb-\[4px\]{
  margin-bottom: 4px;
}

.arh-mb-\[5px\]{
  margin-bottom: 5px;
}

.arh-mb-\[61px\]{
  margin-bottom: 61px;
}

.arh-mb-\[9px\]{
  margin-bottom: 9px;
}

.arh-me-2{
  margin-inline-end: 0.5rem;
}

.arh-me-4{
  margin-inline-end: 1rem;
}

.arh-me-5{
  margin-inline-end: 1.25rem;
}

.arh-ml-0{
  margin-left: 0px;
}

.arh-ml-0\.5{
  margin-left: 0.125rem;
}

.arh-ml-1{
  margin-left: 0.25rem;
}

.arh-ml-1\.5{
  margin-left: 0.375rem;
}

.arh-ml-10{
  margin-left: 2.5rem;
}

.arh-ml-12{
  margin-left: 3rem;
}

.arh-ml-16{
  margin-left: 4rem;
}

.arh-ml-2{
  margin-left: 0.5rem;
}

.arh-ml-2\.5{
  margin-left: 0.625rem;
}

.arh-ml-20{
  margin-left: 5rem;
}

.arh-ml-3{
  margin-left: 0.75rem;
}

.arh-ml-3\.5{
  margin-left: 0.875rem;
}

.arh-ml-4{
  margin-left: 1rem;
}

.arh-ml-5{
  margin-left: 1.25rem;
}

.arh-ml-6{
  margin-left: 1.5rem;
}

.arh-ml-7{
  margin-left: 1.75rem;
}

.arh-ml-9{
  margin-left: 2.25rem;
}

.arh-ml-\[-1rem\]{
  margin-left: -1rem;
}

.arh-ml-\[-2rem\]{
  margin-left: -2rem;
}

.arh-ml-\[0\.575rem\]{
  margin-left: 0.575rem;
}

.arh-ml-\[0\.5rem\]{
  margin-left: 0.5rem;
}

.arh-ml-\[0\.675rem\]{
  margin-left: 0.675rem;
}

.arh-ml-\[0\.75rem\]{
  margin-left: 0.75rem;
}

.arh-ml-\[0\.848rem\]{
  margin-left: 0.848rem;
}

.arh-ml-\[0\.925rem\]{
  margin-left: 0.925rem;
}

.arh-ml-\[1\%\]{
  margin-left: 1%;
}

.arh-ml-\[1\.063rem\]{
  margin-left: 1.063rem;
}

.arh-ml-\[1\.2rem\]{
  margin-left: 1.2rem;
}

.arh-ml-\[1\.313rem\]{
  margin-left: 1.313rem;
}

.arh-ml-\[1\.438rem\]{
  margin-left: 1.438rem;
}

.arh-ml-\[1\.5rem\]{
  margin-left: 1.5rem;
}

.arh-ml-\[1\.875rem\]{
  margin-left: 1.875rem;
}

.arh-ml-\[15px\]{
  margin-left: 15px;
}

.arh-ml-\[1rem\]{
  margin-left: 1rem;
}

.arh-ml-\[2rem\]{
  margin-left: 2rem;
}

.arh-ml-\[3\.25rem\]{
  margin-left: 3.25rem;
}

.arh-ml-\[4\%\]{
  margin-left: 4%;
}

.arh-ml-\[4\.738rem\]{
  margin-left: 4.738rem;
}

.arh-ml-\[5\.5rem\]{
  margin-left: 5.5rem;
}

.arh-ml-\[6px\]{
  margin-left: 6px;
}

.arh-ml-auto{
  margin-left: auto;
}

.arh-mr-0{
  margin-right: 0px;
}

.arh-mr-1{
  margin-right: 0.25rem;
}

.arh-mr-1\.5{
  margin-right: 0.375rem;
}

.arh-mr-12{
  margin-right: 3rem;
}

.arh-mr-16{
  margin-right: 4rem;
}

.arh-mr-2{
  margin-right: 0.5rem;
}

.arh-mr-2\.5{
  margin-right: 0.625rem;
}

.arh-mr-20{
  margin-right: 5rem;
}

.arh-mr-3{
  margin-right: 0.75rem;
}

.arh-mr-4{
  margin-right: 1rem;
}

.arh-mr-44{
  margin-right: 11rem;
}

.arh-mr-5{
  margin-right: 1.25rem;
}

.arh-mr-6{
  margin-right: 1.5rem;
}

.arh-mr-7{
  margin-right: 1.75rem;
}

.arh-mr-\[0\.3rem\]{
  margin-right: 0.3rem;
}

.arh-mr-\[0\.438rem\]{
  margin-right: 0.438rem;
}

.arh-mr-\[0\.5rem\]{
  margin-right: 0.5rem;
}

.arh-mr-\[0\.625rem\]{
  margin-right: 0.625rem;
}

.arh-mr-\[0\.75rem\]{
  margin-right: 0.75rem;
}

.arh-mr-\[0\.813rem\]{
  margin-right: 0.813rem;
}

.arh-mr-\[1\.05rem\]{
  margin-right: 1.05rem;
}

.arh-mr-\[1\.125rem\]{
  margin-right: 1.125rem;
}

.arh-mr-\[1\.469rem\]{
  margin-right: 1.469rem;
}

.arh-mr-\[1\.5rem\]{
  margin-right: 1.5rem;
}

.arh-mr-\[1\.875rem\]{
  margin-right: 1.875rem;
}

.arh-mr-\[10\%\]{
  margin-right: 10%;
}

.arh-mr-\[10px\]{
  margin-right: 10px;
}

.arh-mr-\[1rem\]{
  margin-right: 1rem;
}

.arh-mr-\[2\.125rem\]{
  margin-right: 2.125rem;
}

.arh-mr-\[2\.563rem\]{
  margin-right: 2.563rem;
}

.arh-mr-\[2\.625rem\]{
  margin-right: 2.625rem;
}

.arh-mr-\[20px\]{
  margin-right: 20px;
}

.arh-mr-\[3\%\]{
  margin-right: 3%;
}

.arh-mr-\[3\.796rem\]{
  margin-right: 3.796rem;
}

.arh-mr-\[4\%\]{
  margin-right: 4%;
}

.arh-mr-\[6\.5rem\]{
  margin-right: 6.5rem;
}

.arh-mr-\[66px\]{
  margin-right: 66px;
}

.arh-mr-\[6px\]{
  margin-right: 6px;
}

.arh-ms-2{
  margin-inline-start: 0.5rem;
}

.arh-ms-2\.5{
  margin-inline-start: 0.625rem;
}

.arh-ms-4{
  margin-inline-start: 1rem;
}

.arh-mt-0{
  margin-top: 0px;
}

.arh-mt-1{
  margin-top: 0.25rem;
}

.arh-mt-1\.5{
  margin-top: 0.375rem;
}

.arh-mt-10{
  margin-top: 2.5rem;
}

.arh-mt-12{
  margin-top: 3rem;
}

.arh-mt-14{
  margin-top: 3.5rem;
}

.arh-mt-16{
  margin-top: 4rem;
}

.arh-mt-18{
  margin-top: 1.125rem;
}

.arh-mt-2{
  margin-top: 0.5rem;
}

.arh-mt-2\.5{
  margin-top: 0.625rem;
}

.arh-mt-20{
  margin-top: 5rem;
}

.arh-mt-28{
  margin-top: 7rem;
}

.arh-mt-3{
  margin-top: 0.75rem;
}

.arh-mt-4{
  margin-top: 1rem;
}

.arh-mt-5{
  margin-top: 1.25rem;
}

.arh-mt-6{
  margin-top: 1.5rem;
}

.arh-mt-7{
  margin-top: 1.75rem;
}

.arh-mt-8{
  margin-top: 2rem;
}

.arh-mt-9{
  margin-top: 2.25rem;
}

.arh-mt-\[-0\.5rem\]{
  margin-top: -0.5rem;
}

.arh-mt-\[0\.2rem\]{
  margin-top: 0.2rem;
}

.arh-mt-\[0\.3rem\]{
  margin-top: 0.3rem;
}

.arh-mt-\[0\.563rem\]{
  margin-top: 0.563rem;
}

.arh-mt-\[0\.5rem\]{
  margin-top: 0.5rem;
}

.arh-mt-\[0\.813rem\]{
  margin-top: 0.813rem;
}

.arh-mt-\[0\.875rem\]{
  margin-top: 0.875rem;
}

.arh-mt-\[0\.938rem\]{
  margin-top: 0.938rem;
}

.arh-mt-\[1\.063rem\]{
  margin-top: 1.063rem;
}

.arh-mt-\[1\.125rem\]{
  margin-top: 1.125rem;
}

.arh-mt-\[1\.25rem\]{
  margin-top: 1.25rem;
}

.arh-mt-\[1\.563rem\]{
  margin-top: 1.563rem;
}

.arh-mt-\[1\.5rem\]{
  margin-top: 1.5rem;
}

.arh-mt-\[1\.625rem\]{
  margin-top: 1.625rem;
}

.arh-mt-\[1\.688rem\]{
  margin-top: 1.688rem;
}

.arh-mt-\[1\.75rem\]{
  margin-top: 1.75rem;
}

.arh-mt-\[15px\]{
  margin-top: 15px;
}

.arh-mt-\[168px\]{
  margin-top: 168px;
}

.arh-mt-\[17px\]{
  margin-top: 17px;
}

.arh-mt-\[18px\]{
  margin-top: 18px;
}

.arh-mt-\[1rem\]{
  margin-top: 1rem;
}

.arh-mt-\[2\%\]{
  margin-top: 2%;
}

.arh-mt-\[2\.063rem\]{
  margin-top: 2.063rem;
}

.arh-mt-\[2\.188rem\]{
  margin-top: 2.188rem;
}

.arh-mt-\[20px\]{
  margin-top: 20px;
}

.arh-mt-\[25px\]{
  margin-top: 25px;
}

.arh-mt-\[2px\]{
  margin-top: 2px;
}

.arh-mt-\[2rem\]{
  margin-top: 2rem;
}

.arh-mt-\[3\.25rem\]{
  margin-top: 3.25rem;
}

.arh-mt-\[3\.5rem\]{
  margin-top: 3.5rem;
}

.arh-mt-\[31px\]{
  margin-top: 31px;
}

.arh-mt-\[3px\]{
  margin-top: 3px;
}

.arh-mt-\[3rem\]{
  margin-top: 3rem;
}

.arh-mt-\[4\%\]{
  margin-top: 4%;
}

.arh-mt-\[4rem\]{
  margin-top: 4rem;
}

.arh-mt-\[6px\]{
  margin-top: 6px;
}

.arh-mt-\[6rem\]{
  margin-top: 6rem;
}

.arh-mt-\[74px\]{
  margin-top: 74px;
}

.arh-mt-\[7px\]{
  margin-top: 7px;
}

.arh-mt-\[90px\]{
  margin-top: 90px;
}

.arh-mt-\[9px\]{
  margin-top: 9px;
}

.arh-mt-auto{
  margin-top: auto;
}

.arh-mt-px{
  margin-top: 1px;
}

.\!arh-mr-0{
  margin-right: 0px !important;
}

.\!arh-mr-4{
  margin-right: 1rem !important;
}

.arh-ml-\[7\.5rem\]{
  margin-left: 7.5rem;
}

.arh-box-border{
  box-sizing: border-box;
}

.arh-line-clamp-1{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.arh-line-clamp-2{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.arh-line-clamp-3{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.arh-block{
  display: block;
}

.arh-inline-block{
  display: inline-block;
}

.arh-inline{
  display: inline;
}

.arh-flex{
  display: flex;
}

.arh-inline-flex{
  display: inline-flex;
}

.arh-grid{
  display: grid;
}

.\!arh-hidden{
  display: none !important;
}

.arh-hidden{
  display: none;
}

.arh-aspect-auto{
  aspect-ratio: auto;
}

.arh-aspect-video{
  aspect-ratio: 16 / 9;
}

.\!arh-size-3\.5{
  width: 0.875rem !important;
  height: 0.875rem !important;
}

.arh-size-1\.5{
  width: 0.375rem;
  height: 0.375rem;
}

.arh-size-10{
  width: 2.5rem;
  height: 2.5rem;
}

.arh-size-11{
  width: 2.75rem;
  height: 2.75rem;
}

.arh-size-12{
  width: 3rem;
  height: 3rem;
}

.arh-size-2{
  width: 0.5rem;
  height: 0.5rem;
}

.arh-size-3\.5{
  width: 0.875rem;
  height: 0.875rem;
}

.arh-size-4{
  width: 1rem;
  height: 1rem;
}

.arh-size-6{
  width: 1.5rem;
  height: 1.5rem;
}

.arh-size-7{
  width: 1.75rem;
  height: 1.75rem;
}

.arh-size-8{
  width: 2rem;
  height: 2rem;
}

.arh-size-9{
  width: 2.25rem;
  height: 2.25rem;
}

.arh-size-auto{
  width: auto;
  height: auto;
}

.arh-size-full{
  width: 100%;
  height: 100%;
}

.\!arh-h-0{
  height: 0px !important;
}

.\!arh-h-1\.5{
  height: 0.375rem !important;
}

.\!arh-h-10{
  height: 2.5rem !important;
}

.\!arh-h-2{
  height: 0.5rem !important;
}

.\!arh-h-4{
  height: 1rem !important;
}

.\!arh-h-6{
  height: 1.5rem !important;
}

.\!arh-h-\[15px\]{
  height: 15px !important;
}

.\!arh-h-\[18px\]{
  height: 18px !important;
}

.\!arh-h-\[50px\]{
  height: 50px !important;
}

.\!arh-h-full{
  height: 100% !important;
}

.arh-h-0{
  height: 0px;
}

.arh-h-1\.5{
  height: 0.375rem;
}

.arh-h-1\/5{
  height: 20%;
}

.arh-h-10{
  height: 2.5rem;
}

.arh-h-11{
  height: 2.75rem;
}

.arh-h-12{
  height: 3rem;
}

.arh-h-14{
  height: 3.5rem;
}

.arh-h-16{
  height: 4rem;
}

.arh-h-18{
  height: 1.125rem;
}

.arh-h-2{
  height: 0.5rem;
}

.arh-h-2\.5{
  height: 0.625rem;
}

.arh-h-20{
  height: 5rem;
}

.arh-h-28{
  height: 7rem;
}

.arh-h-3{
  height: 0.75rem;
}

.arh-h-32{
  height: 8rem;
}

.arh-h-4{
  height: 1rem;
}

.arh-h-44{
  height: 11rem;
}

.arh-h-5{
  height: 1.25rem;
}

.arh-h-6{
  height: 1.5rem;
}

.arh-h-64{
  height: 16rem;
}

.arh-h-7{
  height: 1.75rem;
}

.arh-h-8{
  height: 2rem;
}

.arh-h-80{
  height: 20rem;
}

.arh-h-9{
  height: 2.25rem;
}

.arh-h-96{
  height: 24rem;
}

.arh-h-\[0\.438rem\]{
  height: 0.438rem;
}

.arh-h-\[0\.5rem\]{
  height: 0.5rem;
}

.arh-h-\[0\.813rem\]{
  height: 0.813rem;
}

.arh-h-\[1\.063rem\]{
  height: 1.063rem;
}

.arh-h-\[1\.547rem\]{
  height: 1.547rem;
}

.arh-h-\[1\.5rem\]{
  height: 1.5rem;
}

.arh-h-\[1\.875rem\]{
  height: 1.875rem;
}

.arh-h-\[1\.938rem\]{
  height: 1.938rem;
}

.arh-h-\[1\.9rem\]{
  height: 1.9rem;
}

.arh-h-\[100\%\]{
  height: 100%;
}

.arh-h-\[100px\]{
  height: 100px;
}

.arh-h-\[100vh\]{
  height: 100vh;
}

.arh-h-\[10px\]{
  height: 10px;
}

.arh-h-\[115px\]{
  height: 115px;
}

.arh-h-\[13\.97px\]{
  height: 13.97px;
}

.arh-h-\[14px\]{
  height: 14px;
}

.arh-h-\[150px\]{
  height: 150px;
}

.arh-h-\[156px\]{
  height: 156px;
}

.arh-h-\[15vh\]{
  height: 15vh;
}

.arh-h-\[18\.85px\]{
  height: 18.85px;
}

.arh-h-\[180px\]{
  height: 180px;
}

.arh-h-\[18px\]{
  height: 18px;
}

.arh-h-\[1rem\]{
  height: 1rem;
}

.arh-h-\[2\.125rem\]{
  height: 2.125rem;
}

.arh-h-\[2\.188rem\]{
  height: 2.188rem;
}

.arh-h-\[2\.25rem\]{
  height: 2.25rem;
}

.arh-h-\[2\.375rem\]{
  height: 2.375rem;
}

.arh-h-\[2\.5rem\]{
  height: 2.5rem;
}

.arh-h-\[2\.625rem\]{
  height: 2.625rem;
}

.arh-h-\[2\.688rem\]{
  height: 2.688rem;
}

.arh-h-\[2\.75rem\]{
  height: 2.75rem;
}

.arh-h-\[2\.875rem\]{
  height: 2.875rem;
}

.arh-h-\[20px\]{
  height: 20px;
}

.arh-h-\[25px\]{
  height: 25px;
}

.arh-h-\[3\.125rem\]{
  height: 3.125rem;
}

.arh-h-\[3\.892rem\]{
  height: 3.892rem;
}

.arh-h-\[30px\]{
  height: 30px;
}

.arh-h-\[31px\]{
  height: 31px;
}

.arh-h-\[33\.125rem\]{
  height: 33.125rem;
}

.arh-h-\[34px\]{
  height: 34px;
}

.arh-h-\[35px\]{
  height: 35px;
}

.arh-h-\[38px\]{
  height: 38px;
}

.arh-h-\[39px\]{
  height: 39px;
}

.arh-h-\[4\.625rem\]{
  height: 4.625rem;
}

.arh-h-\[40px\]{
  height: 40px;
}

.arh-h-\[42px\]{
  height: 42px;
}

.arh-h-\[43px\]{
  height: 43px;
}

.arh-h-\[44\.84px\]{
  height: 44.84px;
}

.arh-h-\[44px\]{
  height: 44px;
}

.arh-h-\[45px\]{
  height: 45px;
}

.arh-h-\[45vh\]{
  height: 45vh;
}

.arh-h-\[4rem\]{
  height: 4rem;
}

.arh-h-\[5\.625rem\]{
  height: 5.625rem;
}

.arh-h-\[5\.938rem\]{
  height: 5.938rem;
}

.arh-h-\[50px\]{
  height: 50px;
}

.arh-h-\[51px\]{
  height: 51px;
}

.arh-h-\[573px\]{
  height: 573px;
}

.arh-h-\[5rem\]{
  height: 5rem;
}

.arh-h-\[5vh\]{
  height: 5vh;
}

.arh-h-\[600px\]{
  height: 600px;
}

.arh-h-\[601px\]{
  height: 601px;
}

.arh-h-\[65px\]{
  height: 65px;
}

.arh-h-\[6px\]{
  height: 6px;
}

.arh-h-\[7\.224rem\]{
  height: 7.224rem;
}

.arh-h-\[70px\]{
  height: 70px;
}

.arh-h-\[71\.75vh\]{
  height: 71.75vh;
}

.arh-h-\[71px\]{
  height: 71px;
}

.arh-h-\[735px\]{
  height: 735px;
}

.arh-h-\[75vh\]{
  height: 75vh;
}

.arh-h-\[775px\]{
  height: 775px;
}

.arh-h-\[81px\]{
  height: 81px;
}

.arh-h-\[8px\]{
  height: 8px;
}

.arh-h-\[9\.25rem\]{
  height: 9.25rem;
}

.arh-h-\[90px\]{
  height: 90px;
}

.arh-h-\[calc\(100\%-8px\)\]{
  height: calc(100% - 8px);
}

.arh-h-\[calc\(100vh-500px\)\]{
  height: calc(100vh - 500px);
}

.arh-h-\[calc\(100vh-74px\)\]{
  height: calc(100vh - 74px);
}

.arh-h-auto{
  height: auto;
}

.arh-h-full{
  height: 100%;
}

.arh-h-px{
  height: 1px;
}

.arh-h-screen{
  height: 100vh;
}

.\!arh-max-h-56{
  max-height: 14rem !important;
}

.\!arh-max-h-64{
  max-height: 16rem !important;
}

.arh-max-h-0{
  max-height: 0px;
}

.arh-max-h-28{
  max-height: 7rem;
}

.arh-max-h-36{
  max-height: 9rem;
}

.arh-max-h-52{
  max-height: 13rem;
}

.arh-max-h-56{
  max-height: 14rem;
}

.arh-max-h-60{
  max-height: 15rem;
}

.arh-max-h-72{
  max-height: 18rem;
}

.arh-max-h-\[100px\]{
  max-height: 100px;
}

.arh-max-h-\[11rem\]{
  max-height: 11rem;
}

.arh-max-h-\[15\.7rem\]{
  max-height: 15.7rem;
}

.arh-max-h-\[155px\]{
  max-height: 155px;
}

.arh-max-h-\[18rem\]{
  max-height: 18rem;
}

.arh-max-h-\[190px\]{
  max-height: 190px;
}

.arh-max-h-\[20\.188rem\]{
  max-height: 20.188rem;
}

.arh-max-h-\[23\.5rem\]{
  max-height: 23.5rem;
}

.arh-max-h-\[29\.563rem\]{
  max-height: 29.563rem;
}

.arh-max-h-\[32rem\]{
  max-height: 32rem;
}

.arh-max-h-\[33\.125rem\]{
  max-height: 33.125rem;
}

.arh-max-h-\[35rem\]{
  max-height: 35rem;
}

.arh-max-h-\[40px\]{
  max-height: 40px;
}

.arh-max-h-\[41rem\]{
  max-height: 41rem;
}

.arh-max-h-\[42rem\]{
  max-height: 42rem;
}

.arh-max-h-\[5\.125rem\]{
  max-height: 5.125rem;
}

.arh-max-h-\[5\.625rem\]{
  max-height: 5.625rem;
}

.arh-max-h-\[55vh\]{
  max-height: 55vh;
}

.arh-max-h-\[6\.813rem\]{
  max-height: 6.813rem;
}

.arh-max-h-\[80vh\]{
  max-height: 80vh;
}

.arh-max-h-\[90vh\]{
  max-height: 90vh;
}

.arh-max-h-\[990px\]{
  max-height: 990px;
}

.arh-max-h-\[calc\(100dvh-82px\)\]{
  max-height: calc(100dvh - 82px);
}

.arh-max-h-\[calc\(100vh-82px\)\]{
  max-height: calc(100vh - 82px);
}

.arh-max-h-full{
  max-height: 100%;
}

.arh-max-h-screen{
  max-height: 100vh;
}

.\!arh-min-h-\[40vh\]{
  min-height: 40vh !important;
}

.\!arh-min-h-\[calc\(100vh-500px\)\]{
  min-height: calc(100vh - 500px) !important;
}

.arh-min-h-16{
  min-height: 4rem;
}

.arh-min-h-24{
  min-height: 6rem;
}

.arh-min-h-\[151px\]{
  min-height: 151px;
}

.arh-min-h-\[230px\]{
  min-height: 230px;
}

.arh-min-h-\[3\.375rem\]{
  min-height: 3.375rem;
}

.arh-min-h-\[40px\]{
  min-height: 40px;
}

.arh-min-h-\[48\.25rem\]{
  min-height: 48.25rem;
}

.arh-min-h-\[5\.125rem\]{
  min-height: 5.125rem;
}

.arh-min-h-\[50dvh\]{
  min-height: 50dvh;
}

.arh-min-h-\[519px\]{
  min-height: 519px;
}

.arh-min-h-\[58vh\]{
  min-height: 58vh;
}

.arh-min-h-\[5rem\]{
  min-height: 5rem;
}

.arh-min-h-\[6\.813rem\]{
  min-height: 6.813rem;
}

.arh-min-h-\[60dvh\]{
  min-height: 60dvh;
}

.arh-min-h-\[65\.938rem\]{
  min-height: 65.938rem;
}

.arh-min-h-\[7rem\]{
  min-height: 7rem;
}

.arh-min-h-\[80dvh\]{
  min-height: 80dvh;
}

.arh-min-h-\[80px\]{
  min-height: 80px;
}

.arh-min-h-\[80vh\]{
  min-height: 80vh;
}

.arh-min-h-\[calc\(100vh-4rem\)\]{
  min-height: calc(100vh - 4rem);
}

.arh-min-h-\[calc\(100vh-6\.5rem\)\]{
  min-height: calc(100vh - 6.5rem);
}

.arh-min-h-\[calc\(100vh-7rem\)\]{
  min-height: calc(100vh - 7rem);
}

.arh-min-h-full{
  min-height: 100%;
}

.arh-min-h-screen{
  min-height: 100vh;
}

.\!arh-w-1\.5{
  width: 0.375rem !important;
}

.\!arh-w-1\/2{
  width: 50% !important;
}

.\!arh-w-1\/5{
  width: 20% !important;
}

.\!arh-w-1\/6{
  width: 16.666667% !important;
}

.\!arh-w-2{
  width: 0.5rem !important;
}

.\!arh-w-2\/5{
  width: 40% !important;
}

.\!arh-w-3{
  width: 0.75rem !important;
}

.\!arh-w-60{
  width: 15rem !important;
}

.\!arh-w-\[10\.625rem\]{
  width: 10.625rem !important;
}

.\!arh-w-\[15px\]{
  width: 15px !important;
}

.\!arh-w-\[16\.188rem\]{
  width: 16.188rem !important;
}

.\!arh-w-\[161\.52px\]{
  width: 161.52px !important;
}

.\!arh-w-\[18px\]{
  width: 18px !important;
}

.\!arh-w-\[20\.188rem\]{
  width: 20.188rem !important;
}

.\!arh-w-\[21\.438rem\]{
  width: 21.438rem !important;
}

.\!arh-w-\[300px\]{
  width: 300px !important;
}

.\!arh-w-\[35px\]{
  width: 35px !important;
}

.\!arh-w-\[5rem\]{
  width: 5rem !important;
}

.\!arh-w-\[60\%\]{
  width: 60% !important;
}

.\!arh-w-\[640px\]{
  width: 640px !important;
}

.\!arh-w-\[6rem\]{
  width: 6rem !important;
}

.\!arh-w-\[80\%\]{
  width: 80% !important;
}

.\!arh-w-auto{
  width: auto !important;
}

.\!arh-w-fit{
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.\!arh-w-full{
  width: 100% !important;
}

.arh-w-0{
  width: 0px;
}

.arh-w-1\.5{
  width: 0.375rem;
}

.arh-w-1\/12{
  width: 8.333333%;
}

.arh-w-1\/2{
  width: 50%;
}

.arh-w-1\/3{
  width: 33.333333%;
}

.arh-w-1\/4{
  width: 25%;
}

.arh-w-1\/5{
  width: 20%;
}

.arh-w-1\/6{
  width: 16.666667%;
}

.arh-w-10{
  width: 2.5rem;
}

.arh-w-11{
  width: 2.75rem;
}

.arh-w-11\/12{
  width: 91.666667%;
}

.arh-w-12{
  width: 3rem;
}

.arh-w-14{
  width: 3.5rem;
}

.arh-w-16{
  width: 4rem;
}

.arh-w-18{
  width: 1.125rem;
}

.arh-w-2{
  width: 0.5rem;
}

.arh-w-2\.5{
  width: 0.625rem;
}

.arh-w-2\/3{
  width: 66.666667%;
}

.arh-w-2\/5{
  width: 40%;
}

.arh-w-2\/6{
  width: 33.333333%;
}

.arh-w-20{
  width: 5rem;
}

.arh-w-24{
  width: 6rem;
}

.arh-w-28{
  width: 7rem;
}

.arh-w-3{
  width: 0.75rem;
}

.arh-w-3\/12{
  width: 25%;
}

.arh-w-3\/4{
  width: 75%;
}

.arh-w-3\/5{
  width: 60%;
}

.arh-w-3\/9\.2{
  width: 32%;
}

.arh-w-32{
  width: 8rem;
}

.arh-w-36{
  width: 9rem;
}

.arh-w-4{
  width: 1rem;
}

.arh-w-4\/5{
  width: 80%;
}

.arh-w-4\/6{
  width: 66.666667%;
}

.arh-w-40{
  width: 10rem;
}

.arh-w-44{
  width: 11rem;
}

.arh-w-48{
  width: 12rem;
}

.arh-w-5{
  width: 1.25rem;
}

.arh-w-5\/12{
  width: 41.666667%;
}

.arh-w-5\/6{
  width: 83.333333%;
}

.arh-w-52{
  width: 13rem;
}

.arh-w-56{
  width: 14rem;
}

.arh-w-6{
  width: 1.5rem;
}

.arh-w-60{
  width: 15rem;
}

.arh-w-64{
  width: 16rem;
}

.arh-w-7{
  width: 1.75rem;
}

.arh-w-7\/12{
  width: 58.333333%;
}

.arh-w-8{
  width: 2rem;
}

.arh-w-80{
  width: 20rem;
}

.arh-w-9{
  width: 2.25rem;
}

.arh-w-96{
  width: 24rem;
}

.arh-w-\[0\.438rem\]{
  width: 0.438rem;
}

.arh-w-\[0\.5rem\]{
  width: 0.5rem;
}

.arh-w-\[0\.813rem\]{
  width: 0.813rem;
}

.arh-w-\[1\.063rem\]{
  width: 1.063rem;
}

.arh-w-\[1\.547rem\]{
  width: 1.547rem;
}

.arh-w-\[1\.5rem\]{
  width: 1.5rem;
}

.arh-w-\[1\.654rem\]{
  width: 1.654rem;
}

.arh-w-\[1\.875rem\]{
  width: 1.875rem;
}

.arh-w-\[10\%\]{
  width: 10%;
}

.arh-w-\[10\.5rem\]{
  width: 10.5rem;
}

.arh-w-\[100px\]{
  width: 100px;
}

.arh-w-\[10px\]{
  width: 10px;
}

.arh-w-\[10rem\]{
  width: 10rem;
}

.arh-w-\[11\%\]{
  width: 11%;
}

.arh-w-\[11\.125rem\]{
  width: 11.125rem;
}

.arh-w-\[12\%\]{
  width: 12%;
}

.arh-w-\[12\.5\%\]{
  width: 12.5%;
}

.arh-w-\[120\%\]{
  width: 120%;
}

.arh-w-\[120px\]{
  width: 120px;
}

.arh-w-\[121px\]{
  width: 121px;
}

.arh-w-\[128px\]{
  width: 128px;
}

.arh-w-\[12rem\]{
  width: 12rem;
}

.arh-w-\[13\%\]{
  width: 13%;
}

.arh-w-\[14\%\]{
  width: 14%;
}

.arh-w-\[14px\]{
  width: 14px;
}

.arh-w-\[15\%\]{
  width: 15%;
}

.arh-w-\[15\.5rem\]{
  width: 15.5rem;
}

.arh-w-\[15\.625rem\]{
  width: 15.625rem;
}

.arh-w-\[15rem\]{
  width: 15rem;
}

.arh-w-\[16\%\]{
  width: 16%;
}

.arh-w-\[17\%\]{
  width: 17%;
}

.arh-w-\[17px\]{
  width: 17px;
}

.arh-w-\[17rem\]{
  width: 17rem;
}

.arh-w-\[18\%\]{
  width: 18%;
}

.arh-w-\[18\.75rem\]{
  width: 18.75rem;
}

.arh-w-\[18px\]{
  width: 18px;
}

.arh-w-\[19\%\]{
  width: 19%;
}

.arh-w-\[1rem\]{
  width: 1rem;
}

.arh-w-\[2\%\]{
  width: 2%;
}

.arh-w-\[2\.188rem\]{
  width: 2.188rem;
}

.arh-w-\[2\.363rem\]{
  width: 2.363rem;
}

.arh-w-\[2\.3rem\]{
  width: 2.3rem;
}

.arh-w-\[2\.5rem\]{
  width: 2.5rem;
}

.arh-w-\[2\.625rem\]{
  width: 2.625rem;
}

.arh-w-\[2\.75rem\]{
  width: 2.75rem;
}

.arh-w-\[2\.875rem\]{
  width: 2.875rem;
}

.arh-w-\[20\%\]{
  width: 20%;
}

.arh-w-\[200px\]{
  width: 200px;
}

.arh-w-\[20px\]{
  width: 20px;
}

.arh-w-\[21\%\]{
  width: 21%;
}

.arh-w-\[22\%\]{
  width: 22%;
}

.arh-w-\[22\.188rem\]{
  width: 22.188rem;
}

.arh-w-\[22\.3rem\]{
  width: 22.3rem;
}

.arh-w-\[22rem\]{
  width: 22rem;
}

.arh-w-\[23\%\]{
  width: 23%;
}

.arh-w-\[23\.5\%\]{
  width: 23.5%;
}

.arh-w-\[23rem\]{
  width: 23rem;
}

.arh-w-\[24\%\]{
  width: 24%;
}

.arh-w-\[24\.125rem\]{
  width: 24.125rem;
}

.arh-w-\[244px\]{
  width: 244px;
}

.arh-w-\[25\%\]{
  width: 25%;
}

.arh-w-\[25\.5\%\]{
  width: 25.5%;
}

.arh-w-\[250px\]{
  width: 250px;
}

.arh-w-\[26\%\]{
  width: 26%;
}

.arh-w-\[26px\]{
  width: 26px;
}

.arh-w-\[27px\]{
  width: 27px;
}

.arh-w-\[28\%\]{
  width: 28%;
}

.arh-w-\[3\%\]{
  width: 3%;
}

.arh-w-\[3\.438rem\]{
  width: 3.438rem;
}

.arh-w-\[3\.892rem\]{
  width: 3.892rem;
}

.arh-w-\[3\.906rem\]{
  width: 3.906rem;
}

.arh-w-\[30\%\]{
  width: 30%;
}

.arh-w-\[300px\]{
  width: 300px;
}

.arh-w-\[302px\]{
  width: 302px;
}

.arh-w-\[30px\]{
  width: 30px;
}

.arh-w-\[30rem\]{
  width: 30rem;
}

.arh-w-\[31\%\]{
  width: 31%;
}

.arh-w-\[31\.25\%\]{
  width: 31.25%;
}

.arh-w-\[32\%\]{
  width: 32%;
}

.arh-w-\[320px\]{
  width: 320px;
}

.arh-w-\[33\%\]{
  width: 33%;
}

.arh-w-\[330px\]{
  width: 330px;
}

.arh-w-\[34px\]{
  width: 34px;
}

.arh-w-\[35\%\]{
  width: 35%;
}

.arh-w-\[350px\]{
  width: 350px;
}

.arh-w-\[35px\]{
  width: 35px;
}

.arh-w-\[36\.125rem\]{
  width: 36.125rem;
}

.arh-w-\[386px\]{
  width: 386px;
}

.arh-w-\[38px\]{
  width: 38px;
}

.arh-w-\[39\.2\%\]{
  width: 39.2%;
}

.arh-w-\[39px\]{
  width: 39px;
}

.arh-w-\[4\%\]{
  width: 4%;
}

.arh-w-\[4\.313rem\]{
  width: 4.313rem;
}

.arh-w-\[4\.375rem\]{
  width: 4.375rem;
}

.arh-w-\[4\.563rem\]{
  width: 4.563rem;
}

.arh-w-\[4\.5rem\]{
  width: 4.5rem;
}

.arh-w-\[40\%\]{
  width: 40%;
}

.arh-w-\[403px\]{
  width: 403px;
}

.arh-w-\[40px\]{
  width: 40px;
}

.arh-w-\[40rem\]{
  width: 40rem;
}

.arh-w-\[42px\]{
  width: 42px;
}

.arh-w-\[43\%\]{
  width: 43%;
}

.arh-w-\[45\%\]{
  width: 45%;
}

.arh-w-\[464px\]{
  width: 464px;
}

.arh-w-\[47\%\]{
  width: 47%;
}

.arh-w-\[47\.125rem\]{
  width: 47.125rem;
}

.arh-w-\[470px\]{
  width: 470px;
}

.arh-w-\[48\%\]{
  width: 48%;
}

.arh-w-\[48px\]{
  width: 48px;
}

.arh-w-\[4rem\]{
  width: 4rem;
}

.arh-w-\[5\%\]{
  width: 5%;
}

.arh-w-\[5\.438rem\]{
  width: 5.438rem;
}

.arh-w-\[5\.938rem\]{
  width: 5.938rem;
}

.arh-w-\[50\%\]{
  width: 50%;
}

.arh-w-\[500px\]{
  width: 500px;
}

.arh-w-\[50px\]{
  width: 50px;
}

.arh-w-\[57\%\]{
  width: 57%;
}

.arh-w-\[57\.69px\]{
  width: 57.69px;
}

.arh-w-\[58\.375rem\]{
  width: 58.375rem;
}

.arh-w-\[6\%\]{
  width: 6%;
}

.arh-w-\[6\.25rem\]{
  width: 6.25rem;
}

.arh-w-\[6\.5rem\]{
  width: 6.5rem;
}

.arh-w-\[60\%\]{
  width: 60%;
}

.arh-w-\[60px\]{
  width: 60px;
}

.arh-w-\[62\%\]{
  width: 62%;
}

.arh-w-\[64px\]{
  width: 64px;
}

.arh-w-\[65\%\]{
  width: 65%;
}

.arh-w-\[66\%\]{
  width: 66%;
}

.arh-w-\[68\.75\%\]{
  width: 68.75%;
}

.arh-w-\[6px\]{
  width: 6px;
}

.arh-w-\[7\%\]{
  width: 7%;
}

.arh-w-\[7\.349rem\]{
  width: 7.349rem;
}

.arh-w-\[7\.875rem\]{
  width: 7.875rem;
}

.arh-w-\[70px\]{
  width: 70px;
}

.arh-w-\[72\%\]{
  width: 72%;
}

.arh-w-\[72\.5rem\]{
  width: 72.5rem;
}

.arh-w-\[75\%\]{
  width: 75%;
}

.arh-w-\[7Z0\%\]{
  width: 7Z0%;
}

.arh-w-\[8\%\]{
  width: 8%;
}

.arh-w-\[8\.41px\]{
  width: 8.41px;
}

.arh-w-\[8\.5\%\]{
  width: 8.5%;
}

.arh-w-\[8\.5rem\]{
  width: 8.5rem;
}

.arh-w-\[80\%\]{
  width: 80%;
}

.arh-w-\[80\.5rem\]{
  width: 80.5rem;
}

.arh-w-\[83\%\]{
  width: 83%;
}

.arh-w-\[83px\]{
  width: 83px;
}

.arh-w-\[85\%\]{
  width: 85%;
}

.arh-w-\[86\.14\%\]{
  width: 86.14%;
}

.arh-w-\[87px\]{
  width: 87px;
}

.arh-w-\[8px\]{
  width: 8px;
}

.arh-w-\[8rem\]{
  width: 8rem;
}

.arh-w-\[9\%\]{
  width: 9%;
}

.arh-w-\[90\%\]{
  width: 90%;
}

.arh-w-\[938px\]{
  width: 938px;
}

.arh-w-\[95\%\]{
  width: 95%;
}

.arh-w-\[96px\]{
  width: 96px;
}

.arh-w-\[98\%\]{
  width: 98%;
}

.arh-w-\[calc\(100\%\+100px\)\]{
  width: calc(100% + 100px);
}

.arh-w-\[calc\(100\%\+176px\)\]{
  width: calc(100% + 176px);
}

.arh-w-\[calc\(25\%-1\.875rem\)\]{
  width: calc(25% - 1.875rem);
}

.arh-w-\[calc\(33\.33\%-1\.875rem\)\]{
  width: calc(33.33% - 1.875rem);
}

.arh-w-\[calc\(50\%-30px\)\]{
  width: calc(50% - 30px);
}

.arh-w-auto{
  width: auto;
}

.arh-w-fit{
  width: -moz-fit-content;
  width: fit-content;
}

.arh-w-full{
  width: 100%;
}

.arh-w-gridJobPartenary{
  width: calc(100% + 0.7rem);
}

.arh-w-inputFilter{
  width: 30%;
}

.arh-w-\[12\.5rem\]{
  width: 12.5rem;
}

.\!arh-w-\[275px\]{
  width: 275px !important;
}

.arh-w-\[240px\]{
  width: 240px;
}

.\!arh-min-w-inherit{
  min-width: inherit !important;
}

.arh-min-w-0{
  min-width: 0px;
}

.arh-min-w-24{
  min-width: 6rem;
}

.arh-min-w-44{
  min-width: 11rem;
}

.arh-min-w-48{
  min-width: 12rem;
}

.arh-min-w-60{
  min-width: 15rem;
}

.arh-min-w-64{
  min-width: 16rem;
}

.arh-min-w-72{
  min-width: 18rem;
}

.arh-min-w-80{
  min-width: 20rem;
}

.arh-min-w-\[0\.5rem\]{
  min-width: 0.5rem;
}

.arh-min-w-\[10\.5rem\]{
  min-width: 10.5rem;
}

.arh-min-w-\[11\.5rem\]{
  min-width: 11.5rem;
}

.arh-min-w-\[14\.375rem\]{
  min-width: 14.375rem;
}

.arh-min-w-\[2\.3rem\]{
  min-width: 2.3rem;
}

.arh-min-w-\[2\.563rem\]{
  min-width: 2.563rem;
}

.arh-min-w-\[2\.625rem\]{
  min-width: 2.625rem;
}

.arh-min-w-\[2\.813rem\]{
  min-width: 2.813rem;
}

.arh-min-w-\[20\.625rem\]{
  min-width: 20.625rem;
}

.arh-min-w-\[200px\]{
  min-width: 200px;
}

.arh-min-w-\[228px\]{
  min-width: 228px;
}

.arh-min-w-\[31\.25rem\]{
  min-width: 31.25rem;
}

.arh-min-w-\[33\.688rem\]{
  min-width: 33.688rem;
}

.arh-min-w-\[340px\]{
  min-width: 340px;
}

.arh-min-w-\[4\.9rem\]{
  min-width: 4.9rem;
}

.arh-min-w-\[40px\]{
  min-width: 40px;
}

.arh-min-w-\[50\%\]{
  min-width: 50%;
}

.arh-min-w-\[50px\]{
  min-width: 50px;
}

.arh-min-w-\[5rem\]{
  min-width: 5rem;
}

.arh-min-w-\[6\.813rem\]{
  min-width: 6.813rem;
}

.arh-min-w-\[6\.875rem\]{
  min-width: 6.875rem;
}

.arh-min-w-\[6px\]{
  min-width: 6px;
}

.arh-min-w-\[7rem\]{
  min-width: 7rem;
}

.\!arh-max-w-\[110px\]{
  max-width: 110px !important;
}

.arh-max-w-2xl{
  max-width: 42rem;
}

.arh-max-w-60{
  max-width: 15rem;
}

.arh-max-w-72{
  max-width: 18rem;
}

.arh-max-w-96{
  max-width: 24rem;
}

.arh-max-w-\[100rem\]{
  max-width: 100rem;
}

.arh-max-w-\[105px\]{
  max-width: 105px;
}

.arh-max-w-\[10rem\]{
  max-width: 10rem;
}

.arh-max-w-\[11\.25rem\]{
  max-width: 11.25rem;
}

.arh-max-w-\[11rem\]{
  max-width: 11rem;
}

.arh-max-w-\[12\.25rem\]{
  max-width: 12.25rem;
}

.arh-max-w-\[12rem\]{
  max-width: 12rem;
}

.arh-max-w-\[14\.5rem\]{
  max-width: 14.5rem;
}

.arh-max-w-\[140px\]{
  max-width: 140px;
}

.arh-max-w-\[15\.5rem\]{
  max-width: 15.5rem;
}

.arh-max-w-\[150px\]{
  max-width: 150px;
}

.arh-max-w-\[15rem\]{
  max-width: 15rem;
}

.arh-max-w-\[161\.52px\]{
  max-width: 161.52px;
}

.arh-max-w-\[185px\]{
  max-width: 185px;
}

.arh-max-w-\[20rem\]{
  max-width: 20rem;
}

.arh-max-w-\[230px\]{
  max-width: 230px;
}

.arh-max-w-\[250px\]{
  max-width: 250px;
}

.arh-max-w-\[25rem\]{
  max-width: 25rem;
}

.arh-max-w-\[273px\]{
  max-width: 273px;
}

.arh-max-w-\[280px\]{
  max-width: 280px;
}

.arh-max-w-\[29\.25rem\]{
  max-width: 29.25rem;
}

.arh-max-w-\[30\%\]{
  max-width: 30%;
}

.arh-max-w-\[300px\]{
  max-width: 300px;
}

.arh-max-w-\[35\%\]{
  max-width: 35%;
}

.arh-max-w-\[35vw\]{
  max-width: 35vw;
}

.arh-max-w-\[40\%\]{
  max-width: 40%;
}

.arh-max-w-\[40px\]{
  max-width: 40px;
}

.arh-max-w-\[43rem\]{
  max-width: 43rem;
}

.arh-max-w-\[45\%\]{
  max-width: 45%;
}

.arh-max-w-\[45rem\]{
  max-width: 45rem;
}

.arh-max-w-\[492px\]{
  max-width: 492px;
}

.arh-max-w-\[500px\]{
  max-width: 500px;
}

.arh-max-w-\[555px\]{
  max-width: 555px;
}

.arh-max-w-\[5rem\]{
  max-width: 5rem;
}

.arh-max-w-\[6\.25rem\]{
  max-width: 6.25rem;
}

.arh-max-w-\[601px\]{
  max-width: 601px;
}

.arh-max-w-\[607px\]{
  max-width: 607px;
}

.arh-max-w-\[60rem\]{
  max-width: 60rem;
}

.arh-max-w-\[66px\]{
  max-width: 66px;
}

.arh-max-w-\[70\%\]{
  max-width: 70%;
}

.arh-max-w-\[90rem\]{
  max-width: 90rem;
}

.arh-max-w-\[9rem\]{
  max-width: 9rem;
}

.arh-max-w-fit{
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.arh-max-w-full{
  max-width: 100%;
}

.arh-max-w-md{
  max-width: 28rem;
}

.arh-max-w-xs{
  max-width: 20rem;
}

.arh-max-w-64{
  max-width: 16rem;
}

.\!arh-flex-\[0\.25\]{
  flex: 0.25 !important;
}

.arh-flex-1{
  flex: 1 1 0%;
}

.arh-flex-\[0\.25_0\.25_0\]{
  flex: 0.25 0.25 0;
}

.arh-flex-\[0\.5_0\.5_0\%\]{
  flex: 0.5 0.5 0%;
}

.arh-flex-shrink-0{
  flex-shrink: 0;
}

.arh-shrink{
  flex-shrink: 1;
}

.arh-shrink-0{
  flex-shrink: 0;
}

.arh-grow{
  flex-grow: 1;
}

.arh-grow-\[0\.25\]{
  flex-grow: 0.25;
}

.arh-basis-1\/2{
  flex-basis: 50%;
}

.arh-basis-1\/5{
  flex-basis: 20%;
}

.arh-basis-3\/12{
  flex-basis: 25%;
}

.arh-basis-4\/5{
  flex-basis: 80%;
}

.arh-basis-9\/12{
  flex-basis: 75%;
}

.arh-basis-\[0\%\]{
  flex-basis: 0%;
}

.arh-basis-\[40\%\]{
  flex-basis: 40%;
}

.arh-basis-\[calc\(50\%-0\.5rem\)\]{
  flex-basis: calc(50% - 0.5rem);
}

.arh-table-auto{
  table-layout: auto;
}

.arh-table-fixed{
  table-layout: fixed;
}

.arh-border-collapse{
  border-collapse: collapse;
}

.arh-border-separate{
  border-collapse: separate;
}

.arh-border-spacing-0{
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.arh-border-spacing-y-2{
  --tw-border-spacing-y: 0.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.arh-origin-top{
  transform-origin: top;
}

.-arh-translate-x-1{
  --tw-translate-x: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-arh-translate-y-1\/2{
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-arh-translate-y-2\/4{
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-arh-translate-y-\[50\%\]{
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-translate-x-0{
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-translate-x-3{
  --tw-translate-x: 0.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-translate-x-\[110px\]{
  --tw-translate-x: 110px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-translate-x-\[305px\]{
  --tw-translate-x: 305px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-translate-x-full{
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-translate-y-0{
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-translate-y-1{
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-arh-rotate-0{
  --tw-rotate: -0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-arh-rotate-180{
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-arh-rotate-90{
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-rotate-0{
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-rotate-180{
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-rotate-45{
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-rotate-90{
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-rotate-\[-90deg\]{
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-scale-100{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-scale-95{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-scale-\[0\.99\]{
  --tw-scale-x: 0.99;
  --tw-scale-y: 0.99;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-scale-y-0{
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-scale-y-100{
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-transform{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes arh-bell-ring{
  0%{
    transform: rotate(0deg);
  }

  2.5%{
    transform: rotate(10deg);
  }

  7.5%{
    transform: rotate(-10deg);
  }

  12.5%{
    transform: rotate(10deg*.5);
  }

  17.5%{
    transform: rotate(-10deg*.5);
  }

  22.5%{
    transform: rotate(10deg*.25);
  }

  25%{
    transform: rotate(0deg);
  }
}

.arh-animate-\[bell-ring_3s_linear_0s_infinite\]{
  animation: arh-bell-ring 3s linear 0s infinite;
}

@keyframes arh-initialWave{
  0%, 60%, 100%{
    transform: initial;
  }

  30%{
    transform: translateY(-25px);
  }
}

.arh-animate-\[initialWave_1\.3s_-0\.9s_ease-in-out_infinite\]{
  animation: arh-initialWave 1.3s -0.9s ease-in-out infinite;
}

@keyframes arh-initialWave{
  0%, 60%, 100%{
    transform: initial;
  }

  30%{
    transform: translateY(-25px);
  }
}

.arh-animate-\[initialWave_1\.3s_-1\.1s_ease-in-out_infinite\]{
  animation: arh-initialWave 1.3s -1.1s ease-in-out infinite;
}

@keyframes arh-initialWave{
  0%, 60%, 100%{
    transform: initial;
  }

  30%{
    transform: translateY(-25px);
  }
}

.arh-animate-\[initialWave_1\.3s_ease-in-out_infinite\]{
  animation: arh-initialWave 1.3s ease-in-out infinite;
}

@keyframes arh-reveal{
  from{
    opacity: 0;
  }

  to{
    opacity: 1;
  }
}

.arh-animate-\[reveal_1\.3s_ease-out\]{
  animation: arh-reveal 1.3s ease-out;
}

@keyframes arh-showFadeIn{
  from{
    transform: translateY(100%);
  }

  to{
    transform: translateY(0);
  }
}

.arh-animate-\[showFadeIn_1\.3s_1\.3s_ease-out_backwards\]{
  animation: arh-showFadeIn 1.3s 1.3s ease-out backwards;
}

@keyframes arh-showFadeIn{
  from{
    transform: translateY(100%);
  }

  to{
    transform: translateY(0);
  }
}

.arh-animate-\[showFadeIn_1\.3s_2\.6s_ease-out_backwards\]{
  animation: arh-showFadeIn 1.3s 2.6s ease-out backwards;
}

@keyframes arh-showFadeIn{
  from{
    transform: translateY(100%);
  }

  to{
    transform: translateY(0);
  }
}

.arh-animate-\[showFadeIn_1\.3s_3\.9s_ease-out_backwards\]{
  animation: arh-showFadeIn 1.3s 3.9s ease-out backwards;
}

@keyframes arh-wave{
  0%, 60%, 100%{
    transform: initial;
  }

  30%{
    transform: translateY(-4px);
  }
}

.arh-animate-\[wave_1\.3s_-0\.9s_ease-in-out_infinite\]{
  animation: arh-wave 1.3s -0.9s ease-in-out infinite;
}

@keyframes arh-wave{
  0%, 60%, 100%{
    transform: initial;
  }

  30%{
    transform: translateY(-4px);
  }
}

.arh-animate-\[wave_1\.3s_-1\.1s_ease-in-out_infinite\]{
  animation: arh-wave 1.3s -1.1s ease-in-out infinite;
}

@keyframes arh-wave{
  0%, 60%, 100%{
    transform: initial;
  }

  30%{
    transform: translateY(-4px);
  }
}

.arh-animate-\[wave_1\.3s_ease-in-out_infinite\]{
  animation: arh-wave 1.3s ease-in-out infinite;
}

@keyframes arh-spin{
  to{
    transform: rotate(360deg);
  }
}

.arh-animate-spin{
  animation: arh-spin 1s linear infinite;
}

@keyframes arh-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

.arh-animate-\[ping_2s_ease-in-out_infinite\]{
  animation: arh-ping 2s ease-in-out infinite;
}

.\!arh-cursor-auto{
  cursor: auto !important;
}

.\!arh-cursor-pointer{
  cursor: pointer !important;
}

.\!arh-cursor-wait{
  cursor: wait !important;
}

.arh-cursor-auto{
  cursor: auto;
}

.arh-cursor-default{
  cursor: default;
}

.arh-cursor-grabbing{
  cursor: grabbing;
}

.arh-cursor-none{
  cursor: none;
}

.arh-cursor-not-allowed{
  cursor: not-allowed;
}

.arh-cursor-pointer{
  cursor: pointer;
}

.arh-cursor-wait{
  cursor: wait;
}

.arh-select-none{
  -webkit-user-select: none;
          user-select: none;
}

.arh-resize-none{
  resize: none;
}

.arh-list-disc{
  list-style-type: disc;
}

.arh-list-none{
  list-style-type: none;
}

.arh-appearance-none{
  -webkit-appearance: none;
          appearance: none;
}

.arh-columns-2{
  columns: 2;
}

.arh-columns-3{
  columns: 3;
}

.arh-grid-flow-col{
  grid-auto-flow: column;
}

.arh-auto-rows-auto{
  grid-auto-rows: auto;
}

.arh-grid-cols-11{
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.arh-grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.arh-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.arh-grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.arh-grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.arh-grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.arh-grid-cols-\[31\%_31\%_31\%\]{
  grid-template-columns: 31% 31% 31%;
}

.arh-grid-rows-1{
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.arh-grid-rows-\[auto\]{
  grid-template-rows: auto;
}

.arh-flex-row{
  flex-direction: row;
}

.arh-flex-row-reverse{
  flex-direction: row-reverse;
}

.arh-flex-col{
  flex-direction: column;
}

.arh-flex-wrap{
  flex-wrap: wrap;
}

.arh-flex-nowrap{
  flex-wrap: nowrap;
}

.arh-place-items-center{
  place-items: center;
}

.arh-content-around{
  align-content: space-around;
}

.arh-items-start{
  align-items: flex-start;
}

.arh-items-end{
  align-items: flex-end;
}

.arh-items-center{
  align-items: center;
}

.arh-items-baseline{
  align-items: baseline;
}

.arh-items-stretch{
  align-items: stretch;
}

.\!arh-justify-start{
  justify-content: flex-start !important;
}

.arh-justify-start{
  justify-content: flex-start;
}

.arh-justify-end{
  justify-content: flex-end;
}

.\!arh-justify-center{
  justify-content: center !important;
}

.arh-justify-center{
  justify-content: center;
}

.\!arh-justify-between{
  justify-content: space-between !important;
}

.arh-justify-between{
  justify-content: space-between;
}

.arh-justify-around{
  justify-content: space-around;
}

.arh-justify-evenly{
  justify-content: space-evenly;
}

.arh-gap-0\.5{
  gap: 0.125rem;
}

.arh-gap-1{
  gap: 0.25rem;
}

.arh-gap-1\.5{
  gap: 0.375rem;
}

.arh-gap-10{
  gap: 2.5rem;
}

.arh-gap-2{
  gap: 0.5rem;
}

.arh-gap-2\.5{
  gap: 0.625rem;
}

.arh-gap-3{
  gap: 0.75rem;
}

.arh-gap-4{
  gap: 1rem;
}

.arh-gap-5{
  gap: 1.25rem;
}

.arh-gap-6{
  gap: 1.5rem;
}

.arh-gap-8{
  gap: 2rem;
}

.arh-gap-\[1\.125rem\]{
  gap: 1.125rem;
}

.arh-gap-\[1\.875rem\]{
  gap: 1.875rem;
}

.arh-gap-\[3\.4\%\]{
  gap: 3.4%;
}

.arh-gap-\[5\%\]{
  gap: 5%;
}

.arh-gap-x-10{
  column-gap: 2.5rem;
}

.arh-gap-x-14{
  column-gap: 3.5rem;
}

.arh-gap-x-2{
  column-gap: 0.5rem;
}

.arh-gap-x-20{
  column-gap: 5rem;
}

.arh-gap-x-28{
  column-gap: 7rem;
}

.arh-gap-x-4{
  column-gap: 1rem;
}

.arh-gap-x-8{
  column-gap: 2rem;
}

.arh-gap-x-\[1rem\]{
  column-gap: 1rem;
}

.arh-gap-x-\[2\.188rem\]{
  column-gap: 2.188rem;
}

.arh-gap-x-\[2\.375rem\]{
  column-gap: 2.375rem;
}

.arh-gap-x-\[5\%\]{
  column-gap: 5%;
}

.arh-gap-y-2{
  row-gap: 0.5rem;
}

.arh-gap-y-4{
  row-gap: 1rem;
}

.arh-gap-y-5{
  row-gap: 1.25rem;
}

.arh-gap-y-6{
  row-gap: 1.5rem;
}

.arh-gap-y-\[0\.688rem\]{
  row-gap: 0.688rem;
}

.arh-gap-y-\[1\.563rem\]{
  row-gap: 1.563rem;
}

.\!arh-space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.\!arh-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.\!arh-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.-arh-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.-arh-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-x-\[10px\] > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(10px * var(--tw-space-x-reverse));
  margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
}

.arh-space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.arh-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}

.arh-space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.arh-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}

.arh-space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.arh-space-y-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}

.arh-space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.arh-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.arh-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}

.arh-space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.arh-space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.arh-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
}

.arh-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.arh-space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.arh-space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.arh-space-y-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.arh-space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.arh-space-y-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.arh-space-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 1;
}

.arh-divide-x > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.arh-divide-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.arh-divide-y > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.arh-divide-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.arh-divide-solid > :not([hidden]) ~ :not([hidden]){
  border-style: solid;
}

.arh-divide-\[\#EAECF0\] > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgb(234 236 240 / var(--tw-divide-opacity));
}

.arh-divide-\[\#F2F4F7\] > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgb(242 244 247 / var(--tw-divide-opacity));
}

.arh-divide-borderTable > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgb(236 237 239 / var(--tw-divide-opacity));
}

.arh-divide-greySoft > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgb(234 236 240 / var(--tw-divide-opacity));
}

.arh-self-auto{
  align-self: auto;
}

.arh-self-start{
  align-self: flex-start;
}

.arh-overflow-auto{
  overflow: auto;
}

.arh-overflow-hidden{
  overflow: hidden;
}

.\!arh-overflow-visible{
  overflow: visible !important;
}

.arh-overflow-visible{
  overflow: visible;
}

.arh-overflow-x-auto{
  overflow-x: auto;
}

.arh-overflow-y-auto{
  overflow-y: auto;
}

.arh-overflow-x-hidden{
  overflow-x: hidden;
}

.arh-overflow-x-scroll{
  overflow-x: scroll;
}

.arh-overflow-y-scroll{
  overflow-y: scroll;
}

.arh-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arh-text-ellipsis{
  text-overflow: ellipsis;
}

.arh-whitespace-nowrap{
  white-space: nowrap;
}

.arh-whitespace-pre-line{
  white-space: pre-line;
}

.\!arh-whitespace-pre-wrap{
  white-space: pre-wrap !important;
}

.arh-whitespace-pre-wrap{
  white-space: pre-wrap;
}

.arh-whitespace-break-spaces{
  white-space: break-spaces;
}

.arh-text-wrap{
  text-wrap: wrap;
}

.arh-text-nowrap{
  text-wrap: nowrap;
}

.arh-text-balance{
  text-wrap: balance;
}

.arh-break-words{
  overflow-wrap: break-word;
}

.arh-break-all{
  word-break: break-all;
}

.\!arh-rounded{
  border-radius: 0.25rem !important;
}

.\!arh-rounded-\[0\.125rem\]{
  border-radius: 0.125rem !important;
}

.\!arh-rounded-\[1\.875rem\]{
  border-radius: 1.875rem !important;
}

.\!arh-rounded-\[5px\]{
  border-radius: 5px !important;
}

.\!arh-rounded-full{
  border-radius: 9999px !important;
}

.arh-rounded{
  border-radius: 0.25rem;
}

.arh-rounded-1\/2{
  border-radius: 50%;
}

.arh-rounded-2xl{
  border-radius: 1rem;
}

.arh-rounded-3xl{
  border-radius: 1.5rem;
}

.arh-rounded-\[0\.125rem\]{
  border-radius: 0.125rem;
}

.arh-rounded-\[0\.188rem\]{
  border-radius: 0.188rem;
}

.arh-rounded-\[0\.25rem\]{
  border-radius: 0.25rem;
}

.arh-rounded-\[0\.313rem\]{
  border-radius: 0.313rem;
}

.arh-rounded-\[0\.625rem\]{
  border-radius: 0.625rem;
}

.arh-rounded-\[0\.938rem\]{
  border-radius: 0.938rem;
}

.arh-rounded-\[1\.438rem\]{
  border-radius: 1.438rem;
}

.arh-rounded-\[1\.875rem\]{
  border-radius: 1.875rem;
}

.arh-rounded-\[10px\]{
  border-radius: 10px;
}

.arh-rounded-\[12px\]{
  border-radius: 12px;
}

.arh-rounded-\[18px\]{
  border-radius: 18px;
}

.arh-rounded-\[2\.75rem\]{
  border-radius: 2.75rem;
}

.arh-rounded-\[20px\]{
  border-radius: 20px;
}

.arh-rounded-\[23px\]{
  border-radius: 23px;
}

.arh-rounded-\[2px\]{
  border-radius: 2px;
}

.arh-rounded-\[3\.313rem\]{
  border-radius: 3.313rem;
}

.arh-rounded-\[3\.5rem\]{
  border-radius: 3.5rem;
}

.arh-rounded-\[30px\]{
  border-radius: 30px;
}

.arh-rounded-\[3px\]{
  border-radius: 3px;
}

.arh-rounded-\[40px\]{
  border-radius: 40px;
}

.arh-rounded-\[44px\]{
  border-radius: 44px;
}

.arh-rounded-\[4px\]{
  border-radius: 4px;
}

.arh-rounded-\[50\%\]{
  border-radius: 50%;
}

.arh-rounded-\[55px\]{
  border-radius: 55px;
}

.arh-rounded-\[59px\]{
  border-radius: 59px;
}

.arh-rounded-\[5px\]{
  border-radius: 5px;
}

.arh-rounded-\[6px\]{
  border-radius: 6px;
}

.arh-rounded-\[7px\]{
  border-radius: 7px;
}

.arh-rounded-\[8px\]{
  border-radius: 8px;
}

.arh-rounded-\[99px\]{
  border-radius: 99px;
}

.arh-rounded-\[9px\]{
  border-radius: 9px;
}

.arh-rounded-full{
  border-radius: 9999px;
}

.arh-rounded-lg{
  border-radius: 0.5rem;
}

.arh-rounded-md{
  border-radius: 0.375rem;
}

.arh-rounded-none{
  border-radius: 0px;
}

.arh-rounded-sm{
  border-radius: 0.125rem;
}

.arh-rounded-xl{
  border-radius: 0.75rem;
}

.arh-rounded-l-md{
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.arh-rounded-r-md{
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.arh-rounded-t{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.\!arh-rounded-br{
  border-bottom-right-radius: 0.25rem !important;
}

.\!arh-rounded-tr{
  border-top-right-radius: 0.25rem !important;
}

.arh-rounded-bl-\[6px\]{
  border-bottom-left-radius: 6px;
}

.arh-rounded-bl-md{
  border-bottom-left-radius: 0.375rem;
}

.arh-rounded-bl-none{
  border-bottom-left-radius: 0px;
}

.arh-rounded-br-\[6px\]{
  border-bottom-right-radius: 6px;
}

.arh-rounded-br-md{
  border-bottom-right-radius: 0.375rem;
}

.arh-rounded-br-none{
  border-bottom-right-radius: 0px;
}

.arh-rounded-tl{
  border-top-left-radius: 0.25rem;
}

.arh-rounded-tl-\[6px\]{
  border-top-left-radius: 6px;
}

.arh-rounded-tl-md{
  border-top-left-radius: 0.375rem;
}

.arh-rounded-tl-none{
  border-top-left-radius: 0px;
}

.arh-rounded-tr-\[6px\]{
  border-top-right-radius: 6px;
}

.arh-rounded-tr-md{
  border-top-right-radius: 0.375rem;
}

.arh-rounded-tr-none{
  border-top-right-radius: 0px;
}

.\!arh-border{
  border-width: 1px !important;
}

.\!arh-border-0{
  border-width: 0px !important;
}

.arh-border{
  border-width: 1px;
}

.arh-border-0{
  border-width: 0px;
}

.arh-border-2{
  border-width: 2px;
}

.arh-border-\[1px\]{
  border-width: 1px;
}

.arh-border-\[2px\]{
  border-width: 2px;
}

.\!arh-border-x-0{
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

.arh-border-x-0{
  border-left-width: 0px;
  border-right-width: 0px;
}

.arh-border-x-\[15px\]{
  border-left-width: 15px;
  border-right-width: 15px;
}

.arh-border-y{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.arh-border-y-0{
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.\!arh-border-b{
  border-bottom-width: 1px !important;
}

.\!arh-border-b-0{
  border-bottom-width: 0px !important;
}

.\!arh-border-r{
  border-right-width: 1px !important;
}

.\!arh-border-t-0{
  border-top-width: 0px !important;
}

.arh-border-b{
  border-bottom-width: 1px;
}

.arh-border-b-0{
  border-bottom-width: 0px;
}

.arh-border-b-2{
  border-bottom-width: 2px;
}

.arh-border-b-4{
  border-bottom-width: 4px;
}

.arh-border-b-8{
  border-bottom-width: 8px;
}

.arh-border-b-\[15px\]{
  border-bottom-width: 15px;
}

.arh-border-l{
  border-left-width: 1px;
}

.arh-border-l-0{
  border-left-width: 0px;
}

.arh-border-l-4{
  border-left-width: 4px;
}

.arh-border-l-\[6px\]{
  border-left-width: 6px;
}

.arh-border-r{
  border-right-width: 1px;
}

.arh-border-r-0{
  border-right-width: 0px;
}

.arh-border-t{
  border-top-width: 1px;
}

.arh-border-t-0{
  border-top-width: 0px;
}

.arh-border-t-\[15px\]{
  border-top-width: 15px;
}

.\!arh-border-solid{
  border-style: solid !important;
}

.arh-border-solid{
  border-style: solid;
}

.arh-border-dashed{
  border-style: dashed;
}

.\!arh-border-none{
  border-style: none !important;
}

.arh-border-none{
  border-style: none;
}

.\!arh-border-\[\#1A4E63\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(26 78 99 / var(--tw-border-opacity)) !important;
}

.\!arh-border-\[\#B0BEC5\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(176 190 197 / var(--tw-border-opacity)) !important;
}

.\!arh-border-\[\#F60A20\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(246 10 32 / var(--tw-border-opacity)) !important;
}

.\!arh-border-greyColor{
  --tw-border-opacity: 1 !important;
  border-color: rgb(249 250 252 / var(--tw-border-opacity)) !important;
}

.\!arh-border-invalidColorTextInput{
  --tw-border-opacity: 1 !important;
  border-color: rgb(246 10 32 / var(--tw-border-opacity)) !important;
}

.arh-border-\[\#191B1C\]{
  --tw-border-opacity: 1;
  border-color: rgb(25 27 28 / var(--tw-border-opacity));
}

.arh-border-\[\#2C2C31\]{
  --tw-border-opacity: 1;
  border-color: rgb(44 44 49 / var(--tw-border-opacity));
}

.arh-border-\[\#3F3F51\]{
  --tw-border-opacity: 1;
  border-color: rgb(63 63 81 / var(--tw-border-opacity));
}

.arh-border-\[\#4F9A36\]{
  --tw-border-opacity: 1;
  border-color: rgb(79 154 54 / var(--tw-border-opacity));
}

.arh-border-\[\#5E5F5F\]{
  --tw-border-opacity: 1;
  border-color: rgb(94 95 95 / var(--tw-border-opacity));
}

.arh-border-\[\#61C2E0\]{
  --tw-border-opacity: 1;
  border-color: rgb(97 194 224 / var(--tw-border-opacity));
}

.arh-border-\[\#685F9D\]{
  --tw-border-opacity: 1;
  border-color: rgb(104 95 157 / var(--tw-border-opacity));
}

.arh-border-\[\#738BC7\]{
  --tw-border-opacity: 1;
  border-color: rgb(115 139 199 / var(--tw-border-opacity));
}

.arh-border-\[\#73C7B7\]{
  --tw-border-opacity: 1;
  border-color: rgb(115 199 183 / var(--tw-border-opacity));
}

.arh-border-\[\#A09211\]{
  --tw-border-opacity: 1;
  border-color: rgb(160 146 17 / var(--tw-border-opacity));
}

.arh-border-\[\#B0BEC5\]{
  --tw-border-opacity: 1;
  border-color: rgb(176 190 197 / var(--tw-border-opacity));
}

.arh-border-\[\#BDBDBD\]{
  --tw-border-opacity: 1;
  border-color: rgb(189 189 189 / var(--tw-border-opacity));
}

.arh-border-\[\#C4C4C4\]{
  --tw-border-opacity: 1;
  border-color: rgb(196 196 196 / var(--tw-border-opacity));
}

.arh-border-\[\#D0D2D7\]{
  --tw-border-opacity: 1;
  border-color: rgb(208 210 215 / var(--tw-border-opacity));
}

.arh-border-\[\#D3E3F1\]{
  --tw-border-opacity: 1;
  border-color: rgb(211 227 241 / var(--tw-border-opacity));
}

.arh-border-\[\#D7D7D7\]{
  --tw-border-opacity: 1;
  border-color: rgb(215 215 215 / var(--tw-border-opacity));
}

.arh-border-\[\#D8D8D8\]{
  --tw-border-opacity: 1;
  border-color: rgb(216 216 216 / var(--tw-border-opacity));
}

.arh-border-\[\#DAE5E9\]{
  --tw-border-opacity: 1;
  border-color: rgb(218 229 233 / var(--tw-border-opacity));
}

.arh-border-\[\#DCDCDC\]{
  --tw-border-opacity: 1;
  border-color: rgb(220 220 220 / var(--tw-border-opacity));
}

.arh-border-\[\#DFDEDE\]{
  --tw-border-opacity: 1;
  border-color: rgb(223 222 222 / var(--tw-border-opacity));
}

.arh-border-\[\#E2EBFD\]{
  --tw-border-opacity: 1;
  border-color: rgb(226 235 253 / var(--tw-border-opacity));
}

.arh-border-\[\#E2F1FE\]{
  --tw-border-opacity: 1;
  border-color: rgb(226 241 254 / var(--tw-border-opacity));
}

.arh-border-\[\#E4B334\]{
  --tw-border-opacity: 1;
  border-color: rgb(228 179 52 / var(--tw-border-opacity));
}

.arh-border-\[\#E4ECF8\]{
  --tw-border-opacity: 1;
  border-color: rgb(228 236 248 / var(--tw-border-opacity));
}

.arh-border-\[\#E6BD93\]{
  --tw-border-opacity: 1;
  border-color: rgb(230 189 147 / var(--tw-border-opacity));
}

.arh-border-\[\#E8E8E8\]{
  --tw-border-opacity: 1;
  border-color: rgb(232 232 232 / var(--tw-border-opacity));
}

.arh-border-\[\#E9E9E9\]{
  --tw-border-opacity: 1;
  border-color: rgb(233 233 233 / var(--tw-border-opacity));
}

.arh-border-\[\#EAEAEA\]{
  --tw-border-opacity: 1;
  border-color: rgb(234 234 234 / var(--tw-border-opacity));
}

.arh-border-\[\#EAECF0\]{
  --tw-border-opacity: 1;
  border-color: rgb(234 236 240 / var(--tw-border-opacity));
}

.arh-border-\[\#ECECEC\]{
  --tw-border-opacity: 1;
  border-color: rgb(236 236 236 / var(--tw-border-opacity));
}

.arh-border-\[\#ECEDEF\]{
  --tw-border-opacity: 1;
  border-color: rgb(236 237 239 / var(--tw-border-opacity));
}

.arh-border-\[\#ED6581\]{
  --tw-border-opacity: 1;
  border-color: rgb(237 101 129 / var(--tw-border-opacity));
}

.arh-border-\[\#EDF0F4\]{
  --tw-border-opacity: 1;
  border-color: rgb(237 240 244 / var(--tw-border-opacity));
}

.arh-border-\[\#EDF2F2\]{
  --tw-border-opacity: 1;
  border-color: rgb(237 242 242 / var(--tw-border-opacity));
}

.arh-border-\[\#EFF0F0\]{
  --tw-border-opacity: 1;
  border-color: rgb(239 240 240 / var(--tw-border-opacity));
}

.arh-border-\[\#EFF0F2\]{
  --tw-border-opacity: 1;
  border-color: rgb(239 240 242 / var(--tw-border-opacity));
}

.arh-border-\[\#F1F2F3\]{
  --tw-border-opacity: 1;
  border-color: rgb(241 242 243 / var(--tw-border-opacity));
}

.arh-border-\[\#F2F4F7\]{
  --tw-border-opacity: 1;
  border-color: rgb(242 244 247 / var(--tw-border-opacity));
}

.arh-border-\[\#F60A20\]{
  --tw-border-opacity: 1;
  border-color: rgb(246 10 32 / var(--tw-border-opacity));
}

.arh-border-\[\#F9966B\]{
  --tw-border-opacity: 1;
  border-color: rgb(249 150 107 / var(--tw-border-opacity));
}

.arh-border-\[\#F9E6DD\]{
  --tw-border-opacity: 1;
  border-color: rgb(249 230 221 / var(--tw-border-opacity));
}

.arh-border-\[\#FA966B\]{
  --tw-border-opacity: 1;
  border-color: rgb(250 150 107 / var(--tw-border-opacity));
}

.arh-border-\[\#FBEED6\]{
  --tw-border-opacity: 1;
  border-color: rgb(251 238 214 / var(--tw-border-opacity));
}

.arh-border-\[\#FF0F00\]{
  --tw-border-opacity: 1;
  border-color: rgb(255 15 0 / var(--tw-border-opacity));
}

.arh-border-\[\#FF99B2\]{
  --tw-border-opacity: 1;
  border-color: rgb(255 153 178 / var(--tw-border-opacity));
}

.arh-border-\[\#FFC6AE\]{
  --tw-border-opacity: 1;
  border-color: rgb(255 198 174 / var(--tw-border-opacity));
}

.arh-border-\[\#FFCCD7\]{
  --tw-border-opacity: 1;
  border-color: rgb(255 204 215 / var(--tw-border-opacity));
}

.arh-border-\[\#cad1d7\]{
  --tw-border-opacity: 1;
  border-color: rgb(202 209 215 / var(--tw-border-opacity));
}

.arh-border-\[\#d8d8d8\]{
  --tw-border-opacity: 1;
  border-color: rgb(216 216 216 / var(--tw-border-opacity));
}

.arh-border-\[\#e5e5e5\]{
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.arh-border-\[\#e9ecef\]{
  --tw-border-opacity: 1;
  border-color: rgb(233 236 239 / var(--tw-border-opacity));
}

.arh-border-\[\#ebebeb\]{
  --tw-border-opacity: 1;
  border-color: rgb(235 235 235 / var(--tw-border-opacity));
}

.arh-border-\[\#ececec\]{
  --tw-border-opacity: 1;
  border-color: rgb(236 236 236 / var(--tw-border-opacity));
}

.arh-border-\[\#edf2f2\]{
  --tw-border-opacity: 1;
  border-color: rgb(237 242 242 / var(--tw-border-opacity));
}

.arh-border-\[\#eeeff1\]{
  --tw-border-opacity: 1;
  border-color: rgb(238 239 241 / var(--tw-border-opacity));
}

.arh-border-\[\#fcfcfc\]{
  --tw-border-opacity: 1;
  border-color: rgb(252 252 252 / var(--tw-border-opacity));
}

.arh-border-\[\#fdf6f1\]{
  --tw-border-opacity: 1;
  border-color: rgb(253 246 241 / var(--tw-border-opacity));
}

.arh-border-\[\#ffffff\]{
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.arh-border-black{
  --tw-border-opacity: 1;
  border-color: rgb(41 41 41 / var(--tw-border-opacity));
}

.arh-border-blueColor{
  --tw-border-opacity: 1;
  border-color: rgb(26 78 99 / var(--tw-border-opacity));
}

.arh-border-borderCardGreen{
  --tw-border-opacity: 1;
  border-color: rgb(163 218 217 / var(--tw-border-opacity));
}

.arh-border-borderStat{
  --tw-border-opacity: 1;
  border-color: rgb(239 240 240 / var(--tw-border-opacity));
}

.arh-border-borderTable{
  --tw-border-opacity: 1;
  border-color: rgb(236 237 239 / var(--tw-border-opacity));
}

.arh-border-btnBorderGrey{
  --tw-border-opacity: 1;
  border-color: rgb(176 190 197 / var(--tw-border-opacity));
}

.arh-border-btnGreen{
  --tw-border-opacity: 1;
  border-color: rgb(115 199 183 / var(--tw-border-opacity));
}

.arh-border-btnTableBorder{
  --tw-border-opacity: 1;
  border-color: rgb(135 148 192 / var(--tw-border-opacity));
}

.arh-border-gray-100{
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.arh-border-gray-200{
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.arh-border-gray-300{
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.arh-border-gray-500{
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.arh-border-green-200{
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

.arh-border-greenColor{
  --tw-border-opacity: 1;
  border-color: rgb(115 199 183 / var(--tw-border-opacity));
}

.arh-border-greyBlue{
  --tw-border-opacity: 1;
  border-color: rgb(215 215 215 / var(--tw-border-opacity));
}

.arh-border-greyBorderBtn{
  --tw-border-opacity: 1;
  border-color: rgb(215 217 221 / var(--tw-border-opacity));
}

.arh-border-greyBorderInput{
  --tw-border-opacity: 1;
  border-color: rgb(228 236 248 / var(--tw-border-opacity));
}

.arh-border-greyColor{
  --tw-border-opacity: 1;
  border-color: rgb(249 250 252 / var(--tw-border-opacity));
}

.arh-border-greyDisabled{
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.arh-border-greyDisabledBorder{
  --tw-border-opacity: 1;
  border-color: rgb(189 189 189 / var(--tw-border-opacity));
}

.arh-border-greyInput{
  --tw-border-opacity: 1;
  border-color: rgb(233 236 239 / var(--tw-border-opacity));
}

.arh-border-greySoft{
  --tw-border-opacity: 1;
  border-color: rgb(234 236 240 / var(--tw-border-opacity));
}

.arh-border-invalidBorderInput{
  --tw-border-opacity: 1;
  border-color: rgb(247 156 167 / var(--tw-border-opacity));
}

.arh-border-invalidColorTextInput{
  --tw-border-opacity: 1;
  border-color: rgb(246 10 32 / var(--tw-border-opacity));
}

.arh-border-linkedinBlue{
  --tw-border-opacity: 1;
  border-color: rgb(0 123 181 / var(--tw-border-opacity));
}

.arh-border-loaderBlue{
  --tw-border-opacity: 1;
  border-color: rgb(68 114 163 / var(--tw-border-opacity));
}

.arh-border-orangeColor{
  --tw-border-opacity: 1;
  border-color: rgb(252 182 152 / var(--tw-border-opacity));
}

.arh-border-red-500{
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.arh-border-teal-900{
  --tw-border-opacity: 1;
  border-color: rgb(19 78 74 / var(--tw-border-opacity));
}

.arh-border-transparent{
  border-color: transparent;
}

.arh-border-white{
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.arh-border-\[\#8794C0\]{
  --tw-border-opacity: 1;
  border-color: rgb(135 148 192 / var(--tw-border-opacity));
}

.arh-border-\[\#CFD5E0\]{
  --tw-border-opacity: 1;
  border-color: rgb(207 213 224 / var(--tw-border-opacity));
}

.arh-border-\[\#D3BE52\]{
  --tw-border-opacity: 1;
  border-color: rgb(211 190 82 / var(--tw-border-opacity));
}

.arh-border-\[\#DF3431\]{
  --tw-border-opacity: 1;
  border-color: rgb(223 52 49 / var(--tw-border-opacity));
}

.arh-border-\[\#ffc9c9\]{
  --tw-border-opacity: 1;
  border-color: rgb(255 201 201 / var(--tw-border-opacity));
}

.arh-border-x-transparent{
  border-left-color: transparent;
  border-right-color: transparent;
}

.arh-border-b-\[\#ECEDEF\]{
  --tw-border-opacity: 1;
  border-bottom-color: rgb(236 237 239 / var(--tw-border-opacity));
}

.arh-border-b-borderTable{
  --tw-border-opacity: 1;
  border-bottom-color: rgb(236 237 239 / var(--tw-border-opacity));
}

.arh-border-b-white{
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.arh-border-l-\[\#E94916\]{
  --tw-border-opacity: 1;
  border-left-color: rgb(233 73 22 / var(--tw-border-opacity));
}

.arh-border-l-greenColor{
  --tw-border-opacity: 1;
  border-left-color: rgb(115 199 183 / var(--tw-border-opacity));
}

.arh-border-l-orangeColor{
  --tw-border-opacity: 1;
  border-left-color: rgb(252 182 152 / var(--tw-border-opacity));
}

.arh-border-l-transparent{
  border-left-color: transparent;
}

.\!arh-bg-\[\#4c6c78\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(76 108 120 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-\[\#D3E3F1\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(211 227 241 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-\[\#D8D8D8\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(216 216 216 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-\[\#DAE5E9\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(218 229 233 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-\[\#EFEFEF\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-\[\#FFF3F4\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 243 244 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-\[\#fafafa\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-greyColor{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-greyInput{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-invalidBackgroundInput{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 243 244 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-lightOrange{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 239 232 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-orangeColor{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 182 152 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-slate-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.\!arh-bg-transparent{
  background-color: transparent !important;
}

.\!arh-bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.arh-bg-\[\#138EBA\]{
  --tw-bg-opacity: 1;
  background-color: rgb(19 142 186 / var(--tw-bg-opacity));
}

.arh-bg-\[\#191B1C\]{
  --tw-bg-opacity: 1;
  background-color: rgb(25 27 28 / var(--tw-bg-opacity));
}

.arh-bg-\[\#1A4E63\]{
  --tw-bg-opacity: 1;
  background-color: rgb(26 78 99 / var(--tw-bg-opacity));
}

.arh-bg-\[\#1a4e63\]{
  --tw-bg-opacity: 1;
  background-color: rgb(26 78 99 / var(--tw-bg-opacity));
}

.arh-bg-\[\#265985\]{
  --tw-bg-opacity: 1;
  background-color: rgb(38 89 133 / var(--tw-bg-opacity));
}

.arh-bg-\[\#2C2C31\]{
  --tw-bg-opacity: 1;
  background-color: rgb(44 44 49 / var(--tw-bg-opacity));
}

.arh-bg-\[\#2daa7f\]{
  --tw-bg-opacity: 1;
  background-color: rgb(45 170 127 / var(--tw-bg-opacity));
}

.arh-bg-\[\#323639\]{
  --tw-bg-opacity: 1;
  background-color: rgb(50 54 57 / var(--tw-bg-opacity));
}

.arh-bg-\[\#37a1eb\]{
  --tw-bg-opacity: 1;
  background-color: rgb(55 161 235 / var(--tw-bg-opacity));
}

.arh-bg-\[\#3CBCA3\]{
  --tw-bg-opacity: 1;
  background-color: rgb(60 188 163 / var(--tw-bg-opacity));
}

.arh-bg-\[\#425f7a\]{
  --tw-bg-opacity: 1;
  background-color: rgb(66 95 122 / var(--tw-bg-opacity));
}

.arh-bg-\[\#444\]\/50{
  background-color: rgb(68 68 68 / 0.5);
}

.arh-bg-\[\#4472A3\]{
  --tw-bg-opacity: 1;
  background-color: rgb(68 114 163 / var(--tw-bg-opacity));
}

.arh-bg-\[\#49d4ab\]{
  --tw-bg-opacity: 1;
  background-color: rgb(73 212 171 / var(--tw-bg-opacity));
}

.arh-bg-\[\#5E5F5F\]{
  --tw-bg-opacity: 1;
  background-color: rgb(94 95 95 / var(--tw-bg-opacity));
}

.arh-bg-\[\#609cd3\]{
  --tw-bg-opacity: 1;
  background-color: rgb(96 156 211 / var(--tw-bg-opacity));
}

.arh-bg-\[\#665c80\]{
  --tw-bg-opacity: 1;
  background-color: rgb(102 92 128 / var(--tw-bg-opacity));
}

.arh-bg-\[\#66C109\]{
  --tw-bg-opacity: 1;
  background-color: rgb(102 193 9 / var(--tw-bg-opacity));
}

.arh-bg-\[\#73C7B7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.arh-bg-\[\#73C7B7\]\/20{
  background-color: rgb(115 199 183 / 0.2);
}

.arh-bg-\[\#73C7B7\]\/30{
  background-color: rgb(115 199 183 / 0.3);
}

.arh-bg-\[\#73C7B7\]\/\[0\.4\]{
  background-color: rgb(115 199 183 / 0.4);
}

.arh-bg-\[\#92E890\]{
  --tw-bg-opacity: 1;
  background-color: rgb(146 232 144 / var(--tw-bg-opacity));
}

.arh-bg-\[\#92e98f\]{
  --tw-bg-opacity: 1;
  background-color: rgb(146 233 143 / var(--tw-bg-opacity));
}

.arh-bg-\[\#95C5E7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(149 197 231 / var(--tw-bg-opacity));
}

.arh-bg-\[\#9686c1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(150 134 193 / var(--tw-bg-opacity));
}

.arh-bg-\[\#99d0f6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(153 208 246 / var(--tw-bg-opacity));
}

.arh-bg-\[\#9E77ED\]{
  --tw-bg-opacity: 1;
  background-color: rgb(158 119 237 / var(--tw-bg-opacity));
}

.arh-bg-\[\#9ad0f6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(154 208 246 / var(--tw-bg-opacity));
}

.arh-bg-\[\#A273C7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(162 115 199 / var(--tw-bg-opacity));
}

.arh-bg-\[\#A7EBDE\]{
  --tw-bg-opacity: 1;
  background-color: rgb(167 235 222 / var(--tw-bg-opacity));
}

.arh-bg-\[\#B0BEC5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(176 190 197 / var(--tw-bg-opacity));
}

.arh-bg-\[\#B6A3E9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(182 163 233 / var(--tw-bg-opacity));
}

.arh-bg-\[\#C24E27\]{
  --tw-bg-opacity: 1;
  background-color: rgb(194 78 39 / var(--tw-bg-opacity));
}

.arh-bg-\[\#C4C4C4\]{
  --tw-bg-opacity: 1;
  background-color: rgb(196 196 196 / var(--tw-bg-opacity));
}

.arh-bg-\[\#CAEBCD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(202 235 205 / var(--tw-bg-opacity));
}

.arh-bg-\[\#CEECA8\]{
  --tw-bg-opacity: 1;
  background-color: rgb(206 236 168 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D1D7D5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(209 215 213 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D2CDF0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(210 205 240 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D2DDE1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(210 221 225 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D3E3F1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(211 227 241 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D3F0C9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(211 240 201 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D6F0CD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(214 240 205 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D8E2F5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(216 226 245 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D8F5EB\]{
  --tw-bg-opacity: 1;
  background-color: rgb(216 245 235 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D8F5FB\]{
  --tw-bg-opacity: 1;
  background-color: rgb(216 245 251 / var(--tw-bg-opacity));
}

.arh-bg-\[\#D9D9D9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E2F1FE\]{
  --tw-bg-opacity: 1;
  background-color: rgb(226 241 254 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E5ECF6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(229 236 246 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E6EBF8\]{
  --tw-bg-opacity: 1;
  background-color: rgb(230 235 248 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E6F8EF\]{
  --tw-bg-opacity: 1;
  background-color: rgb(230 248 239 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E7F8F3\]{
  --tw-bg-opacity: 1;
  background-color: rgb(231 248 243 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E7FDE6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(231 253 230 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E8E1FB\]{
  --tw-bg-opacity: 1;
  background-color: rgb(232 225 251 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E9ECF1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 241 / var(--tw-bg-opacity));
}

.arh-bg-\[\#EACDCE\]{
  --tw-bg-opacity: 1;
  background-color: rgb(234 205 206 / var(--tw-bg-opacity));
}

.arh-bg-\[\#EBEEF4\]{
  --tw-bg-opacity: 1;
  background-color: rgb(235 238 244 / var(--tw-bg-opacity));
}

.arh-bg-\[\#ECECEC\]{
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 236 / var(--tw-bg-opacity));
}

.arh-bg-\[\#ECEDEF\]{
  --tw-bg-opacity: 1;
  background-color: rgb(236 237 239 / var(--tw-bg-opacity));
}

.arh-bg-\[\#EFF0F2\]{
  --tw-bg-opacity: 1;
  background-color: rgb(239 240 242 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F0F0F0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F0F1F2\]{
  --tw-bg-opacity: 1;
  background-color: rgb(240 241 242 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F1F1F1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F1F4FA\]{
  --tw-bg-opacity: 1;
  background-color: rgb(241 244 250 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F29D0E\]{
  --tw-bg-opacity: 1;
  background-color: rgb(242 157 14 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F2F4F7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(242 244 247 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F36242\]\/\[0\.2\]{
  background-color: rgb(243 98 66 / 0.2);
}

.arh-bg-\[\#F3F6FC\]{
  --tw-bg-opacity: 1;
  background-color: rgb(243 246 252 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F4F4F4\]{
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F4FBFA\]{
  --tw-bg-opacity: 1;
  background-color: rgb(244 251 250 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F5D954\]\/\[0\.4\]{
  background-color: rgb(245 217 84 / 0.4);
}

.arh-bg-\[\#F5F4F4\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 244 244 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F5F5F5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F5FFFA\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 255 250 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F60A20\]{
  --tw-bg-opacity: 1;
  background-color: rgb(246 10 32 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F63D68\]{
  --tw-bg-opacity: 1;
  background-color: rgb(246 61 104 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F8EAE4\]{
  --tw-bg-opacity: 1;
  background-color: rgb(248 234 228 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F8F1B6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(248 241 182 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F8F2BD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(248 242 189 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F8F8F8\]{
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F9E6DD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(249 230 221 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F9F9F9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F9FAFB\]{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F9FAFC\]{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FA966B\]{
  --tw-bg-opacity: 1;
  background-color: rgb(250 150 107 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FAD860\]{
  --tw-bg-opacity: 1;
  background-color: rgb(250 216 96 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FAEFDC\]{
  --tw-bg-opacity: 1;
  background-color: rgb(250 239 220 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FBE5E9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(251 229 233 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FBEED6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(251 238 214 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FCB698\]{
  --tw-bg-opacity: 1;
  background-color: rgb(252 182 152 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FCFCFC\]{
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 252 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FD7D54\]{
  --tw-bg-opacity: 1;
  background-color: rgb(253 125 84 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FDD9D9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(253 217 217 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FDF6F1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 241 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FDFDFD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 253 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FF0000\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FF6B6B\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 107 107 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFB1C1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 177 193 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFD4E0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 212 224 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFD8C7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 199 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFDF6B\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 223 107 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFEFE8\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 232 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFEFE9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 233 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFF1F3\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 243 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFF3F4\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 243 244 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFF4F0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 244 240 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFF7F3\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 243 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFFDF0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 253 240 / var(--tw-bg-opacity));
}

.arh-bg-\[\#b0bec5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(176 190 197 / var(--tw-bg-opacity));
}

.arh-bg-\[\#bae7de\]{
  --tw-bg-opacity: 1;
  background-color: rgb(186 231 222 / var(--tw-bg-opacity));
}

.arh-bg-\[\#d8f5eb\]{
  --tw-bg-opacity: 1;
  background-color: rgb(216 245 235 / var(--tw-bg-opacity));
}

.arh-bg-\[\#dbd7e6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(219 215 230 / var(--tw-bg-opacity));
}

.arh-bg-\[\#df4d1f\]{
  --tw-bg-opacity: 1;
  background-color: rgb(223 77 31 / var(--tw-bg-opacity));
}

.arh-bg-\[\#e94916\]{
  --tw-bg-opacity: 1;
  background-color: rgb(233 73 22 / var(--tw-bg-opacity));
}

.arh-bg-\[\#e9ecef\]{
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity));
}

.arh-bg-\[\#efeff0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 240 / var(--tw-bg-opacity));
}

.arh-bg-\[\#f29d0e\]{
  --tw-bg-opacity: 1;
  background-color: rgb(242 157 14 / var(--tw-bg-opacity));
}

.arh-bg-\[\#f36242\]{
  --tw-bg-opacity: 1;
  background-color: rgb(243 98 66 / var(--tw-bg-opacity));
}

.arh-bg-\[\#f5d04b\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 75 / var(--tw-bg-opacity));
}

.arh-bg-\[\#f5d954\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 217 84 / var(--tw-bg-opacity));
}

.arh-bg-\[\#f67c53\]{
  --tw-bg-opacity: 1;
  background-color: rgb(246 124 83 / var(--tw-bg-opacity));
}

.arh-bg-\[\#f6f6f6\]{
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.arh-bg-\[\#f9fafc\]{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}

.arh-bg-\[\#fafafa\]{
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.arh-bg-\[\#fcfcfc\]{
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 252 / var(--tw-bg-opacity));
}

.arh-bg-\[\#fdb0c0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(253 176 192 / var(--tw-bg-opacity));
}

.arh-bg-\[\#fdf6f1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 241 / var(--tw-bg-opacity));
}

.arh-bg-\[\#fe6383\]{
  --tw-bg-opacity: 1;
  background-color: rgb(254 99 131 / var(--tw-bg-opacity));
}

.arh-bg-\[\#ffb1c1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 177 193 / var(--tw-bg-opacity));
}

.arh-bg-\[\#ffe7ab\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 231 171 / var(--tw-bg-opacity));
}

.arh-bg-\[\#ffefe8\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 232 / var(--tw-bg-opacity));
}

.arh-bg-\[\#fff\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.arh-bg-\[\#ffffff\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.arh-bg-bg-jobFree{
  --tw-bg-opacity: 1;
  background-color: rgb(244 251 250 / var(--tw-bg-opacity));
}

.arh-bg-bg-jobPayant{
  --tw-bg-opacity: 1;
  background-color: rgb(246 244 251 / var(--tw-bg-opacity));
}

.arh-bg-bg-jobProgress{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

.arh-bg-black{
  --tw-bg-opacity: 1;
  background-color: rgb(41 41 41 / var(--tw-bg-opacity));
}

.arh-bg-black\/80{
  background-color: rgb(41 41 41 / 0.8);
}

.arh-bg-blue-500{
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.arh-bg-blueColor{
  --tw-bg-opacity: 1;
  background-color: rgb(26 78 99 / var(--tw-bg-opacity));
}

.arh-bg-btnGreen{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.arh-bg-btnTableBgGrey{
  --tw-bg-opacity: 1;
  background-color: rgb(235 238 244 / var(--tw-bg-opacity));
}

.arh-bg-cardGreen{
  --tw-bg-opacity: 1;
  background-color: rgb(238 246 247 / var(--tw-bg-opacity));
}

.arh-bg-cyan-500{
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.arh-bg-dotGreen{
  --tw-bg-opacity: 1;
  background-color: rgb(106 232 171 / var(--tw-bg-opacity));
}

.arh-bg-dotRed{
  --tw-bg-opacity: 1;
  background-color: rgb(237 88 24 / var(--tw-bg-opacity));
}

.arh-bg-dotYellow{
  --tw-bg-opacity: 1;
  background-color: rgb(244 174 69 / var(--tw-bg-opacity));
}

.arh-bg-gray-100{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.arh-bg-gray-200{
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.arh-bg-gray-300{
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.arh-bg-gray-500{
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.arh-bg-gray-700{
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.arh-bg-green-200{
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.arh-bg-green-700{
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.arh-bg-greenColor{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.arh-bg-greenColor\/30{
  background-color: rgb(115 199 183 / 0.3);
}

.arh-bg-greyBorderInput{
  --tw-bg-opacity: 1;
  background-color: rgb(228 236 248 / var(--tw-bg-opacity));
}

.arh-bg-greyColor{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}

.arh-bg-greyColor\/80{
  background-color: rgb(249 250 252 / 0.8);
}

.arh-bg-greyDisabled{
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.arh-bg-greyInput{
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity));
}

.arh-bg-indigo{
  --tw-bg-opacity: 1;
  background-color: rgb(192 115 199 / var(--tw-bg-opacity));
}

.arh-bg-inherit{
  background-color: inherit;
}

.arh-bg-invalidBackgroundInput{
  --tw-bg-opacity: 1;
  background-color: rgb(255 243 244 / var(--tw-bg-opacity));
}

.arh-bg-lightOrange{
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 232 / var(--tw-bg-opacity));
}

.arh-bg-linkedinBlue{
  --tw-bg-opacity: 1;
  background-color: rgb(0 123 181 / var(--tw-bg-opacity));
}

.arh-bg-loaderBlue{
  --tw-bg-opacity: 1;
  background-color: rgb(68 114 163 / var(--tw-bg-opacity));
}

.arh-bg-loaderGreen{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.arh-bg-loaderOrange{
  --tw-bg-opacity: 1;
  background-color: rgb(250 150 107 / var(--tw-bg-opacity));
}

.arh-bg-modalBackdrop{
  --tw-bg-opacity: 1;
  background-color: rgb(83 93 95 / var(--tw-bg-opacity));
}

.arh-bg-orangeColor{
  --tw-bg-opacity: 1;
  background-color: rgb(252 182 152 / var(--tw-bg-opacity));
}

.arh-bg-red-500{
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.arh-bg-transparent{
  background-color: transparent;
}

.arh-bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.arh-bg-zinc-100{
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.arh-bg-\[\#EEF6F7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(238 246 247 / var(--tw-bg-opacity));
}

.arh-bg-\[\#F0F4FA\]{
  --tw-bg-opacity: 1;
  background-color: rgb(240 244 250 / var(--tw-bg-opacity));
}

.arh-bg-\[\#68C153\]{
  --tw-bg-opacity: 1;
  background-color: rgb(104 193 83 / var(--tw-bg-opacity));
}

.arh-bg-\[\#E4F8DC\]{
  --tw-bg-opacity: 1;
  background-color: rgb(228 248 220 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFE0DF\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 224 223 / var(--tw-bg-opacity));
}

.arh-bg-\[\#FFF5C2\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 245 194 / var(--tw-bg-opacity));
}

.arh-bg-\[\#750000\]{
  --tw-bg-opacity: 1;
  background-color: rgb(117 0 0 / var(--tw-bg-opacity));
}

.arh-bg-\[\#ffc9c9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 201 201 / var(--tw-bg-opacity));
}

.arh-bg-\[\#B494FD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(180 148 253 / var(--tw-bg-opacity));
}

.arh-bg-opacity-90{
  --tw-bg-opacity: 0.9;
}

.arh-fill-\[\#292929\]{
  fill: #292929;
}

.arh-fill-\[\#2E3A59\]{
  fill: #2E3A59;
}

.arh-fill-\[\#73C7B7\]{
  fill: #73C7B7;
}

.arh-fill-\[\#F55611\]{
  fill: #F55611;
}

.arh-stroke-greenColor{
  stroke: #73c7b7;
}

.arh-object-contain{
  object-fit: contain;
}

.arh-object-cover{
  object-fit: cover;
}

.\!arh-p-0{
  padding: 0px !important;
}

.\!arh-p-1{
  padding: 0.25rem !important;
}

.\!arh-p-3{
  padding: 0.75rem !important;
}

.\!arh-p-5{
  padding: 1.25rem !important;
}

.\!arh-p-\[0\.5rem\]{
  padding: 0.5rem !important;
}

.\!arh-p-\[1\.43rem\]{
  padding: 1.43rem !important;
}

.\!arh-p-px{
  padding: 1px !important;
}

.arh-p-0{
  padding: 0px;
}

.arh-p-0\.5{
  padding: 0.125rem;
}

.arh-p-1{
  padding: 0.25rem;
}

.arh-p-1\.5{
  padding: 0.375rem;
}

.arh-p-10{
  padding: 2.5rem;
}

.arh-p-14{
  padding: 3.5rem;
}

.arh-p-16{
  padding: 4rem;
}

.arh-p-18{
  padding: 1.125rem;
}

.arh-p-2{
  padding: 0.5rem;
}

.arh-p-2\.5{
  padding: 0.625rem;
}

.arh-p-20{
  padding: 5rem;
}

.arh-p-3{
  padding: 0.75rem;
}

.arh-p-3\.5{
  padding: 0.875rem;
}

.arh-p-4{
  padding: 1rem;
}

.arh-p-5{
  padding: 1.25rem;
}

.arh-p-6{
  padding: 1.5rem;
}

.arh-p-7{
  padding: 1.75rem;
}

.arh-p-8{
  padding: 2rem;
}

.arh-p-9{
  padding: 2.25rem;
}

.arh-p-\[0\.188rem\]{
  padding: 0.188rem;
}

.arh-p-\[0\.3rem\]{
  padding: 0.3rem;
}

.arh-p-\[0\.42rem\]{
  padding: 0.42rem;
}

.arh-p-\[0\.563rem\]{
  padding: 0.563rem;
}

.arh-p-\[0\.5rem\]{
  padding: 0.5rem;
}

.arh-p-\[0\.688rem\]{
  padding: 0.688rem;
}

.arh-p-\[1\.313rem\]{
  padding: 1.313rem;
}

.arh-p-\[1\.33rem\]{
  padding: 1.33rem;
}

.arh-p-\[1\.563rem\]{
  padding: 1.563rem;
}

.arh-p-\[10px\]{
  padding: 10px;
}

.arh-p-\[1rem\]{
  padding: 1rem;
}

.arh-p-\[26px\]{
  padding: 26px;
}

.arh-p-\[2px\]{
  padding: 2px;
}

.arh-p-\[3\%\]{
  padding: 3%;
}

.arh-p-\[3\.118rem\]{
  padding: 3.118rem;
}

.arh-p-\[63px\]{
  padding: 63px;
}

.arh-p-\[6px\]{
  padding: 6px;
}

.arh-p-px{
  padding: 1px;
}

.\!arh-px-0{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.\!arh-px-2{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.\!arh-px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.\!arh-px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.\!arh-px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.\!arh-px-\[10px\]{
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.\!arh-px-\[11px\]{
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.\!arh-py-0{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.\!arh-py-1{
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.\!arh-py-2{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.\!arh-py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.\!arh-py-\[0\.2rem\]{
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.\!arh-py-\[0\.75rem\]{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.\!arh-py-\[5px\]{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.\!arh-py-\[6px\]{
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.arh-px-0{
  padding-left: 0px;
  padding-right: 0px;
}

.arh-px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.arh-px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.arh-px-11{
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.arh-px-12{
  padding-left: 3rem;
  padding-right: 3rem;
}

.arh-px-14{
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.arh-px-16{
  padding-left: 4rem;
  padding-right: 4rem;
}

.arh-px-18{
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.arh-px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.arh-px-2\.5{
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.arh-px-24{
  padding-left: 6rem;
  padding-right: 6rem;
}

.arh-px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.arh-px-3\.5{
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.arh-px-30{
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.arh-px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}

.arh-px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.arh-px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.arh-px-7{
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.arh-px-8{
  padding-left: 2rem;
  padding-right: 2rem;
}

.arh-px-9{
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.arh-px-\[0\.588rem\]{
  padding-left: 0.588rem;
  padding-right: 0.588rem;
}

.arh-px-\[0\.5rem\]{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.arh-px-\[0\.625rem\]{
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.arh-px-\[0\.688rem\]{
  padding-left: 0.688rem;
  padding-right: 0.688rem;
}

.arh-px-\[0\.813rem\]{
  padding-left: 0.813rem;
  padding-right: 0.813rem;
}

.arh-px-\[0\.875rem\]{
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.arh-px-\[0\.8rem\]{
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.arh-px-\[0\.938rem\]{
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.arh-px-\[1\.125rem\]{
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.arh-px-\[1\.188rem\]{
  padding-left: 1.188rem;
  padding-right: 1.188rem;
}

.arh-px-\[1\.25rem\]{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.arh-px-\[1\.313rem\]{
  padding-left: 1.313rem;
  padding-right: 1.313rem;
}

.arh-px-\[1\.375rem\]{
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

.arh-px-\[1\.563rem\]{
  padding-left: 1.563rem;
  padding-right: 1.563rem;
}

.arh-px-\[1\.5rem\]{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.arh-px-\[1\.688rem\]{
  padding-left: 1.688rem;
  padding-right: 1.688rem;
}

.arh-px-\[1\.719rem\]{
  padding-left: 1.719rem;
  padding-right: 1.719rem;
}

.arh-px-\[1\.875rem\]{
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.arh-px-\[1\.938rem\]{
  padding-left: 1.938rem;
  padding-right: 1.938rem;
}

.arh-px-\[10px\]{
  padding-left: 10px;
  padding-right: 10px;
}

.arh-px-\[11px\]{
  padding-left: 11px;
  padding-right: 11px;
}

.arh-px-\[14px\]{
  padding-left: 14px;
  padding-right: 14px;
}

.arh-px-\[15\%\]{
  padding-left: 15%;
  padding-right: 15%;
}

.arh-px-\[15px\]{
  padding-left: 15px;
  padding-right: 15px;
}

.arh-px-\[17px\]{
  padding-left: 17px;
  padding-right: 17px;
}

.arh-px-\[19px\]{
  padding-left: 19px;
  padding-right: 19px;
}

.arh-px-\[1rem\]{
  padding-left: 1rem;
  padding-right: 1rem;
}

.arh-px-\[2\%\]{
  padding-left: 2%;
  padding-right: 2%;
}

.arh-px-\[2\.125rem\]{
  padding-left: 2.125rem;
  padding-right: 2.125rem;
}

.arh-px-\[2\.3rem\]{
  padding-left: 2.3rem;
  padding-right: 2.3rem;
}

.arh-px-\[2\.5rem\]{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.arh-px-\[2\.625rem\]{
  padding-left: 2.625rem;
  padding-right: 2.625rem;
}

.arh-px-\[20px\]{
  padding-left: 20px;
  padding-right: 20px;
}

.arh-px-\[21px\]{
  padding-left: 21px;
  padding-right: 21px;
}

.arh-px-\[25px\]{
  padding-left: 25px;
  padding-right: 25px;
}

.arh-px-\[26rem\]{
  padding-left: 26rem;
  padding-right: 26rem;
}

.arh-px-\[2rem\]{
  padding-left: 2rem;
  padding-right: 2rem;
}

.arh-px-\[3rem\]{
  padding-left: 3rem;
  padding-right: 3rem;
}

.arh-px-\[4\.7rem\]{
  padding-left: 4.7rem;
  padding-right: 4.7rem;
}

.arh-px-\[40px\]{
  padding-left: 40px;
  padding-right: 40px;
}

.arh-px-\[5\%\]{
  padding-left: 5%;
  padding-right: 5%;
}

.arh-px-\[5px\]{
  padding-left: 5px;
  padding-right: 5px;
}

.arh-px-\[9\.313rem\]{
  padding-left: 9.313rem;
  padding-right: 9.313rem;
}

.arh-py-0{
  padding-top: 0px;
  padding-bottom: 0px;
}

.arh-py-0\.5{
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.arh-py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.arh-py-1\.5{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.arh-py-10{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.arh-py-11{
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.arh-py-14{
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.arh-py-16{
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.arh-py-18{
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.arh-py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.arh-py-2\.5{
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.arh-py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.arh-py-3\.5{
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.arh-py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.arh-py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.arh-py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.arh-py-7{
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.arh-py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.arh-py-\[0\.094rem\]{
  padding-top: 0.094rem;
  padding-bottom: 0.094rem;
}

.arh-py-\[0\.2rem\]{
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.arh-py-\[0\.313rem\]{
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
}

.arh-py-\[0\.344rem\]{
  padding-top: 0.344rem;
  padding-bottom: 0.344rem;
}

.arh-py-\[0\.375rem\]{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.arh-py-\[0\.3rem\]{
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.arh-py-\[0\.438rem\]{
  padding-top: 0.438rem;
  padding-bottom: 0.438rem;
}

.arh-py-\[0\.563rem\]{
  padding-top: 0.563rem;
  padding-bottom: 0.563rem;
}

.arh-py-\[0\.5rem\]{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.arh-py-\[0\.625\]{
  padding-top: 0.625;
  padding-bottom: 0.625;
}

.arh-py-\[0\.625rem\]{
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.arh-py-\[0\.688rem\]{
  padding-top: 0.688rem;
  padding-bottom: 0.688rem;
}

.arh-py-\[0\.6rem\]{
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.arh-py-\[0\.719rem\]{
  padding-top: 0.719rem;
  padding-bottom: 0.719rem;
}

.arh-py-\[0\.75rem\]{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.arh-py-\[0\.813rem\]{
  padding-top: 0.813rem;
  padding-bottom: 0.813rem;
}

.arh-py-\[0\.8rem\]{
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.arh-py-\[0\.938rem\]{
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
}

.arh-py-\[1\.125rem\]{
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.arh-py-\[1\.188rem\]{
  padding-top: 1.188rem;
  padding-bottom: 1.188rem;
}

.arh-py-\[1\.344rem\]{
  padding-top: 1.344rem;
  padding-bottom: 1.344rem;
}

.arh-py-\[1\.5rem\]{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.arh-py-\[1\.7rem\]{
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
}

.arh-py-\[1\.875rem\]{
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.arh-py-\[10px\]{
  padding-top: 10px;
  padding-bottom: 10px;
}

.arh-py-\[13px\]{
  padding-top: 13px;
  padding-bottom: 13px;
}

.arh-py-\[14px\]{
  padding-top: 14px;
  padding-bottom: 14px;
}

.arh-py-\[1rem\]{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.arh-py-\[2\.125rem\]{
  padding-top: 2.125rem;
  padding-bottom: 2.125rem;
}

.arh-py-\[2\.5rem\]{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.arh-py-\[20px\]{
  padding-top: 20px;
  padding-bottom: 20px;
}

.arh-py-\[2rem\]{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.arh-py-\[3px\]{
  padding-top: 3px;
  padding-bottom: 3px;
}

.arh-py-\[5px\]{
  padding-top: 5px;
  padding-bottom: 5px;
}

.arh-py-\[6px\]{
  padding-top: 6px;
  padding-bottom: 6px;
}

.arh-py-\[7\.5px\]{
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.arh-py-px{
  padding-top: 1px;
  padding-bottom: 1px;
}

.\!arh-pl-9{
  padding-left: 2.25rem !important;
}

.\!arh-pl-\[0\.5rem\]{
  padding-left: 0.5rem !important;
}

.\!arh-pl-\[0\.7rem\]{
  padding-left: 0.7rem !important;
}

.\!arh-pl-\[0\.9rem\]{
  padding-left: 0.9rem !important;
}

.\!arh-pl-\[1\.25rem\]{
  padding-left: 1.25rem !important;
}

.\!arh-pl-\[1\.5rem\]{
  padding-left: 1.5rem !important;
}

.\!arh-pl-\[1rem\]{
  padding-left: 1rem !important;
}

.\!arh-pl-\[2rem\]{
  padding-left: 2rem !important;
}

.\!arh-pr-0{
  padding-right: 0px !important;
}

.\!arh-pr-8{
  padding-right: 2rem !important;
}

.\!arh-pt-0{
  padding-top: 0px !important;
}

.arh-pb-0{
  padding-bottom: 0px;
}

.arh-pb-10{
  padding-bottom: 2.5rem;
}

.arh-pb-16{
  padding-bottom: 4rem;
}

.arh-pb-2{
  padding-bottom: 0.5rem;
}

.arh-pb-3{
  padding-bottom: 0.75rem;
}

.arh-pb-4{
  padding-bottom: 1rem;
}

.arh-pb-5{
  padding-bottom: 1.25rem;
}

.arh-pb-6{
  padding-bottom: 1.5rem;
}

.arh-pb-7{
  padding-bottom: 1.75rem;
}

.arh-pb-8{
  padding-bottom: 2rem;
}

.arh-pb-\[0\.5rem\]{
  padding-bottom: 0.5rem;
}

.arh-pb-\[0\.695rem\]{
  padding-bottom: 0.695rem;
}

.arh-pb-\[1\.063rem\]{
  padding-bottom: 1.063rem;
}

.arh-pb-\[1rem\]{
  padding-bottom: 1rem;
}

.arh-pb-\[2\.063rem\]{
  padding-bottom: 2.063rem;
}

.arh-pb-\[2\.625rem\]{
  padding-bottom: 2.625rem;
}

.arh-pb-\[2\.875rem\]{
  padding-bottom: 2.875rem;
}

.arh-pb-\[20px\]{
  padding-bottom: 20px;
}

.arh-pb-\[2rem\]{
  padding-bottom: 2rem;
}

.arh-pb-\[50px\]{
  padding-bottom: 50px;
}

.arh-pl-0{
  padding-left: 0px;
}

.arh-pl-1\.5{
  padding-left: 0.375rem;
}

.arh-pl-10{
  padding-left: 2.5rem;
}

.arh-pl-11{
  padding-left: 2.75rem;
}

.arh-pl-12{
  padding-left: 3rem;
}

.arh-pl-16{
  padding-left: 4rem;
}

.arh-pl-18{
  padding-left: 1.125rem;
}

.arh-pl-2{
  padding-left: 0.5rem;
}

.arh-pl-2\.5{
  padding-left: 0.625rem;
}

.arh-pl-20{
  padding-left: 5rem;
}

.arh-pl-24{
  padding-left: 6rem;
}

.arh-pl-3{
  padding-left: 0.75rem;
}

.arh-pl-3\.5{
  padding-left: 0.875rem;
}

.arh-pl-4{
  padding-left: 1rem;
}

.arh-pl-5{
  padding-left: 1.25rem;
}

.arh-pl-6{
  padding-left: 1.5rem;
}

.arh-pl-7{
  padding-left: 1.75rem;
}

.arh-pl-8{
  padding-left: 2rem;
}

.arh-pl-9{
  padding-left: 2.25rem;
}

.arh-pl-\[1\.438rem\]{
  padding-left: 1.438rem;
}

.arh-pl-\[1\.5rem\]{
  padding-left: 1.5rem;
}

.arh-pl-\[10px\]{
  padding-left: 10px;
}

.arh-pl-\[1rem\]{
  padding-left: 1rem;
}

.arh-pl-\[2\.091rem\]{
  padding-left: 2.091rem;
}

.arh-pl-\[2\.938rem\]{
  padding-left: 2.938rem;
}

.arh-pl-\[2rem\]{
  padding-left: 2rem;
}

.arh-pl-\[30px\]{
  padding-left: 30px;
}

.arh-pl-\[3rem\]{
  padding-left: 3rem;
}

.arh-pl-\[4\.597rem\]{
  padding-left: 4.597rem;
}

.arh-pl-\[44px\]{
  padding-left: 44px;
}

.arh-pl-\[5\.4rem\]{
  padding-left: 5.4rem;
}

.arh-pl-\[5\.75rem\]{
  padding-left: 5.75rem;
}

.arh-pl-\[5\.8rem\]{
  padding-left: 5.8rem;
}

.arh-pl-\[50px\]{
  padding-left: 50px;
}

.arh-pl-\[91px\]{
  padding-left: 91px;
}

.arh-pr-1{
  padding-right: 0.25rem;
}

.arh-pr-10{
  padding-right: 2.5rem;
}

.arh-pr-12{
  padding-right: 3rem;
}

.arh-pr-14{
  padding-right: 3.5rem;
}

.arh-pr-16{
  padding-right: 4rem;
}

.arh-pr-18{
  padding-right: 1.125rem;
}

.arh-pr-2{
  padding-right: 0.5rem;
}

.arh-pr-2\.5{
  padding-right: 0.625rem;
}

.arh-pr-24{
  padding-right: 6rem;
}

.arh-pr-28{
  padding-right: 7rem;
}

.arh-pr-3{
  padding-right: 0.75rem;
}

.arh-pr-4{
  padding-right: 1rem;
}

.arh-pr-6{
  padding-right: 1.5rem;
}

.arh-pr-7{
  padding-right: 1.75rem;
}

.arh-pr-8{
  padding-right: 2rem;
}

.arh-pr-\[0\.563rem\]{
  padding-right: 0.563rem;
}

.arh-pr-\[0\.5rem\]{
  padding-right: 0.5rem;
}

.arh-pr-\[1\.438rem\]{
  padding-right: 1.438rem;
}

.arh-pr-\[1\.75rem\]{
  padding-right: 1.75rem;
}

.arh-pr-\[10px\]{
  padding-right: 10px;
}

.arh-pr-\[1rem\]{
  padding-right: 1rem;
}

.arh-pr-\[2\.938rem\]{
  padding-right: 2.938rem;
}

.arh-pr-\[2rem\]{
  padding-right: 2rem;
}

.arh-pr-\[50px\]{
  padding-right: 50px;
}

.arh-pr-\[57px\]{
  padding-right: 57px;
}

.arh-pr-\[60px\]{
  padding-right: 60px;
}

.arh-pr-\[92px\]{
  padding-right: 92px;
}

.arh-pr-\[9px\]{
  padding-right: 9px;
}

.arh-pt-0{
  padding-top: 0px;
}

.arh-pt-1{
  padding-top: 0.25rem;
}

.arh-pt-2{
  padding-top: 0.5rem;
}

.arh-pt-3{
  padding-top: 0.75rem;
}

.arh-pt-4{
  padding-top: 1rem;
}

.arh-pt-6{
  padding-top: 1.5rem;
}

.arh-pt-8{
  padding-top: 2rem;
}

.arh-pt-\[0\.5rem\]{
  padding-top: 0.5rem;
}

.arh-pt-\[0\.775rem\]{
  padding-top: 0.775rem;
}

.arh-pt-\[1\.125rem\]{
  padding-top: 1.125rem;
}

.arh-pt-\[1\.875rem\]{
  padding-top: 1.875rem;
}

.arh-pt-\[1rem\]{
  padding-top: 1rem;
}

.arh-pt-\[2\.188rem\]{
  padding-top: 2.188rem;
}

.arh-pt-\[2\.5rem\]{
  padding-top: 2.5rem;
}

.arh-pt-\[25px\]{
  padding-top: 25px;
}

.arh-pt-\[30px\]{
  padding-top: 30px;
}

.arh-pt-\[4\.188rem\]{
  padding-top: 4.188rem;
}

.arh-pt-\[4\.597rem\]{
  padding-top: 4.597rem;
}

.arh-pt-\[4px\]{
  padding-top: 4px;
}

.\!arh-pl-0{
  padding-left: 0px !important;
}

.\!arh-pl-12{
  padding-left: 3rem !important;
}

.\!arh-text-left{
  text-align: left !important;
}

.arh-text-left{
  text-align: left;
}

.arh-text-center{
  text-align: center;
}

.arh-text-right{
  text-align: right;
}

.arh-text-justify{
  text-align: justify;
}

.arh-text-start{
  text-align: start;
}

.arh-text-end{
  text-align: end;
}

.arh-align-top{
  vertical-align: top;
}

.arh-align-middle{
  vertical-align: middle;
}

.\!arh-font-poppins{
  font-family: Poppins !important;
}

.arh-font-\[\'Poppins\\\\_Medium\'\]{
  font-family: 'Poppins\_Medium';
}

.arh-font-bebas{
  font-family: Bebas Neue;
}

.arh-font-poppins{
  font-family: Poppins;
}

.\!arh-text-2xs{
  font-size: 0.625rem !important;
}

.\!arh-text-\[0\.813rem\]{
  font-size: 0.813rem !important;
}

.\!arh-text-\[0\.9rem\]{
  font-size: 0.9rem !important;
}

.\!arh-text-\[10px\]{
  font-size: 10px !important;
}

.\!arh-text-\[13px\]{
  font-size: 13px !important;
}

.\!arh-text-\[17px\]{
  font-size: 17px !important;
}

.\!arh-text-base{
  font-size: 1rem !important;
}

.\!arh-text-xs{
  font-size: .75rem !important;
}

.arh-text-15{
  font-size: 0.938rem;
}

.arh-text-2xl{
  font-size: 1.5rem;
}

.arh-text-2xs{
  font-size: 0.625rem;
}

.arh-text-3xl{
  font-size: 1.875rem;
}

.arh-text-4xl{
  font-size: 2.25rem;
}

.arh-text-6xl{
  font-size: 4rem;
}

.arh-text-\[\.8125rem\]{
  font-size: .8125rem;
}

.arh-text-\[\.813rem\]{
  font-size: .813rem;
}

.arh-text-\[0\.688rem\]{
  font-size: 0.688rem;
}

.arh-text-\[0\.75rem\]{
  font-size: 0.75rem;
}

.arh-text-\[0\.8125rem\]{
  font-size: 0.8125rem;
}

.arh-text-\[0\.813rem\]{
  font-size: 0.813rem;
}

.arh-text-\[0\.875rem\]{
  font-size: 0.875rem;
}

.arh-text-\[0\.938rem\]{
  font-size: 0.938rem;
}

.arh-text-\[1\.15rem\]{
  font-size: 1.15rem;
}

.arh-text-\[1\.2rem\]{
  font-size: 1.2rem;
}

.arh-text-\[1\.5rem\]{
  font-size: 1.5rem;
}

.arh-text-\[10px\]{
  font-size: 10px;
}

.arh-text-\[11px\]{
  font-size: 11px;
}

.arh-text-\[12px\]{
  font-size: 12px;
}

.arh-text-\[13px\]{
  font-size: 13px;
}

.arh-text-\[14px\]{
  font-size: 14px;
}

.arh-text-\[150px\]{
  font-size: 150px;
}

.arh-text-\[15px\]{
  font-size: 15px;
}

.arh-text-\[16px\]{
  font-size: 16px;
}

.arh-text-\[17px\]{
  font-size: 17px;
}

.arh-text-\[1rem\]{
  font-size: 1rem;
}

.arh-text-\[2\.5rem\]{
  font-size: 2.5rem;
}

.arh-text-\[21px\]{
  font-size: 21px;
}

.arh-text-\[22px\]{
  font-size: 22px;
}

.arh-text-\[23px\]{
  font-size: 23px;
}

.arh-text-\[25px\]{
  font-size: 25px;
}

.arh-text-\[26px\]{
  font-size: 26px;
}

.arh-text-\[27px\]{
  font-size: 27px;
}

.arh-text-\[28px\]{
  font-size: 28px;
}

.arh-text-\[3\.75rem\]{
  font-size: 3.75rem;
}

.arh-text-\[30px\]{
  font-size: 30px;
}

.arh-text-\[35px\]{
  font-size: 35px;
}

.arh-text-\[40px\]{
  font-size: 40px;
}

.arh-text-base{
  font-size: 1rem;
}

.arh-text-fontBodyTable{
  font-size: 0.688rem;
}

.arh-text-lg{
  font-size: 1.125rem;
}

.arh-text-sm{
  font-size: .875rem;
}

.arh-text-tiny{
  font-size: .875rem;
}

.arh-text-xl{
  font-size: 1.25rem;
}

.arh-text-xs{
  font-size: .75rem;
}

.\!arh-font-medium{
  font-weight: 500 !important;
}

.\!arh-font-normal{
  font-weight: 400 !important;
}

.\!arh-font-semibold{
  font-weight: 600 !important;
}

.arh-font-\[300\]{
  font-weight: 300;
}

.arh-font-black{
  font-weight: 900;
}

.arh-font-bold{
  font-weight: 700;
}

.arh-font-light{
  font-weight: 300;
}

.arh-font-medium{
  font-weight: 500;
}

.arh-font-normal{
  font-weight: 400;
}

.arh-font-semibold{
  font-weight: 600;
}

.arh-uppercase{
  text-transform: uppercase;
}

.arh-lowercase{
  text-transform: lowercase;
}

.arh-capitalize{
  text-transform: capitalize;
}

.\!arh-normal-case{
  text-transform: none !important;
}

.arh-normal-case{
  text-transform: none;
}

.arh-italic{
  font-style: italic;
}

.arh-not-italic{
  font-style: normal;
}

.arh-leading-4{
  line-height: 1rem;
}

.arh-leading-5{
  line-height: 1.25rem;
}

.arh-leading-6{
  line-height: 1.5rem;
}

.arh-leading-7{
  line-height: 1.75rem;
}

.arh-leading-\[1\.313rem\]{
  line-height: 1.313rem;
}

.arh-leading-\[1\.4\]{
  line-height: 1.4;
}

.arh-leading-\[1\.563rem\]{
  line-height: 1.563rem;
}

.arh-leading-\[18px\]{
  line-height: 18px;
}

.arh-leading-\[21px\]{
  line-height: 21px;
}

.arh-leading-\[23px\]{
  line-height: 23px;
}

.arh-leading-\[24px\]{
  line-height: 24px;
}

.arh-leading-\[28px\]{
  line-height: 28px;
}

.arh-leading-\[3\.063rem\]{
  line-height: 3.063rem;
}

.arh-leading-\[30px\]{
  line-height: 30px;
}

.arh-leading-\[34px\]{
  line-height: 34px;
}

.arh-leading-\[69px\]{
  line-height: 69px;
}

.arh-leading-normal{
  line-height: 1.5;
}

.\!arh-tracking-normal{
  letter-spacing: 0em !important;
}

.-arh-tracking-\[0\.01rem\]{
  letter-spacing: -0.01rem;
}

.arh-tracking-\[0\.0075em\]{
  letter-spacing: 0.0075em;
}

.arh-tracking-\[0\.01em\]{
  letter-spacing: 0.01em;
}

.arh-tracking-\[2\%\]{
  letter-spacing: 2%;
}

.arh-tracking-normal{
  letter-spacing: 0em;
}

.arh-tracking-tighter{
  letter-spacing: -0.05em;
}

.arh-tracking-wide{
  letter-spacing: 0.025em;
}

.arh-tracking-wider{
  letter-spacing: 0.05em;
}

.\!arh-text-\[\#1A4E63\]{
  --tw-text-opacity: 1 !important;
  color: rgb(26 78 99 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#265985\]{
  --tw-text-opacity: 1 !important;
  color: rgb(38 89 133 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#2D3748\]{
  --tw-text-opacity: 1 !important;
  color: rgb(45 55 72 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#497163\]{
  --tw-text-opacity: 1 !important;
  color: rgb(73 113 99 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#56AA9A\]{
  --tw-text-opacity: 1 !important;
  color: rgb(86 170 154 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#5a5a5a\]{
  --tw-text-opacity: 1 !important;
  color: rgb(90 90 90 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#665c80\]{
  --tw-text-opacity: 1 !important;
  color: rgb(102 92 128 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#7A858A\]{
  --tw-text-opacity: 1 !important;
  color: rgb(122 133 138 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#7a858a\]{
  --tw-text-opacity: 1 !important;
  color: rgb(122 133 138 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#9D9D9D\]{
  --tw-text-opacity: 1 !important;
  color: rgb(157 157 157 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#A0AEC0\]{
  --tw-text-opacity: 1 !important;
  color: rgb(160 174 192 / var(--tw-text-opacity)) !important;
}

.\!arh-text-\[\#F60A20\]{
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.\!arh-text-black{
  --tw-text-opacity: 1 !important;
  color: rgb(41 41 41 / var(--tw-text-opacity)) !important;
}

.\!arh-text-darkColor{
  --tw-text-opacity: 1 !important;
  color: rgb(41 41 41 / var(--tw-text-opacity)) !important;
}

.\!arh-text-darkGreyColor{
  --tw-text-opacity: 1 !important;
  color: rgb(57 60 64 / var(--tw-text-opacity)) !important;
}

.\!arh-text-darkOrange{
  --tw-text-opacity: 1 !important;
  color: rgb(245 86 17 / var(--tw-text-opacity)) !important;
}

.\!arh-text-invalidColorTextInput{
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.\!arh-text-white{
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.arh-text-\[\#000000\]{
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.arh-text-\[\#027A48\]{
  --tw-text-opacity: 1;
  color: rgb(2 122 72 / var(--tw-text-opacity));
}

.arh-text-\[\#101828\]{
  --tw-text-opacity: 1;
  color: rgb(16 24 40 / var(--tw-text-opacity));
}

.arh-text-\[\#1170FF\]{
  --tw-text-opacity: 1;
  color: rgb(17 112 255 / var(--tw-text-opacity));
}

.arh-text-\[\#1A4E63\]{
  --tw-text-opacity: 1;
  color: rgb(26 78 99 / var(--tw-text-opacity));
}

.arh-text-\[\#203D48\]{
  --tw-text-opacity: 1;
  color: rgb(32 61 72 / var(--tw-text-opacity));
}

.arh-text-\[\#25BDA0\]{
  --tw-text-opacity: 1;
  color: rgb(37 189 160 / var(--tw-text-opacity));
}

.arh-text-\[\#265985\]{
  --tw-text-opacity: 1;
  color: rgb(38 89 133 / var(--tw-text-opacity));
}

.arh-text-\[\#292929\]{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.arh-text-\[\#295143\]{
  --tw-text-opacity: 1;
  color: rgb(41 81 67 / var(--tw-text-opacity));
}

.arh-text-\[\#2C2C31\]{
  --tw-text-opacity: 1;
  color: rgb(44 44 49 / var(--tw-text-opacity));
}

.arh-text-\[\#2D3748\]{
  --tw-text-opacity: 1;
  color: rgb(45 55 72 / var(--tw-text-opacity));
}

.arh-text-\[\#2E445F\]{
  --tw-text-opacity: 1;
  color: rgb(46 68 95 / var(--tw-text-opacity));
}

.arh-text-\[\#2c2323\]{
  --tw-text-opacity: 1;
  color: rgb(44 35 35 / var(--tw-text-opacity));
}

.arh-text-\[\#344054\]{
  --tw-text-opacity: 1;
  color: rgb(52 64 84 / var(--tw-text-opacity));
}

.arh-text-\[\#349280\]{
  --tw-text-opacity: 1;
  color: rgb(52 146 128 / var(--tw-text-opacity));
}

.arh-text-\[\#357569\]{
  --tw-text-opacity: 1;
  color: rgb(53 117 105 / var(--tw-text-opacity));
}

.arh-text-\[\#37474F\]{
  --tw-text-opacity: 1;
  color: rgb(55 71 79 / var(--tw-text-opacity));
}

.arh-text-\[\#388879\]{
  --tw-text-opacity: 1;
  color: rgb(56 136 121 / var(--tw-text-opacity));
}

.arh-text-\[\#393C40\]{
  --tw-text-opacity: 1;
  color: rgb(57 60 64 / var(--tw-text-opacity));
}

.arh-text-\[\#3A3A3A\]{
  --tw-text-opacity: 1;
  color: rgb(58 58 58 / var(--tw-text-opacity));
}

.arh-text-\[\#3C3C4399\]{
  color: #3C3C4399;
}

.arh-text-\[\#3D4B5B\]{
  --tw-text-opacity: 1;
  color: rgb(61 75 91 / var(--tw-text-opacity));
}

.arh-text-\[\#3D6C34\]{
  --tw-text-opacity: 1;
  color: rgb(61 108 52 / var(--tw-text-opacity));
}

.arh-text-\[\#3E7A9A\]{
  --tw-text-opacity: 1;
  color: rgb(62 122 154 / var(--tw-text-opacity));
}

.arh-text-\[\#3b4d56\]{
  --tw-text-opacity: 1;
  color: rgb(59 77 86 / var(--tw-text-opacity));
}

.arh-text-\[\#425f7a\]{
  --tw-text-opacity: 1;
  color: rgb(66 95 122 / var(--tw-text-opacity));
}

.arh-text-\[\#444444\]{
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}

.arh-text-\[\#444444\]\/40{
  color: rgb(68 68 68 / 0.4);
}

.arh-text-\[\#44796F\]{
  --tw-text-opacity: 1;
  color: rgb(68 121 111 / var(--tw-text-opacity));
}

.arh-text-\[\#468579\]{
  --tw-text-opacity: 1;
  color: rgb(70 133 121 / var(--tw-text-opacity));
}

.arh-text-\[\#474D55\]{
  --tw-text-opacity: 1;
  color: rgb(71 77 85 / var(--tw-text-opacity));
}

.arh-text-\[\#488b7e\]{
  --tw-text-opacity: 1;
  color: rgb(72 139 126 / var(--tw-text-opacity));
}

.arh-text-\[\#48B19D\]{
  --tw-text-opacity: 1;
  color: rgb(72 177 157 / var(--tw-text-opacity));
}

.arh-text-\[\#497163\]{
  --tw-text-opacity: 1;
  color: rgb(73 113 99 / var(--tw-text-opacity));
}

.arh-text-\[\#4CA090\]{
  --tw-text-opacity: 1;
  color: rgb(76 160 144 / var(--tw-text-opacity));
}

.arh-text-\[\#4F9A36\]{
  --tw-text-opacity: 1;
  color: rgb(79 154 54 / var(--tw-text-opacity));
}

.arh-text-\[\#525451\]{
  --tw-text-opacity: 1;
  color: rgb(82 84 81 / var(--tw-text-opacity));
}

.arh-text-\[\#53626a\]{
  --tw-text-opacity: 1;
  color: rgb(83 98 106 / var(--tw-text-opacity));
}

.arh-text-\[\#5A5A5A\]{
  --tw-text-opacity: 1;
  color: rgb(90 90 90 / var(--tw-text-opacity));
}

.arh-text-\[\#5A5E62\]{
  --tw-text-opacity: 1;
  color: rgb(90 94 98 / var(--tw-text-opacity));
}

.arh-text-\[\#5B8FDE\]{
  --tw-text-opacity: 1;
  color: rgb(91 143 222 / var(--tw-text-opacity));
}

.arh-text-\[\#5CAA76\]{
  --tw-text-opacity: 1;
  color: rgb(92 170 118 / var(--tw-text-opacity));
}

.arh-text-\[\#5E5E5E\]{
  --tw-text-opacity: 1;
  color: rgb(94 94 94 / var(--tw-text-opacity));
}

.arh-text-\[\#5a5a5a\]{
  --tw-text-opacity: 1;
  color: rgb(90 90 90 / var(--tw-text-opacity));
}

.arh-text-\[\#5b5d60\]{
  --tw-text-opacity: 1;
  color: rgb(91 93 96 / var(--tw-text-opacity));
}

.arh-text-\[\#5b8FDE\]{
  --tw-text-opacity: 1;
  color: rgb(91 143 222 / var(--tw-text-opacity));
}

.arh-text-\[\#5f6568\]{
  --tw-text-opacity: 1;
  color: rgb(95 101 104 / var(--tw-text-opacity));
}

.arh-text-\[\#61C2E0\]{
  --tw-text-opacity: 1;
  color: rgb(97 194 224 / var(--tw-text-opacity));
}

.arh-text-\[\#636769\]{
  --tw-text-opacity: 1;
  color: rgb(99 103 105 / var(--tw-text-opacity));
}

.arh-text-\[\#636C70\]{
  --tw-text-opacity: 1;
  color: rgb(99 108 112 / var(--tw-text-opacity));
}

.arh-text-\[\#665c80\]{
  --tw-text-opacity: 1;
  color: rgb(102 92 128 / var(--tw-text-opacity));
}

.arh-text-\[\#667085\]{
  --tw-text-opacity: 1;
  color: rgb(102 112 133 / var(--tw-text-opacity));
}

.arh-text-\[\#685F9D\]{
  --tw-text-opacity: 1;
  color: rgb(104 95 157 / var(--tw-text-opacity));
}

.arh-text-\[\#696969\]{
  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}

.arh-text-\[\#6A787D\]{
  --tw-text-opacity: 1;
  color: rgb(106 120 125 / var(--tw-text-opacity));
}

.arh-text-\[\#6B817C\]{
  --tw-text-opacity: 1;
  color: rgb(107 129 124 / var(--tw-text-opacity));
}

.arh-text-\[\#6b6b6b\]{
  --tw-text-opacity: 1;
  color: rgb(107 107 107 / var(--tw-text-opacity));
}

.arh-text-\[\#727279\]{
  --tw-text-opacity: 1;
  color: rgb(114 114 121 / var(--tw-text-opacity));
}

.arh-text-\[\#738BC7\]{
  --tw-text-opacity: 1;
  color: rgb(115 139 199 / var(--tw-text-opacity));
}

.arh-text-\[\#75787C\]{
  --tw-text-opacity: 1;
  color: rgb(117 120 124 / var(--tw-text-opacity));
}

.arh-text-\[\#757a80\]{
  --tw-text-opacity: 1;
  color: rgb(117 122 128 / var(--tw-text-opacity));
}

.arh-text-\[\#767676\]{
  --tw-text-opacity: 1;
  color: rgb(118 118 118 / var(--tw-text-opacity));
}

.arh-text-\[\#7762B1\]{
  --tw-text-opacity: 1;
  color: rgb(119 98 177 / var(--tw-text-opacity));
}

.arh-text-\[\#777777\]{
  --tw-text-opacity: 1;
  color: rgb(119 119 119 / var(--tw-text-opacity));
}

.arh-text-\[\#7A858A\]{
  --tw-text-opacity: 1;
  color: rgb(122 133 138 / var(--tw-text-opacity));
}

.arh-text-\[\#7E888C\]{
  --tw-text-opacity: 1;
  color: rgb(126 136 140 / var(--tw-text-opacity));
}

.arh-text-\[\#7F8286\]{
  --tw-text-opacity: 1;
  color: rgb(127 130 134 / var(--tw-text-opacity));
}

.arh-text-\[\#7a858a\]{
  --tw-text-opacity: 1;
  color: rgb(122 133 138 / var(--tw-text-opacity));
}

.arh-text-\[\#828896\]{
  --tw-text-opacity: 1;
  color: rgb(130 136 150 / var(--tw-text-opacity));
}

.arh-text-\[\#838383\]{
  --tw-text-opacity: 1;
  color: rgb(131 131 131 / var(--tw-text-opacity));
}

.arh-text-\[\#859298\]{
  --tw-text-opacity: 1;
  color: rgb(133 146 152 / var(--tw-text-opacity));
}

.arh-text-\[\#8A8A8A\]{
  --tw-text-opacity: 1;
  color: rgb(138 138 138 / var(--tw-text-opacity));
}

.arh-text-\[\#8B8F91\]{
  --tw-text-opacity: 1;
  color: rgb(139 143 145 / var(--tw-text-opacity));
}

.arh-text-\[\#8C8C8C\]{
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}

.arh-text-\[\#912B44\]{
  --tw-text-opacity: 1;
  color: rgb(145 43 68 / var(--tw-text-opacity));
}

.arh-text-\[\#949699\]{
  --tw-text-opacity: 1;
  color: rgb(148 150 153 / var(--tw-text-opacity));
}

.arh-text-\[\#949FB0\]{
  --tw-text-opacity: 1;
  color: rgb(148 159 176 / var(--tw-text-opacity));
}

.arh-text-\[\#94ABB7\]{
  --tw-text-opacity: 1;
  color: rgb(148 171 183 / var(--tw-text-opacity));
}

.arh-text-\[\#959595\]{
  --tw-text-opacity: 1;
  color: rgb(149 149 149 / var(--tw-text-opacity));
}

.arh-text-\[\#979797\]{
  --tw-text-opacity: 1;
  color: rgb(151 151 151 / var(--tw-text-opacity));
}

.arh-text-\[\#97a3a9\]{
  --tw-text-opacity: 1;
  color: rgb(151 163 169 / var(--tw-text-opacity));
}

.arh-text-\[\#9BA0A5\]{
  --tw-text-opacity: 1;
  color: rgb(155 160 165 / var(--tw-text-opacity));
}

.arh-text-\[\#9E9E9E\]{
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.arh-text-\[\#9FA6AA\]{
  --tw-text-opacity: 1;
  color: rgb(159 166 170 / var(--tw-text-opacity));
}

.arh-text-\[\#A09211\]{
  --tw-text-opacity: 1;
  color: rgb(160 146 17 / var(--tw-text-opacity));
}

.arh-text-\[\#A2A1A1\]{
  --tw-text-opacity: 1;
  color: rgb(162 161 161 / var(--tw-text-opacity));
}

.arh-text-\[\#A8A8A8\]{
  --tw-text-opacity: 1;
  color: rgb(168 168 168 / var(--tw-text-opacity));
}

.arh-text-\[\#B0BEC5\]{
  --tw-text-opacity: 1;
  color: rgb(176 190 197 / var(--tw-text-opacity));
}

.arh-text-\[\#BBBBBB\]{
  --tw-text-opacity: 1;
  color: rgb(187 187 187 / var(--tw-text-opacity));
}

.arh-text-\[\#BDA018\]{
  --tw-text-opacity: 1;
  color: rgb(189 160 24 / var(--tw-text-opacity));
}

.arh-text-\[\#BF5E35\]{
  --tw-text-opacity: 1;
  color: rgb(191 94 53 / var(--tw-text-opacity));
}

.arh-text-\[\#C01048\]{
  --tw-text-opacity: 1;
  color: rgb(192 16 72 / var(--tw-text-opacity));
}

.arh-text-\[\#C24E27\]{
  --tw-text-opacity: 1;
  color: rgb(194 78 39 / var(--tw-text-opacity));
}

.arh-text-\[\#DA5014\]{
  --tw-text-opacity: 1;
  color: rgb(218 80 20 / var(--tw-text-opacity));
}

.arh-text-\[\#DE271C\]{
  --tw-text-opacity: 1;
  color: rgb(222 39 28 / var(--tw-text-opacity));
}

.arh-text-\[\#E4B334\]{
  --tw-text-opacity: 1;
  color: rgb(228 179 52 / var(--tw-text-opacity));
}

.arh-text-\[\#E98F30\]{
  --tw-text-opacity: 1;
  color: rgb(233 143 48 / var(--tw-text-opacity));
}

.arh-text-\[\#EA8A60\]{
  --tw-text-opacity: 1;
  color: rgb(234 138 96 / var(--tw-text-opacity));
}

.arh-text-\[\#F29D0E\]{
  --tw-text-opacity: 1;
  color: rgb(242 157 14 / var(--tw-text-opacity));
}

.arh-text-\[\#F36242\]{
  --tw-text-opacity: 1;
  color: rgb(243 98 66 / var(--tw-text-opacity));
}

.arh-text-\[\#F55611\]{
  --tw-text-opacity: 1;
  color: rgb(245 86 17 / var(--tw-text-opacity));
}

.arh-text-\[\#F60A20\]{
  --tw-text-opacity: 1;
  color: rgb(246 10 32 / var(--tw-text-opacity));
}

.arh-text-\[\#F64662\]{
  --tw-text-opacity: 1;
  color: rgb(246 70 98 / var(--tw-text-opacity));
}

.arh-text-\[\#FA966B\]{
  --tw-text-opacity: 1;
  color: rgb(250 150 107 / var(--tw-text-opacity));
}

.arh-text-\[\#FB5A57\]{
  --tw-text-opacity: 1;
  color: rgb(251 90 87 / var(--tw-text-opacity));
}

.arh-text-\[\#df4d1f\]{
  --tw-text-opacity: 1;
  color: rgb(223 77 31 / var(--tw-text-opacity));
}

.arh-text-\[\#ea8a60\]{
  --tw-text-opacity: 1;
  color: rgb(234 138 96 / var(--tw-text-opacity));
}

.arh-text-\[\#eb6034\]{
  --tw-text-opacity: 1;
  color: rgb(235 96 52 / var(--tw-text-opacity));
}

.arh-text-\[\#ef3b60\]{
  --tw-text-opacity: 1;
  color: rgb(239 59 96 / var(--tw-text-opacity));
}

.arh-text-\[\#f29d0e\]{
  --tw-text-opacity: 1;
  color: rgb(242 157 14 / var(--tw-text-opacity));
}

.arh-text-black{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.arh-text-blueColor{
  --tw-text-opacity: 1;
  color: rgb(26 78 99 / var(--tw-text-opacity));
}

.arh-text-btnGreen{
  --tw-text-opacity: 1;
  color: rgb(115 199 183 / var(--tw-text-opacity));
}

.arh-text-btnTableFont{
  --tw-text-opacity: 1;
  color: rgb(136 140 151 / var(--tw-text-opacity));
}

.arh-text-colorPlaceholderInputSimple{
  --tw-text-opacity: 1;
  color: rgb(139 143 145 / var(--tw-text-opacity));
}

.arh-text-darkColor{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.arh-text-darkGreyColor{
  --tw-text-opacity: 1;
  color: rgb(57 60 64 / var(--tw-text-opacity));
}

.arh-text-darkOrange{
  --tw-text-opacity: 1;
  color: rgb(245 86 17 / var(--tw-text-opacity));
}

.arh-text-fontCardGreen{
  --tw-text-opacity: 1;
  color: rgb(53 108 107 / var(--tw-text-opacity));
}

.arh-text-gray-400{
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.arh-text-gray-500{
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.arh-text-gray-600{
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.arh-text-gray-700{
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.arh-text-gray-800{
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.arh-text-gray-900{
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.arh-text-green-700{
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.arh-text-greenColor{
  --tw-text-opacity: 1;
  color: rgb(115 199 183 / var(--tw-text-opacity));
}

.arh-text-greyDark{
  --tw-text-opacity: 1;
  color: rgb(59 77 86 / var(--tw-text-opacity));
}

.arh-text-greyDisabledText{
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.arh-text-greyEmpty{
  --tw-text-opacity: 1;
  color: rgb(122 133 138 / var(--tw-text-opacity));
}

.arh-text-inherit{
  color: inherit;
}

.arh-text-invalidColorTextInput{
  --tw-text-opacity: 1;
  color: rgb(246 10 32 / var(--tw-text-opacity));
}

.arh-text-invalidTextInput{
  --tw-text-opacity: 1;
  color: rgb(241 105 121 / var(--tw-text-opacity));
}

.arh-text-jobFree{
  --tw-text-opacity: 1;
  color: rgb(72 177 157 / var(--tw-text-opacity));
}

.arh-text-jobPayant{
  --tw-text-opacity: 1;
  color: rgb(126 94 205 / var(--tw-text-opacity));
}

.arh-text-jobProgress{
  --tw-text-opacity: 1;
  color: rgb(118 118 118 / var(--tw-text-opacity));
}

.arh-text-loaderBlue{
  --tw-text-opacity: 1;
  color: rgb(68 114 163 / var(--tw-text-opacity));
}

.arh-text-red-500{
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.arh-text-slate-500{
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.arh-text-slightBlueGrey{
  --tw-text-opacity: 1;
  color: rgb(139 143 145 / var(--tw-text-opacity));
}

.arh-text-teal-900{
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.arh-text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.arh-text-\[\#69727F\]{
  --tw-text-opacity: 1;
  color: rgb(105 114 127 / var(--tw-text-opacity));
}

.arh-text-\[\#717D94\]{
  --tw-text-opacity: 1;
  color: rgb(113 125 148 / var(--tw-text-opacity));
}

.arh-text-\[\#B1B9C9\]{
  --tw-text-opacity: 1;
  color: rgb(177 185 201 / var(--tw-text-opacity));
}

.arh-text-\[\#C29C3B\]{
  --tw-text-opacity: 1;
  color: rgb(194 156 59 / var(--tw-text-opacity));
}

.arh-text-\[\#DF3431\]{
  --tw-text-opacity: 1;
  color: rgb(223 52 49 / var(--tw-text-opacity));
}

.arh-text-\[\#750000\]{
  --tw-text-opacity: 1;
  color: rgb(117 0 0 / var(--tw-text-opacity));
}

.\!arh-underline{
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}

.arh-underline{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.arh-no-underline{
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.arh-underline-offset-0{
  text-underline-offset: 0px;
}

.arh-underline-offset-1{
  text-underline-offset: 1px;
}

.arh-underline-offset-2{
  text-underline-offset: 2px;
}

.arh-underline-offset-4{
  text-underline-offset: 4px;
}

.arh-caret-transparent{
  caret-color: transparent;
}

.arh-opacity-0{
  opacity: 0;
}

.arh-opacity-100{
  opacity: 1;
}

.arh-opacity-30{
  opacity: 0.3;
}

.arh-opacity-50{
  opacity: 0.5;
}

.arh-opacity-60{
  opacity: 0.6;
}

.arh-opacity-65{
  opacity: 0.65;
}

.arh-opacity-\[1\]{
  opacity: 1;
}

.arh-opacity-75{
  opacity: 0.75;
}

.arh-shadow-\[-1px_0_11px_rgba\(0\,0\,0\,4\%\)\]{
  --tw-shadow: -1px 0 11px rgba(0,0,0,4%);
  --tw-shadow-colored: -1px 0 11px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-\[0_0_13px_rgb\(0\,0\,0\,8\%\)\]{
  --tw-shadow: 0 0 13px rgb(0,0,0,8%);
  --tw-shadow-colored: 0 0 13px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-\[0_0_20px_rgba\(0\,0\,0\,6\%\)\]{
  --tw-shadow: 0 0 20px rgba(0,0,0,6%);
  --tw-shadow-colored: 0 0 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-\[0_3px_14px_2px_rgba\(51\,48\,48\,0\.34\)\]{
  --tw-shadow: 0 3px 14px 2px rgba(51,48,48,0.34);
  --tw-shadow-colored: 0 3px 14px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-\[0_3px_19px_rgba\(0\,0\,0\,8\%\)\]{
  --tw-shadow: 0 3px 19px rgba(0,0,0,8%);
  --tw-shadow-colored: 0 3px 19px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-\[0px_0px_13px_rgba\(0\,0\,0\,8\%\)\]{
  --tw-shadow: 0px 0px 13px rgba(0,0,0,8%);
  --tw-shadow-colored: 0px 0px 13px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-lg{
  --tw-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  --tw-shadow-colored: 0 4px 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-md{
  --tw-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px 4px 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-none{
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-soft{
  --tw-shadow: -4px 1px 17px rgba(0, 0, 0, 0.03);
  --tw-shadow-colored: -4px 1px 17px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-thin{
  --tw-shadow: 3px 3px 6px rgba(245, 246, 248, 1);
  --tw-shadow-colored: 3px 3px 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-shadow-xs{
  --tw-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  --tw-shadow-colored: 0 0 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.arh-outline-none{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.arh-outline-0{
  outline-width: 0px;
}

.arh-ring-0{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.arh-transition{
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.arh-transition-all{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.arh-transition-colors{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.arh-transition-transform{
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.arh-duration-100{
  transition-duration: 100ms;
}

.arh-duration-150{
  transition-duration: 150ms;
}

.arh-duration-200{
  transition-duration: 200ms;
}

.arh-duration-300{
  transition-duration: 300ms;
}

.arh-duration-500{
  transition-duration: 500ms;
}

.arh-duration-75{
  transition-duration: 75ms;
}

.arh-duration-\[100ms\]{
  transition-duration: 100ms;
}

.arh-ease-btnfocus{
  transition-timing-function: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.arh-ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.arh-ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.arh-ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.arh-line-clamp-1{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.arh-line-clamp-2{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.arh-line-clamp-3{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/* Style de la scrollbar */

.arh-scrollbar-thin::-webkit-scrollbar {
  height: 8px;
  /* Modifie l'épaisseur de la scrollbar */
}

/* Style de la partie qui glisse (thumb) */

.arh-scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #EFEFEF;
  /* Couleur de la barre */
  border-radius: 999px;
  /* Bord arrondi */
}

.arh-scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: #E9E9E9;
  /* Couleur de la barre de défilement au survol */
  cursor: pointer;
  /*height: 30px; /* Augmentez temporairement la largeur lors du survol */
}

/* Facultatif : style de la zone de fond */

.arh-scrollbar-thin::-webkit-scrollbar-track {
  background-color: white;
  /* Couleur de l'arrière-plan */
}

.arh-display-scroll-with-none {
  scrollbar-width: none;
}

.arh-display-scroll-with-none::-webkit-scrollbar {
  height: 0;
  /* Épaisseur de la barre de défilement verticale */
}

/*Scrollbar hide*/

.\[type\:boolean\]{
  type: boolean;
}

.\[type\:tableaux\]{
  type: tableaux;
}

/* Height:moins du mesure du top par rapport au parent */

.arh-height-collapse-response {
  height: calc(100% - 7.8rem);
}

/* Invalid label */

.error_label {
  color: #F60A20 !important;
}

/* End invalid label */

.arh-avatar-assigned ~ .avatar_employer {
  margin-left: -0.5rem;
}

.arh-content-protocole .form_group_pointeuse .input_pointeuse.active {
  border: 1px solid #73C7B7;
}

.arh-content-protocole .content_items_pointeuse.active {
  border-left: 5px solid #73C7B7;
}

.arh-upload-createTask {
  margin: 0 0 1.5rem !important;
}

.arh-upload-createTask .titleUpload_file--big {
  display: none !important;
}

.arh-list-etiquette-fullPosition {
  top: 0 !important;
  right: 0 !important;
}

.arh-text-align-right-table {
  text-align: right !important;
}

.arh-pr-14-table {
  padding-right: 80px !important;
}

.arh-datePicker-entreprise-sittings input {
  font-size: 0.75rem !important;
  padding-left: 1.5rem !important;
}

input.arh-datePicker-entreprise-sittings {
  font-size: 0.75rem !important;
  padding-left: 1.5rem !important;
}

.arh-py-table {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.arh-bg-orange {
  background-color: #FFEFE8;
}

.arh-text-orange {
  color: #DA5014;
}

.arh-bg-blue {
  background-color: #E2EBF9;
}

.arh-text-blue {
  color: #1D3E71;
}

.arh-bg-green {
  background-color: #E6F8EF;
}

.arh-text-green {
  color: #295143;
}

.arh-bg-blue-cyan {
  background-color: #D2DDE1;
}

.arh-text-blue-cyan {
  color: #203D48;
}

.arh-text-baby-blue {
  color: #1A4E63;
}

.arh-bg-baby-blue {
  background-color: #D3E3F1;
}

.arh-transition-btnAdd {
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

/* Nouvelle affichage */

.fourImage1 {
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
}

.fourImage2 {
  right: 0;
  top: 0;
  width: 50%;
  height: 50%;
}

.fourImage3 {
  left: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
}

.fourImage4 {
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
}

.oneImage {
  width: 100%;
  height: 100%;
}

.twoImage1,
.twoImage2 {
  width: 50%;
  height: 100%;
}

.threeImage1 {
  width: 50%;
  height: 100%;
}

.threeImage2 {
  width: 50%;
  height: 50%;
}

.threeImage3 {
  width: 50%;
  height: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.arh-max-h-new-right {
  max-height: calc(100vh - 13rem);
}

.arh-w-laneDivider {
  width: calc(100% + 100px);
}

.arh-add-news-editor .floating-text-format-popup {
  top: 0 !important;
  transform: inherit !important;
}

.view_news .editor-listitem {
  pointer-events: none;
}

.news-lexical .editor-listitem {
  pointer-events: none;
}

/* End Nouvelle affichage */

/* color checkbox document Ma fiche*/

.content_body_permission .rct-collapse-btn .rct-icon-expand-open {
  background-color: var(--greenColor);
  border: 1px solid var(--greenColor);
}

.content_body_permission .rct-icons-fa4 .rct-icon-uncheck::before {
  color: var(--greenColor);
}

.content_body_permission .rct-icons-fa4 .rct-icon-check::before,
.rct-icons-fa4 .rct-icon-half-check::before {
  color: var(--greenColor);
}

/* End color checkbox document Ma fiche*/

.theme-blue .content_body_permission .rct-collapse-btn .rct-icon-expand-open {
  background-color: var(--loaderParams) !important;
  border: 1px solid var(--loaderParams) !important;
}

.theme-blue .content_body_permission .rct-icons-fa4 .rct-icon-uncheck::before {
  color: var(--loaderParams) !important;
}

.theme-blue .content_body_permission .rct-icons-fa4 .rct-icon-check::before,
.rct-icons-fa4 .rct-icon-half-check::before {
  color: var(--loaderParams) !important;
}

/* Les inputs */

.focus_blue_param:focus {
  border: 1px solid var(--loaderParams) !important;
  caret-color: var(--loaderParams);
}

.field_invalid {
  border: 1px solid rgba(246, 10, 32, 0.5) !important;
  background-color: #FFF3F4 !important;
  color: #F60A20 !important;
}

.field_invalid::placeholder {
  color: #F60A20 !important;
}

/* End les inputs */

/* pipeline */

.arh-widthCalc-filter {
  width: calc(50% - 1.625rem);
}

.arh-widthCalc-filter-modele {
  width: calc(50% - 23px);
}

/* End pipeline */

/* Connexion */

.arh-logo-connexion svg {
  width: 1.063rem;
  height: 1.063rem;
}

.arh-btn-connexion::after {
  display: inline-block;
  padding-left: 8px;
  content: "\0279E";
  transition: transform 0.3s ease-out;
}

.arh-btn-connexion:hover {
  color: #D15825;
  background-color: #FFD1BC;
}

.arh-btn-connexion:hover::after {
  transform: translateX(4px);
}

.arh-btn-register:hover {
  color: #D15825;
  background-color: #FFD1BC;
  transition: transform 0.3s ease-out;
}

/* End Connexion */

/*hide scroll image to print */

.imgToPrint .pg-viewer-wrapper {
  overflow-y: inherit !important;
}

.arh-file canvas {
  object-fit: contain;
}

/* Calendar Green select */

/* .DateRangePicker_customisation--green .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range.react-datepicker__day--today {
  border-radius: 0 !important;
  background-color: var(--rangeDateGreen) !important;
  color: #292929;
  position: relative;
} */

/* End Calendar green select */

/* Collapse slide matériel et employer importation */

.arh-slide-collapse {
  transform: translateX(535px);
  transition: 300ms ease-in-out;
}

.arh-slide-collapse--expanded {
  transform: translateX(0);
}

.arh-slide-collapse-icon {
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.arh-slide-collapse-icon-active svg path {
  fill: #616161;
}

.arh-modal-btn {
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.arh-modal-btn[disabled] {
  color: #5A5A5A;
  background-color: var(--greyColor) !important;
  border: solid 1px var(--borderLoginColor);
  border-radius: 3px;
  cursor: not-allowed;
  opacity: 0.7;
}

/* END Collapse slide matériel et employer importation */

/* Folder Employer */

.arh-icones-timer-no {
  transform: rotate(-90deg);
}

/* End Folder Employer */

.arh-rotate-custom-0 {
  --tw-rotate: 0;
  /* transform: rotate(var(--tw-rotate)); */
}

.arh-rotate-custom-90 {
  --tw-rotate: 90deg;
  /* transform: rotate(var(--tw-rotate)); */
}

/* Lexical Editor */

.bordered-lexical .editor-input {
  max-height: 9rem;
  min-height: 250px;
  overflow-y: auto;
}

.bordered-lexical .editor-input iframe {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}

.arh-boxShadow-lexical_editor {
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

/* End Lexical Editor */

.arh-max-w-name-table {
  width: calc(100% - 1.5rem);
  max-width: calc(100% - 1.5rem);
}

.arh-max-w-parent-table-evaluation {
  width: calc(100% - 12px);
  max-width: calc(100% - 12px);
}

/* Evaluation Details */

.arh-detail-canvasSvg canvas,
.arh-detail-canvasSvg svg {
  width: 100% !important;
}

.arh-max-h-commentAssesment {
  max-height: calc(100vh + 7.5rem);
}

/* End Evaluation Details */

/* *Media Query indispensable */

@media screen and (max-height: 700px) {
  .arh-max-h-new-right {
    max-height: calc(100vh + 2.8rem);
  }

  .arh-overflow-inherit {
    overflow: inherit !important;
  }
}

/* End Media Query */

/*Eliminate i-frame on error*/

body > iframe[style*="2147483647"] {
  display: none !important;
}

.arh-tree ol {
  position: relative;
  border-left: 2px dotted var(--colorgrey);
  margin-left: 36px !important;
}

.arh-tree li {
  position: relative;
}

.arh-tree .react-checkbox-tree {
  margin-bottom: 2rem;
}

.arh-tree label {
  font-size: 13px;
  margin-left: 1.5rem;
}

.arh-tree .rct-node-parent ol .rct-node-leaf .rct-title {
  font-family: "Poppins";
  font-style: auto;
  font-weight: 400;
  font-size: 12px;
}

.arh-tree .rct-title {
  margin-left: 0.5rem;
}

.arh-tree .rct-node {
  margin-bottom: 0.25rem;
}

.arh-tree .rct-node-parent .rct-title,
.arh-tree .rct-node-leaf .rct-title {
  font-family: "Poppins";
  font-style: auto;
  font-weight: 500;
  font-size: 12px;
}

.arh-tree .rct-node-parent label,
.arh-tree .rct-node-leaf label,
.arh-tree .rct-node-parent > ol label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.arh-tree .rct-collapse .rct-icon-expand-close,
.arh-tree .rct-collapse .rct-icon-expand-open {
  opacity: 1;
  width: 16px;
  height: 16px;
  background-color: #73C7B7;
  color: white;
  border-radius: 50%;
  border: 1px solid #73C7B7;
  font-size: 11px;
  margin-bottom: 3px;
  position: absolute;
  top: -0.7rem;
  left: -0.5rem;
  z-index: 1;
}

.arh-tree .rct-icons-fa4 .rct-icon-uncheck::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}

.arh-tree.arh-tree-invalid .rct-icons-fa4 .rct-icon-uncheck::before {
  border-color: #F79CA7;
}

.arh-tree .rct-icon {
  position: relative;
}

.arh-tree .rct-icons-fa4 .rct-icon-check::before,
.arh-tree .rct-icons-fa4 .rct-icon-half-check::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}

.arh-tree .rct-icons-fa4 .rct-icon-check::after,
.arh-tree .rct-icons-fa4 .rct-icon-half-check::after {
  content: "" !important;
  display: block;
  background: #73C7B7;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.arh-tree .react-checkbox-tree label:hover {
  background: transparent !important;
}

/* Bar progress in Evaluation */

/*///////////////////////////////////////////////////
    // Skill Bars \\ 
///////////////////////////////////////////////////*/

.arh-skillBarValue {
  float: left;
  width: 0;
  --target-width: 0;
  animation: slideIn 2s ease-out forwards;
}

/*///////////////////////////////////////////////////
    // Animation \\ 
///////////////////////////////////////////////////*/

@keyframes slideIn {
  0% {
    width: 0;
  }

  25% {
    width: 100%;
  }

  100% {
    width: var(--target-width);
  }
}

.arh-rotate-180-main {
  transform: rotate(-180deg);
}

/* End Bar progress in Evaluation */

/* MuiDivider-root */

.MuiDivider-root {
  padding: 0 !important;
  display: none !important;
}

/* End MuiDivider-root */

/* Linear Gradient */

.arh-bg-gradient-progression {
  background: linear-gradient(270deg, #A7EBDE 0%, #D3F0C9 39.15%, #F8F1B6 55.93%, #F7F7F7 75.1%, #F7F7F7 100%);
}

/* End Linear Gradient */

/* Bar de progression border negative = rondue de sens contraire */

/* arh-bg-[#E4ECEE] */

.arh-bar-border-negation {
  background-image: radial-gradient(circle at 2px 10px, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 11px, #E4ECEE 13px);
}

/*code check anime*/

.arh-stepConfirmation svg {
  width: 100%;
  margin: 3.49vw auto;
}

@keyframes outline {
  from {
    stroke-dasharray: 0, 345.576px;
  }

  to {
    stroke-dasharray: 345.576px, 345.576px;
  }
}

#outline {
  animation: 0.38s ease-in outline;
  transform: rotate(0deg);
  transform-origin: center;
}

@keyframes circle {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

#white-circle {
  animation: 0.35s ease-in 0.35s forwards circle;
  transform: none;
  transform-origin: center;
}

@keyframes check {
  from {
    stroke-dasharray: 0, 75px;
  }

  to {
    stroke-dasharray: 75px, 75px;
  }
}

#check {
  animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
  stroke-dasharray: 0, 75px;
}

@keyframes check-group {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.09);
  }

  to {
    transform: scale(1);
  }
}

#check-group {
  animation: 0.32s ease-in-out 1.03s check-group;
  transform-origin: center;
}

/* End  */

.arh-divider-login:before {
  color: #ECECEC;
  content: "";
  flex: 1 1;
  box-shadow: 0 -2px;
  height: 1px;
  margin: auto 0.25rem;
  margin-right: 1rem;
}

.arh-divider-login:after {
  color: #ECECEC;
  content: "";
  flex: 1 1;
  box-shadow: 0 -2px;
  height: 1px;
  margin: auto 0.25rem;
  margin-left: 1rem;
}

.arh-other-connexion > div {
  width: 48%;
}

@media screen and (max-height: 1280px) {
  .arh-other-connexion > div {
    width: 100%;
  }
}

#react-org-chart svg rect {
  height: 200px
}

/* Disclosure */

.arh-collapse-param .arh-collapse-param-child {
  padding: 0 4rem 2rem 6rem;
}

.arh-scrollable-content:we­bkit-scrollbar {
  scrollbar-color: white;
}

.arh-scrollable-content:-we­bkit-scrollbar-thumb­ {
  background-color: white;
}

.arh-scrollable-content::-webkit-scrollbar-track {
  background-color: white;
}

.arh-h-SideCollapseQuestionDev {
  height: calc(100% - 7.8rem);
}

.arh-whitespace-break-spaces {
  white-space: break-spaces;
}

/* End Disclosure */

.lexical iframe {
  width: 100%;
  max-width: 36.125rem;
}

/* Customisation Date range picker dans acceptation congé */

.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__year-read-view--selected-year .react-datepicker__day-name,
.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__day,
.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__time-name {
  width: 65px;
  height: 63px;
}

.arh-dateRange-congeReplies .DateRangePicker_customisation .react-datepicker__month-read-view--selected-month {
  height: 40px;
}

.arh-dateRange-congeReplies .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after,
.arh-dateRange-saveHours .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after {
  background-color: unset;
}

.arh-dateRange-congeReplies .react-datepicker__day--outside-month {
  color: #B0BEC5 !important;
}

.DateRangePicker_customisation .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after,
.arh-dateRange-saveHours .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end::after {
  background-color: unset;
}

/* .arh-dateRange-congeReplies .DateRangePicker_customisation--orange .react-datepicker__day--range-start:before,
.arh-dateRange-congeReplies .DateRangePicker_customisation--orange .react-datepicker__day--range-end:before {
  background-color: #FA966B;
} */

/* END Customisation Date range picker dans acceptation congé */

/* PWA */

@media screen and (max-width: 768px) {
  .arh-mobile-avatar-sidebar .arh-avatar-mobile {
    width: 100%;
    justify-content: center;
  }

  .arh-mobile-avatar-sidebar .arh-avatar-mobile p {
    max-width: 9rem;
  }
}

/* END PWA */

/* Time Picker */

.arh-timePicker .react-datepicker-wrapper {
  width: 100%;
}

.arh-timePicker .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  border: 0;
  cursor: pointer;
  font-weight: 400 !important;
  font-size: .75rem !important;
  font-family: Poppins !important;
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.arh-timePicker .react-datepicker__header--time--only {
  display: none;
}

.arh-timePicker .react-datepicker__triangle {
  display: none;
}

.arh-timePicker .react-datepicker.react-datepicker--time-only {
  width: 100% !important;
  border: 0 !important;
}

.arh-timePicker .react-datepicker__time-container.react-datepicker__time-container--with-today-button {
  position: initial !important;
  border: 1px solid #ECECEC;
  border-radius: 6px;
}

.arh-timePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #73C7B7 !important;
}

.arh-timePicker .react-datepicker-popper {
  top: 0.5rem !important;
  left: -8.7rem !important;
}

/* End Time Picker */

/* Invalid for timePicker Input */

.arh-invalid-input-timePicker input {
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.arh-invalid-input-timePicker input::placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.arh-invalid-input-timePicker input {
  background: transparent !important;
}

/* End Invalid for timePicker Input */

/* Notification Warning not Less */

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--warning {
  border-color: #FFB201;
  background-color: #F8F1E2;
  color: #D89804;
}

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage .progress-bar--warning {
  background-color: #FFB201;
}

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage.calloutMessage--warning {
  padding: 2rem 3.5rem 2rem 1.5rem !important;
}

.calloutMessage--warning .arh-w-full.arh-h-\[calc\(100\%-8px\)\] .arh-space-x-5 {
  height: 100%;
}

.arh-toastify .Toastify__toast-container {
  height: auto !important;
  min-height: 120px;
}

.arh-toastify .Toastify__toast-container .Toastify__toast.arh-cursor-default {
  height: 100% !important;
  max-height: inherit !important;
  min-height: 120px !important;
  border: 0 !important;
}

.arh-toastify .Toastify__toast-container .calloutMessage {
  height: auto !important;
  padding: 1.5rem !important;
}

/* Correction collage notification pour error et success */

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body {
  height: inherit !important;
  padding: 0 !important;
  margin: 0 !important;
}

.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--error,
.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--success,
.arh-toastify .Toastify .Toastify__toast-container .Toastify__toast-body .calloutMessage--info {
  position: absolute !important;
  height: 100% !important;
}

/* End Correction collage notification */

/* End Notification Warning not Less */

.react-datepicker__day--keyboard-selected:not(:hover) {
  background-color: unset !important;
  color: #000 !important;
}

.react-datepicker__day--keyboard-selected:not(:hover).react-datepicker__day--today {
  /* background-color: #F8F8FA !important; */
  color: #000 !important;
  border-radius: 50%;
}

/* ToolBar for formulaire Recrutement */

.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header {
  z-index: initial !important;
}

/* End ToolBar for formulaire Recrutement */

/* Lexical Editor for Modele formulaire  */

.react-form-builder .react-form-builder-preview label {
  margin-top: 2rem;
}

/* Lexical Editor for Modele formulaire */

.Modal__modal {
  font-family: Poppins;
}

.Modal__modal .Input__wrapper {
  flex-direction: column;
  align-items: start;
}

.Modal__modal .Input__label {
  color: #292929;
}

.Modal__modal .Input__input {
  font-size: .75rem;
  padding: .75rem 1.5rem;
  border-color: #ECECEC;
  flex: 1 0;
  width: 100%;
}

.Modal__modal .Button__root {
  background-color: white;
  border: 1px solid #D7D9DD;
  color: #292929;
  transition: background 200ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: .75rem 1.5rem;
  font-size: .75rem;
}

.Modal__modal .Button__root:hover {
  background-color: #F5F5F5;
}

.Modal__modal .Button__root:active {
  background-color: #EFEFF0;
}

.arh-time .react-datepicker-wrapper {
  width: 100px !important;
}

.arh-year-range .react-datepicker__year-wrapper {
  max-width: 300px !important;
  /* min-height: 150px; */
}

.arh-year-range-green div.react-datepicker__year-text.react-datepicker__year-text--selected {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #73C7B7 !important;
  border-radius: 4px !important;
}

textarea {
  scroll-padding-block: 12px;
}

.arh-year-range-green div.react-datepicker__year-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem !important;
}

.arh-year-range-green div.react-datepicker__year-text:hover {
  background-color: var(--loaderGreen);
  color: var(--darkColor);
  border-radius: 4px !important;
}

.DateRangePicker_customisation .react-datepicker__day--outside-month {
  opacity: 0;
}

/* Custom working hours importation */

.arh-importation-colorArrow--white {
  span svg path {
    fill: #FFFFFF !important
  }
}

.arh-importation-colorArrow--orange {
  span svg path {
    fill: #F55611 !important
  }
}

/* End Custom working hours importation  */

/* Radius */

.arh-radius-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.arh-radius-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* END Radius */

.arh-widthDetailed {
  width: calc(100vw - 24.688rem);
}

/* New Style Permission*/

.arh-new-style-permission ol {
  position: relative;
  border-left: 2px dotted var(--colorgrey);
  margin-left: 13px !important;
}

.arh-new-style-permission li {
  position: relative;
}

.arh-new-style-permission .react-checkbox-tree {
  margin-bottom: 2rem;
}

.arh-new-style-permission label {
  font-size: 13px;
  margin-left: 1.5rem;
}

.arh-new-style-permission .rct-node-parent ol .rct-node-leaf .rct-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.arh-new-style-permission .rct-title {
  margin-left: 0.5rem;
  text-align: start;
}

.arh-new-style-permission .rct-node {
  margin-bottom: 0.25rem;
}

.arh-new-style-permission .rct-node-parent .rct-title,
.arh-new-style-permission .rct-node-leaf .rct-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.arh-new-style-permission .rct-node-parent label,
.arh-new-style-permission .rct-node-leaf label,
.arh-new-style-permission .rct-node-parent>ol label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.arh-new-style-permission .rct-collapse .rct-icon-expand-close,
.arh-new-style-permission .rct-collapse .rct-icon-expand-open {
  opacity: 1;
  width: 16px;
  height: 16px;
  background-color: var(--loaderParams);
  color: white;
  border-radius: 50%;
  border: 1px solid var(--loaderParams);
  font-size: 11px;
  margin-bottom: 3px;
  position: absolute;
  top: -0.7rem;
  left: -0.5rem;
  z-index: 1;
}

.arh-new-style-permission .rct-icons-fa4 .rct-icon-uncheck::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}

.arh-new-style-permission .rct-icon {
  position: relative;
}

.arh-new-style-permission .rct-icons-fa4 .rct-icon-check::before,
.arh-new-style-permission .rct-icons-fa4 .rct-icon-half-check::before {
  content: "" !important;
  display: block;
  background: #FFFFFF;
  border: 1px solid #DFDEDE;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}

.arh-new-style-permission .rct-icons-fa4 .rct-icon-check::after,
.arh-new-style-permission .rct-icons-fa4 .rct-icon-half-check::after {
  content: "" !important;
  display: block;
  background: var(--loaderParams);
  border-radius: 2px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.arh-new-style-permission .react-checkbox-tree label:hover {
  background: transparent !important;
}

.arh-new-style-permission {
  padding: 0.5rem !important;
  width: 100%;
  height: 380px;
  border: 2px solid #ebebeb;
  margin-top: 1rem;
  max-height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
  color: #292929;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
}

/*End New Style*/

/* Custom dropZone File Upload */

.arh-custom-fileUpload-disciplinary {
  margin: inherit !important;
}

.arh-custom-fileUpload-disciplinary .dzu-dropzone {
  border: 0 !important;
}

/* TimePicker Green */

.arh-timePicker-green.input_detail .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected {
  background-color: var(--greenColor);
}

/* End TimePicker Green */

.first-letter\:\!arh-text-xs::first-letter{
  font-size: .75rem !important;
}

.selection\:arh-bg-\[\#73C7B7\] *::selection{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.selection\:arh-text-darkColor *::selection{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.selection\:arh-bg-\[\#73C7B7\]::selection{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.selection\:arh-text-darkColor::selection{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.placeholder\:\!arh-bg-invalidBackgroundInput::placeholder{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 243 244 / var(--tw-bg-opacity)) !important;
}

.placeholder\:arh-bg-\[\#FFF3F4\]::placeholder{
  --tw-bg-opacity: 1;
  background-color: rgb(255 243 244 / var(--tw-bg-opacity));
}

.placeholder\:\!arh-text-xs::placeholder{
  font-size: .75rem !important;
}

.placeholder\:arh-text-\[11px\]::placeholder{
  font-size: 11px;
}

.placeholder\:arh-text-sm::placeholder{
  font-size: .875rem;
}

.placeholder\:arh-text-xs::placeholder{
  font-size: .75rem;
}

.placeholder\:\!arh-text-\[\#8B8F91\]::placeholder{
  --tw-text-opacity: 1 !important;
  color: rgb(139 143 145 / var(--tw-text-opacity)) !important;
}

.placeholder\:\!arh-text-\[\#F60A20\]::placeholder{
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.placeholder\:\!arh-text-darkColor::placeholder{
  --tw-text-opacity: 1 !important;
  color: rgb(41 41 41 / var(--tw-text-opacity)) !important;
}

.placeholder\:\!arh-text-invalidColorTextInput::placeholder{
  --tw-text-opacity: 1 !important;
  color: rgb(246 10 32 / var(--tw-text-opacity)) !important;
}

.placeholder\:arh-text-\[\#757A80\]::placeholder{
  --tw-text-opacity: 1;
  color: rgb(117 122 128 / var(--tw-text-opacity));
}

.placeholder\:arh-text-\[\#8B8F91\]::placeholder{
  --tw-text-opacity: 1;
  color: rgb(139 143 145 / var(--tw-text-opacity));
}

.placeholder\:arh-text-\[\#97a3a9\]::placeholder{
  --tw-text-opacity: 1;
  color: rgb(151 163 169 / var(--tw-text-opacity));
}

.placeholder\:arh-text-\[\#ABABAB\]::placeholder{
  --tw-text-opacity: 1;
  color: rgb(171 171 171 / var(--tw-text-opacity));
}

.placeholder\:arh-text-\[\#B0BEC5\]::placeholder{
  --tw-text-opacity: 1;
  color: rgb(176 190 197 / var(--tw-text-opacity));
}

.placeholder\:arh-text-\[\#F60A20\]::placeholder{
  --tw-text-opacity: 1;
  color: rgb(246 10 32 / var(--tw-text-opacity));
}

.placeholder\:arh-text-black::placeholder{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.placeholder\:arh-text-colorPlaceholderInputSimple::placeholder{
  --tw-text-opacity: 1;
  color: rgb(139 143 145 / var(--tw-text-opacity));
}

.placeholder\:arh-text-darkColor::placeholder{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.placeholder\:arh-text-darkOrange::placeholder{
  --tw-text-opacity: 1;
  color: rgb(245 86 17 / var(--tw-text-opacity));
}

.placeholder\:arh-text-greyDisabledText::placeholder{
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.placeholder\:arh-text-invalidColorTextInput::placeholder{
  --tw-text-opacity: 1;
  color: rgb(246 10 32 / var(--tw-text-opacity));
}

.placeholder\:arh-text-white::placeholder{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.before\:arh-absolute::before{
  content: var(--tw-content);
  position: absolute;
}

.before\:arh-left-1\/2::before{
  content: var(--tw-content);
  left: 50%;
}

.before\:arh-top-1\/2::before{
  content: var(--tw-content);
  top: 50%;
}

.before\:arh-h-2\.5::before{
  content: var(--tw-content);
  height: 0.625rem;
}

.before\:arh-w-2\.5::before{
  content: var(--tw-content);
  width: 0.625rem;
}

.before\:-arh-translate-x-1\/2::before{
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-arh-translate-y-1\/2::before{
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:arh-rounded-full::before{
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:arh-rounded-sm::before{
  content: var(--tw-content);
  border-radius: 0.125rem;
}

.before\:arh-bg-transparent::before{
  content: var(--tw-content);
  background-color: transparent;
}

.before\:arh-content-\[\'\'\]::before{
  --tw-content: '';
  content: var(--tw-content);
}

.after\:arh-absolute::after{
  content: var(--tw-content);
  position: absolute;
}

.after\:arh-bottom-\[-6px\]::after{
  content: var(--tw-content);
  bottom: -6px;
}

.after\:arh-left-0::after{
  content: var(--tw-content);
  left: 0px;
}

.after\:arh-block::after{
  content: var(--tw-content);
  display: block;
}

.after\:arh-h-\[6px\]::after{
  content: var(--tw-content);
  height: 6px;
}

.after\:arh-w-full::after{
  content: var(--tw-content);
  width: 100%;
}

.after\:arh-origin-left::after{
  content: var(--tw-content);
  transform-origin: left;
}

.after\:arh-scale-100::after{
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:arh-scale-x-0::after{
  content: var(--tw-content);
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:arh-rounded::after{
  content: var(--tw-content);
  border-radius: 0.25rem;
}

.after\:arh-bg-\[\#1A4E63\]::after{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(26 78 99 / var(--tw-bg-opacity));
}

.after\:arh-bg-\[\#73C7B7\]::after{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.after\:arh-bg-\[\#95C5E7\]::after{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(149 197 231 / var(--tw-bg-opacity));
}

.after\:arh-bg-\[\#FA966B\]::after{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(250 150 107 / var(--tw-bg-opacity));
}

.after\:arh-transition::after{
  content: var(--tw-content);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:arh-duration-200::after{
  content: var(--tw-content);
  transition-duration: 200ms;
}

.after\:arh-ease-in-out::after{
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.after\:arh-content-\[\"\"\]::after{
  --tw-content: "";
  content: var(--tw-content);
}

.first\:arh-mr-\[0\.2rem\]:first-child{
  margin-right: 0.2rem;
}

.first\:arh-mt-0:first-child{
  margin-top: 0px;
}

.last\:\!arh-mb-0:last-child{
  margin-bottom: 0px !important;
}

.last\:arh-ml-\[1\.25rem\]:last-child{
  margin-left: 1.25rem;
}

.last\:arh-border-b:last-child{
  border-bottom-width: 1px;
}

.checked\:arh-bg-greenColor:checked{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.checked\:arh-bg-orangeColor:checked{
  --tw-bg-opacity: 1;
  background-color: rgb(252 182 152 / var(--tw-bg-opacity));
}

.checked\:arh-ring:checked{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.checked\:arh-ring-inset:checked{
  --tw-ring-inset: inset;
}

.checked\:arh-ring-white:checked{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.checked\:before\:arh-bg-\[\#95C5E7\]:checked::before{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(149 197 231 / var(--tw-bg-opacity));
}

.checked\:before\:arh-bg-blueColor:checked::before{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(26 78 99 / var(--tw-bg-opacity));
}

.checked\:before\:arh-bg-gray-500:checked::before{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.checked\:before\:arh-bg-greenColor:checked::before{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.checked\:before\:arh-bg-orangeColor:checked::before{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(252 182 152 / var(--tw-bg-opacity));
}

.invalid\:arh-text-invalidColorTextInput:invalid{
  --tw-text-opacity: 1;
  color: rgb(246 10 32 / var(--tw-text-opacity));
}

.read-only\:arh-cursor-default:read-only{
  cursor: default;
}

.read-only\:arh-border-greyDisabledBorder:read-only{
  --tw-border-opacity: 1;
  border-color: rgb(189 189 189 / var(--tw-border-opacity));
}

.read-only\:arh-bg-greyDisabled:read-only{
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.read-only\:arh-text-greyDisabledText:read-only{
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.read-only\:placeholder\:arh-text-greyDisabledText:read-only::placeholder{
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.hover\:arh-flex:hover{
  display: flex;
}

.hover\:arh-scale-100:hover{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:arh-scale-110:hover{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:arh-cursor-not-allowed:hover{
  cursor: not-allowed;
}

.hover\:arh-cursor-pointer:hover{
  cursor: pointer;
}

.hover\:arh-border-\[\#292929\]:hover{
  --tw-border-opacity: 1;
  border-color: rgb(41 41 41 / var(--tw-border-opacity));
}

.hover\:arh-border-\[\#D3E3F1\]:hover{
  --tw-border-opacity: 1;
  border-color: rgb(211 227 241 / var(--tw-border-opacity));
}

.hover\:arh-border-\[\#ffa680\]:hover{
  --tw-border-opacity: 1;
  border-color: rgb(255 166 128 / var(--tw-border-opacity));
}

.hover\:arh-border-black:hover{
  --tw-border-opacity: 1;
  border-color: rgb(41 41 41 / var(--tw-border-opacity));
}

.hover\:arh-border-blueColor:hover{
  --tw-border-opacity: 1;
  border-color: rgb(26 78 99 / var(--tw-border-opacity));
}

.hover\:arh-border-darkGreenColor:hover{
  --tw-border-opacity: 1;
  border-color: rgb(62 153 136 / var(--tw-border-opacity));
}

.hover\:arh-border-darkerColor:hover{
  --tw-border-opacity: 1;
  border-color: rgb(20 20 20 / var(--tw-border-opacity));
}

.hover\:arh-border-greenColor:hover{
  --tw-border-opacity: 1;
  border-color: rgb(115 199 183 / var(--tw-border-opacity));
}

.hover\:arh-border-hoverBlueColor:hover{
  --tw-border-opacity: 1;
  border-color: rgb(32 61 72 / var(--tw-border-opacity));
}

.hover\:arh-border-lightGreenHover:hover{
  --tw-border-opacity: 1;
  border-color: rgb(163 221 210 / var(--tw-border-opacity));
}

.hover\:arh-border-loaderOrange:hover{
  --tw-border-opacity: 1;
  border-color: rgb(250 150 107 / var(--tw-border-opacity));
}

.hover\:arh-border-orangeColor:hover{
  --tw-border-opacity: 1;
  border-color: rgb(252 182 152 / var(--tw-border-opacity));
}

.hover\:arh-border-transparent:hover{
  border-color: transparent;
}

.hover\:\!arh-bg-transparent:hover{
  background-color: transparent !important;
}

.hover\:\!arh-bg-white:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.hover\:arh-bg-\[\#1A4E63\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(26 78 99 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#292929\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(41 41 41 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#A8A8A8\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(168 168 168 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#B3BFD8\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(179 191 216 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#E7F8F3\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(231 248 243 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#F8F8F8\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#F9FAFC\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#f9fafc\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}

.hover\:arh-bg-\[\#ffa680\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 166 128 / var(--tw-bg-opacity));
}

.hover\:arh-bg-black:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(41 41 41 / var(--tw-bg-opacity));
}

.hover\:arh-bg-black\/50:hover{
  background-color: rgb(41 41 41 / 0.5);
}

.hover\:arh-bg-blueColor:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(26 78 99 / var(--tw-bg-opacity));
}

.hover\:arh-bg-darkGreenColor:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(62 153 136 / var(--tw-bg-opacity));
}

.hover\:arh-bg-darkerColor:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
}

.hover\:arh-bg-gray-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:arh-bg-gray-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:arh-bg-greenColor:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(115 199 183 / var(--tw-bg-opacity));
}

.hover\:arh-bg-greyActive:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 240 / var(--tw-bg-opacity));
}

.hover\:arh-bg-greyColor:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}

.hover\:arh-bg-greyDisabled:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.hover\:arh-bg-hoverBlueColor:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(32 61 72 / var(--tw-bg-opacity));
}

.hover\:arh-bg-invalidBackgroundInput:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 243 244 / var(--tw-bg-opacity));
}

.hover\:arh-bg-lightGreenHover:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(163 221 210 / var(--tw-bg-opacity));
}

.hover\:arh-bg-loaderBlue:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(68 114 163 / var(--tw-bg-opacity));
}

.hover\:arh-bg-loaderBlue\/50:hover{
  background-color: rgb(68 114 163 / 0.5);
}

.hover\:arh-bg-loaderOrange:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(250 150 107 / var(--tw-bg-opacity));
}

.hover\:arh-bg-orangeColor:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(252 182 152 / var(--tw-bg-opacity));
}

.hover\:arh-bg-transparent:hover{
  background-color: transparent;
}

.hover\:arh-bg-white:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:arh-font-semibold:hover{
  font-weight: 600;
}

.hover\:\!arh-text-\[\#5a5a5a\]:hover{
  --tw-text-opacity: 1 !important;
  color: rgb(90 90 90 / var(--tw-text-opacity)) !important;
}

.hover\:\!arh-text-darkGreyColor:hover{
  --tw-text-opacity: 1 !important;
  color: rgb(57 60 64 / var(--tw-text-opacity)) !important;
}

.hover\:arh-text-\[\#101828\]:hover{
  --tw-text-opacity: 1;
  color: rgb(16 24 40 / var(--tw-text-opacity));
}

.hover\:arh-text-\[\#1A4E63\]:hover{
  --tw-text-opacity: 1;
  color: rgb(26 78 99 / var(--tw-text-opacity));
}

.hover\:arh-text-\[\#D3E3F1\]:hover{
  --tw-text-opacity: 1;
  color: rgb(211 227 241 / var(--tw-text-opacity));
}

.hover\:arh-text-black:hover{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.hover\:arh-text-black\/50:hover{
  color: rgb(41 41 41 / 0.5);
}

.hover\:arh-text-darkColor:hover{
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity));
}

.hover\:arh-text-loaderBlue:hover{
  --tw-text-opacity: 1;
  color: rgb(68 114 163 / var(--tw-text-opacity));
}

.hover\:arh-text-textBlackBtnHover:hover{
  --tw-text-opacity: 1;
  color: rgb(17 20 45 / var(--tw-text-opacity));
}

.hover\:arh-text-white:hover{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:arh-underline:hover{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.hover\:arh-underline-offset-1:hover{
  text-underline-offset: 1px;
}

.hover\:after\:arh-scale-x-100:hover::after{
  content: var(--tw-content);
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.focus\:arh-border:focus{
  border-width: 1px;
}

.focus\:arh-border-solid:focus{
  border-style: solid;
}

.focus\:\!arh-border-\[\#ECECEC\]:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgb(236 236 236 / var(--tw-border-opacity)) !important;
}

.focus\:arh-border-\[\#1a4e63\]:focus{
  --tw-border-opacity: 1;
  border-color: rgb(26 78 99 / var(--tw-border-opacity));
}

.focus\:arh-border-\[\#4472a3\]:focus{
  --tw-border-opacity: 1;
  border-color: rgb(68 114 163 / var(--tw-border-opacity));
}

.focus\:arh-border-\[\#fcb698\]:focus{
  --tw-border-opacity: 1;
  border-color: rgb(252 182 152 / var(--tw-border-opacity));
}

.focus\:arh-border-greenColor:focus{
  --tw-border-opacity: 1;
  border-color: rgb(115 199 183 / var(--tw-border-opacity));
}

.focus\:arh-bg-\[\#333333\]:focus{
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity));
}

.focus\:arh-bg-\[\#B3BFD8\]:focus{
  --tw-bg-opacity: 1;
  background-color: rgb(179 191 216 / var(--tw-bg-opacity));
}

.focus\:arh-bg-\[\#f9fafc\]:focus{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}

.focus\:arh-text-\[\#1A4E63\]:focus{
  --tw-text-opacity: 1;
  color: rgb(26 78 99 / var(--tw-text-opacity));
}

.focus\:arh-outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:arh-ring-0:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:arh-border-\[\#ECECEC\]:focus-visible{
  --tw-border-opacity: 1;
  border-color: rgb(236 236 236 / var(--tw-border-opacity));
}

.focus-visible\:arh-border-greyBorderInput:focus-visible{
  --tw-border-opacity: 1;
  border-color: rgb(228 236 248 / var(--tw-border-opacity));
}

.focus-visible\:arh-ring-2:focus-visible{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:arh-ring-\[\#ECECEC\]:focus-visible{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 236 236 / var(--tw-ring-opacity));
}

.focus-visible\:arh-ring-greyBorderInput:focus-visible{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(228 236 248 / var(--tw-ring-opacity));
}

.focus-visible\:arh-ring-white:focus-visible{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus-visible\:arh-ring-opacity-75:focus-visible{
  --tw-ring-opacity: 0.75;
}

.focus-visible\:arh-ring-offset-2:focus-visible{
  --tw-ring-offset-width: 2px;
}

.focus-visible\:arh-ring-offset-greyColor:focus-visible{
  --tw-ring-offset-color: #F9FAFC;
}

.active\:arh-border-darkOrange:active{
  --tw-border-opacity: 1;
  border-color: rgb(245 86 17 / var(--tw-border-opacity));
}

.active\:arh-bg-darkGreenColor:active{
  --tw-bg-opacity: 1;
  background-color: rgb(62 153 136 / var(--tw-bg-opacity));
}

.active\:arh-bg-gray-100:active{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.active\:arh-bg-gray-200:active{
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.active\:arh-bg-greyActive:active{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 240 / var(--tw-bg-opacity));
}

.active\:arh-bg-loaderBlue\/70:active{
  background-color: rgb(68 114 163 / 0.7);
}

.active\:arh-bg-orangeColor:active{
  --tw-bg-opacity: 1;
  background-color: rgb(252 182 152 / var(--tw-bg-opacity));
}

.active\:arh-bg-transparent:active{
  background-color: transparent;
}

.active\:arh-text-white:active{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.disabled\:arh-pointer-events-none:disabled{
  pointer-events: none;
}

.disabled\:arh-cursor-default:disabled{
  cursor: default;
}

.disabled\:arh-cursor-none:disabled{
  cursor: none;
}

.disabled\:arh-cursor-not-allowed:disabled{
  cursor: not-allowed;
}

.disabled\:arh-border-greyDisabled:disabled{
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.disabled\:arh-border-greyDisabledBorder:disabled{
  --tw-border-opacity: 1;
  border-color: rgb(189 189 189 / var(--tw-border-opacity));
}

.disabled\:\!arh-bg-\[\#F8F8F8\]:disabled{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity)) !important;
}

.disabled\:arh-bg-greyDisabled:disabled{
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.disabled\:\!arh-text-\[\#8A8A8A\]:disabled{
  --tw-text-opacity: 1 !important;
  color: rgb(138 138 138 / var(--tw-text-opacity)) !important;
}

.disabled\:arh-text-black\/60:disabled{
  color: rgb(41 41 41 / 0.6);
}

.disabled\:arh-text-greyDisabledText:disabled{
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.disabled\:placeholder\:arh-text-greyDisabledText:disabled::placeholder{
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.arh-group:hover .group-hover\:arh-visible{
  visibility: visible;
}

.arh-group:hover .group-hover\:\!arh-block{
  display: block !important;
}

.arh-group:hover .group-hover\:-arh-translate-x-0\.5{
  --tw-translate-x: -0.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-group:hover .group-hover\:arh-translate-x-0\.5{
  --tw-translate-x: 0.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.arh-group:hover .group-hover\:arh-text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.arh-group\/outlined:hover .group-hover\/outlined\:arh-mix-blend-difference{
  mix-blend-mode: difference;
}

.arh-peer:hover ~ .peer-hover\:arh-visible{
  visibility: visible;
}

@media (min-width: 768px){
  .md\:arh-flex-row{
    flex-direction: row;
  }

  .md\:arh-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:arh-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:arh-px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:arh-text-left{
    text-align: left;
  }

  .md\:arh-text-\[16px\]{
    font-size: 16px;
  }
}

@media (min-width: 1024px){
  .lg\:arh-w-\[17\%\]{
    width: 17%;
  }

  .lg\:arh-w-\[23\%\]{
    width: 23%;
  }

  .lg\:arh-w-\[83\%\]{
    width: 83%;
  }

  .lg\:arh-w-fit{
    width: -moz-fit-content;
    width: fit-content;
  }

  .lg\:arh-w-\[15\.625rem\]{
    width: 15.625rem;
  }

  .lg\:arh-flex-row{
    flex-direction: row;
  }
}

@media (min-width: 1280px){
  .xl\:arh-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 1536px){
  .\32xl\:arh-pr-12{
    padding-right: 3rem;
  }
}

@media (max-width: 1920px){
  .desktop-base\:arh-absolute{
    position: absolute;
  }

  .desktop-base\:arh-right-\[3rem\]{
    right: 3rem;
  }

  .desktop-base\:arh-top-\[3rem\]{
    top: 3rem;
  }

  .desktop-base\:arh-mr-7{
    margin-right: 1.75rem;
  }

  .desktop-base\:arh-hidden{
    display: none;
  }

  .desktop-base\:arh-w-screen{
    width: 100vw;
  }

  .desktop-base\:arh-max-w-full{
    max-width: 100%;
  }

  .desktop-base\:arh-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
}

@media (max-width: 1680px){
  .\33xl\:arh-mb-4{
    margin-bottom: 1rem;
  }

  .\33xl\:arh-grid{
    display: grid;
  }

  .\33xl\:arh-w-\[120\%\]{
    width: 120%;
  }

  .\33xl\:arh-w-\[43\%\]{
    width: 43%;
  }

  .\33xl\:arh-w-\[48\%\]{
    width: 48%;
  }

  .\33xl\:arh-w-\[95\%\]{
    width: 95%;
  }

  .\33xl\:arh-w-full{
    width: 100%;
  }

  .\33xl\:arh-max-w-7xl{
    max-width: 80rem;
  }

  .\33xl\:arh-flex-none{
    flex: none;
  }

  .\33xl\:arh-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .\33xl\:arh-flex-col{
    flex-direction: column;
  }

  .\33xl\:arh-flex-wrap{
    flex-wrap: wrap;
  }

  .\33xl\:arh-items-start{
    align-items: flex-start;
  }

  .\33xl\:arh-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .\33xl\:arh-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\33xl\:arh-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .\33xl\:arh-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .\33xl\:arh-px-\[10\%\]{
    padding-left: 10%;
    padding-right: 10%;
  }

  .\33xl\:arh-px-\[35\%\]{
    padding-left: 35%;
    padding-right: 35%;
  }

  .\33xl\:arh-pl-\[1\.597rem\]{
    padding-left: 1.597rem;
  }

  .\33xl\:arh-text-\[1\.35rem\]{
    font-size: 1.35rem;
  }

  .\33xl\:arh-text-\[13px\]{
    font-size: 13px;
  }

  .\33xl\:arh-text-\[9px\]{
    font-size: 9px;
  }

  .\33xl\:arh-text-xs{
    font-size: .75rem;
  }
}

@media (max-width: 1440px){
  .desktop-l\:arh-right-\[1\.562rem\]{
    right: 1.562rem;
  }

  .desktop-l\:arh-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .desktop-l\:arh-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .desktop-l\:arh-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .desktop-l\:arh-my-\[1rem\]{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .desktop-l\:\!arh-mb-4{
    margin-bottom: 1rem !important;
  }

  .desktop-l\:arh-mb-0{
    margin-bottom: 0px;
  }

  .desktop-l\:arh-mb-2{
    margin-bottom: 0.5rem;
  }

  .desktop-l\:arh-mb-4{
    margin-bottom: 1rem;
  }

  .desktop-l\:arh-mb-\[1\.5rem\]{
    margin-bottom: 1.5rem;
  }

  .desktop-l\:arh-ml-\[8rem\]{
    margin-left: 8rem;
  }

  .desktop-l\:arh-mr-6{
    margin-right: 1.5rem;
  }

  .desktop-l\:arh-mt-\[0\.5rem\]{
    margin-top: 0.5rem;
  }

  .desktop-l\:arh-flex{
    display: flex;
  }

  .desktop-l\:\!arh-w-fit{
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .desktop-l\:arh-w-1\/2{
    width: 50%;
  }

  .desktop-l\:arh-w-2\/3{
    width: 66.666667%;
  }

  .desktop-l\:arh-w-56{
    width: 14rem;
  }

  .desktop-l\:arh-w-\[100\%\]{
    width: 100%;
  }

  .desktop-l\:arh-w-\[150\%\]{
    width: 150%;
  }

  .desktop-l\:arh-w-\[45\%\]{
    width: 45%;
  }

  .desktop-l\:arh-w-\[68\.27\%\]{
    width: 68.27%;
  }

  .desktop-l\:arh-w-full{
    width: 100%;
  }

  .desktop-l\:arh-flex-col{
    flex-direction: column;
  }

  .desktop-l\:arh-justify-end{
    justify-content: flex-end;
  }

  .desktop-l\:arh-gap-y-6{
    row-gap: 1.5rem;
  }

  .desktop-l\:arh-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .desktop-l\:arh-p-2{
    padding: 0.5rem;
  }

  .desktop-l\:\!arh-px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .desktop-l\:arh-px-\[25\%\]{
    padding-left: 25%;
    padding-right: 25%;
  }

  .desktop-l\:arh-px-\[3rem\]{
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .desktop-l\:arh-pl-\[2\.597rem\]{
    padding-left: 2.597rem;
  }

  .desktop-l\:arh-pr-5{
    padding-right: 1.25rem;
  }

  .desktop-l\:arh-pt-\[2\.597rem\]{
    padding-top: 2.597rem;
  }

  .desktop-l\:arh-text-4xl{
    font-size: 2.25rem;
  }

  .desktop-l\:arh-text-\[0\.813rem\]{
    font-size: 0.813rem;
  }

  .desktop-l\:arh-text-\[1\.175rem\]{
    font-size: 1.175rem;
  }

  .desktop-l\:arh-text-\[12px\]{
    font-size: 12px;
  }

  .desktop-l\:arh-text-\[2\.75rem\]{
    font-size: 2.75rem;
  }

  .desktop-l\:arh-text-\[20px\]{
    font-size: 20px;
  }

  .desktop-l\:arh-text-\[2rem\]{
    font-size: 2rem;
  }

  .desktop-l\:arh-text-xs{
    font-size: .75rem;
  }
}

@media (max-width: 1366px){
  .desktop-middle\:arh-col-auto{
    grid-column: auto;
  }

  .desktop-middle\:arh-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .desktop-middle\:arh-mb-5{
    margin-bottom: 1.25rem;
  }

  .desktop-middle\:arh-ml-2{
    margin-left: 0.5rem;
  }

  .desktop-middle\:arh-ml-3{
    margin-left: 0.75rem;
  }

  .desktop-middle\:arh-mr-3{
    margin-right: 0.75rem;
  }

  .desktop-middle\:arh-h-7{
    height: 1.75rem;
  }

  .desktop-middle\:arh-h-auto{
    height: auto;
  }

  .desktop-middle\:arh-w-10\/12{
    width: 83.333333%;
  }

  .desktop-middle\:arh-w-2\/5{
    width: 40%;
  }

  .desktop-middle\:arh-w-24{
    width: 6rem;
  }

  .desktop-middle\:arh-w-36{
    width: 9rem;
  }

  .desktop-middle\:arh-w-7{
    width: 1.75rem;
  }

  .desktop-middle\:arh-w-72{
    width: 18rem;
  }

  .desktop-middle\:arh-w-\[10\%\]{
    width: 10%;
  }

  .desktop-middle\:arh-w-\[12\%\]{
    width: 12%;
  }

  .desktop-middle\:arh-w-\[13\%\]{
    width: 13%;
  }

  .desktop-middle\:arh-w-\[15\%\]{
    width: 15%;
  }

  .desktop-middle\:arh-w-\[160\%\]{
    width: 160%;
  }

  .desktop-middle\:arh-w-\[17\%\]{
    width: 17%;
  }

  .desktop-middle\:arh-w-\[2\%\]{
    width: 2%;
  }

  .desktop-middle\:arh-w-\[20\%\]{
    width: 20%;
  }

  .desktop-middle\:arh-w-\[28\%\]{
    width: 28%;
  }

  .desktop-middle\:arh-w-\[8\%\]{
    width: 8%;
  }

  .desktop-middle\:arh-w-\[calc\(100vw-105px\)\]{
    width: calc(100vw - 105px);
  }

  .desktop-middle\:arh-w-full{
    width: 100%;
  }

  .desktop-middle\:arh-min-w-\[24\.25rem\]{
    min-width: 24.25rem;
  }

  .desktop-middle\:arh-max-w-\[50\%\]{
    max-width: 50%;
  }

  .desktop-middle\:arh-max-w-\[54px\]{
    max-width: 54px;
  }

  .desktop-middle\:arh-max-w-\[5rem\]{
    max-width: 5rem;
  }

  .desktop-middle\:arh-max-w-\[6\.25rem\]{
    max-width: 6.25rem;
  }

  .desktop-middle\:arh-max-w-\[8rem\]{
    max-width: 8rem;
  }

  .desktop-middle\:arh-max-w-full{
    max-width: 100%;
  }

  .desktop-middle\:arh-shrink-0{
    flex-shrink: 0;
  }

  .desktop-middle\:arh-basis-full{
    flex-basis: 100%;
  }

  .desktop-middle\:\!arh-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .desktop-middle\:arh-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .desktop-middle\:arh-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .desktop-middle\:arh-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .desktop-middle\:arh-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .desktop-middle\:arh-flex-col{
    flex-direction: column;
  }

  .desktop-middle\:arh-flex-col-reverse{
    flex-direction: column-reverse;
  }

  .desktop-middle\:arh-flex-nowrap{
    flex-wrap: nowrap;
  }

  .desktop-middle\:arh-items-start{
    align-items: flex-start;
  }

  .desktop-middle\:arh-items-center{
    align-items: center;
  }

  .desktop-middle\:arh-justify-between{
    justify-content: space-between;
  }

  .desktop-middle\:arh-gap-5{
    gap: 1.25rem;
  }

  .desktop-middle\:arh-gap-\[4\%\]{
    gap: 4%;
  }

  .desktop-middle\:arh-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .desktop-middle\:arh-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .desktop-middle\:arh-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .desktop-middle\:arh-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .desktop-middle\:arh-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .desktop-middle\:arh-px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .desktop-middle\:arh-px-\[1\.125rem\]{
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .desktop-middle\:arh-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .desktop-middle\:arh-pl-2{
    padding-left: 0.5rem;
  }

  .desktop-middle\:arh-pl-\[0\.597rem\]{
    padding-left: 0.597rem;
  }

  .desktop-middle\:arh-pr-12{
    padding-right: 3rem;
  }

  .desktop-middle\:arh-pr-20{
    padding-right: 5rem;
  }

  .desktop-middle\:arh-pr-9{
    padding-right: 2.25rem;
  }

  .desktop-middle\:arh-pt-2{
    padding-top: 0.5rem;
  }

  .desktop-middle\:arh-text-center{
    text-align: center;
  }

  .desktop-middle\:arh-text-\[1\.145rem\]{
    font-size: 1.145rem;
  }

  .desktop-middle\:arh-text-\[1\.5rem\]{
    font-size: 1.5rem;
  }

  .desktop-middle\:arh-text-\[10px\]{
    font-size: 10px;
  }

  .desktop-middle\:arh-text-\[11\.2px\]{
    font-size: 11.2px;
  }

  .desktop-middle\:arh-text-base{
    font-size: 1rem;
  }

  .desktop-middle\:arh-text-tiny{
    font-size: .875rem;
  }

  .desktop-middle\:arh-text-xs{
    font-size: .75rem;
  }
}

@media (max-width: 1300px){
  .desktop-little\:\!arh-mr-\[0\.5rem\]{
    margin-right: 0.5rem !important;
  }

  .desktop-little\:arh-h-\[70vh\]{
    height: 70vh;
  }
}

@media (max-height: 700px){
  .resp-height-700\:arh-mb-4{
    margin-bottom: 1rem;
  }

  .resp-height-700\:arh-gap-y-2{
    row-gap: 0.5rem;
  }

  .resp-height-700\:arh-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 1280px){
  .desktop-mini\:arh-bottom-\[3\.3rem\]{
    bottom: 3.3rem;
  }

  .desktop-mini\:arh-col-span-1{
    grid-column: span 1 / span 1;
  }

  .desktop-mini\:\!arh-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .desktop-mini\:arh-mx-0{
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop-mini\:arh-mx-\[2\%\]{
    margin-left: 2%;
    margin-right: 2%;
  }

  .desktop-mini\:arh-mb-2{
    margin-bottom: 0.5rem;
  }

  .desktop-mini\:arh-mb-4{
    margin-bottom: 1rem;
  }

  .desktop-mini\:arh-mb-\[1rem\]{
    margin-bottom: 1rem;
  }

  .desktop-mini\:arh-mb-\[2\.5rem\]{
    margin-bottom: 2.5rem;
  }

  .desktop-mini\:arh-mb-\[2rem\]{
    margin-bottom: 2rem;
  }

  .desktop-mini\:arh-ml-0{
    margin-left: 0px;
  }

  .desktop-mini\:arh-ml-4{
    margin-left: 1rem;
  }

  .desktop-mini\:arh-mr-0{
    margin-right: 0px;
  }

  .desktop-mini\:arh-mt-\[0\.3rem\]{
    margin-top: 0.3rem;
  }

  .desktop-mini\:arh-mt-\[0\.5rem\]{
    margin-top: 0.5rem;
  }

  .desktop-mini\:arh-mt-\[1rem\]{
    margin-top: 1rem;
  }

  .desktop-mini\:arh-block{
    display: block;
  }

  .desktop-mini\:arh-flex{
    display: flex;
  }

  .desktop-mini\:arh-hidden{
    display: none;
  }

  .desktop-mini\:arh-h-\[1\.948rem\]{
    height: 1.948rem;
  }

  .desktop-mini\:arh-h-\[20rem\]{
    height: 20rem;
  }

  .desktop-mini\:arh-h-auto{
    height: auto;
  }

  .desktop-mini\:arh-h-full{
    height: 100%;
  }

  .desktop-mini\:arh-max-h-\[20rem\]{
    max-height: 20rem;
  }

  .desktop-mini\:arh-min-h-0{
    min-height: 0px;
  }

  .desktop-mini\:\!arh-w-fit{
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .desktop-mini\:arh-w-\[1\.913rem\]{
    width: 1.913rem;
  }

  .desktop-mini\:arh-w-\[150\%\]{
    width: 150%;
  }

  .desktop-mini\:arh-w-\[160\%\]{
    width: 160%;
  }

  .desktop-mini\:arh-w-\[31rem\]{
    width: 31rem;
  }

  .desktop-mini\:arh-w-\[343px\]{
    width: 343px;
  }

  .desktop-mini\:arh-w-\[95\%\]{
    width: 95%;
  }

  .desktop-mini\:arh-w-\[96\%\]{
    width: 96%;
  }

  .desktop-mini\:arh-w-full{
    width: 100%;
  }

  .desktop-mini\:arh-min-w-0{
    min-width: 0px;
  }

  .desktop-mini\:arh-max-w-\[23rem\]{
    max-width: 23rem;
  }

  .desktop-mini\:arh-max-w-full{
    max-width: 100%;
  }

  .desktop-mini\:arh-flex-1{
    flex: 1 1 0%;
  }

  .desktop-mini\:arh-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .desktop-mini\:arh-flex-col{
    flex-direction: column;
  }

  .desktop-mini\:arh-flex-col-reverse{
    flex-direction: column-reverse;
  }

  .desktop-mini\:arh-flex-wrap{
    flex-wrap: wrap;
  }

  .desktop-mini\:arh-items-start{
    align-items: flex-start;
  }

  .desktop-mini\:arh-items-center{
    align-items: center;
  }

  .desktop-mini\:arh-justify-center{
    justify-content: center;
  }

  .desktop-mini\:arh-gap-y-6{
    row-gap: 1.5rem;
  }

  .desktop-mini\:arh-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .desktop-mini\:arh-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .desktop-mini\:arh-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .desktop-mini\:arh-overflow-hidden{
    overflow: hidden;
  }

  .desktop-mini\:arh-object-position-inscription-mobile{
    object-position: 25% 16%;
  }

  .desktop-mini\:arh-p-0{
    padding: 0px;
  }

  .desktop-mini\:arh-p-\[3\%\]{
    padding: 3%;
  }

  .desktop-mini\:arh-px-0{
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop-mini\:arh-px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .desktop-mini\:arh-px-\[3\%\]{
    padding-left: 3%;
    padding-right: 3%;
  }

  .desktop-mini\:arh-pt-0{
    padding-top: 0px;
  }

  .desktop-mini\:arh-pt-12{
    padding-top: 3rem;
  }

  .desktop-mini\:arh-pt-24{
    padding-top: 6rem;
  }

  .desktop-mini\:arh-pt-4{
    padding-top: 1rem;
  }

  .desktop-mini\:arh-pt-8{
    padding-top: 2rem;
  }

  .desktop-mini\:arh-text-\[\.70rem\]{
    font-size: .70rem;
  }

  .desktop-mini\:arh-text-\[0\.813rem\]{
    font-size: 0.813rem;
  }

  .desktop-mini\:arh-text-\[10px\]{
    font-size: 10px;
  }

  .desktop-mini\:arh-text-\[12px\]{
    font-size: 12px;
  }

  .desktop-mini\:arh-text-\[13px\]{
    font-size: 13px;
  }

  .desktop-mini\:arh-leading-\[18px\]{
    line-height: 18px;
  }

  .desktop-mini\:arh-leading-\[20px\]{
    line-height: 20px;
  }

  .desktop-mini\:arh-leading-\[50px\]{
    line-height: 50px;
  }
}

@media (max-width: 768px){
  .mobile-768\:arh-static{
    position: static;
  }

  .mobile-768\:arh-fixed{
    position: fixed;
  }

  .mobile-768\:-arh-left-\[0\.8rem\]{
    left: -0.8rem;
  }

  .mobile-768\:-arh-right-\[0\.8rem\]{
    right: -0.8rem;
  }

  .mobile-768\:arh-left-1\/2{
    left: 50%;
  }

  .mobile-768\:arh-left-16{
    left: 4rem;
  }

  .mobile-768\:arh-left-\[1\.1rem\]{
    left: 1.1rem;
  }

  .mobile-768\:arh-right-4{
    right: 1rem;
  }

  .mobile-768\:arh-right-\[0\.7rem\]{
    right: 0.7rem;
  }

  .mobile-768\:arh-top-4{
    top: 1rem;
  }

  .mobile-768\:arh-top-40{
    top: 10rem;
  }

  .mobile-768\:arh-top-6{
    top: 1.5rem;
  }

  .mobile-768\:arh-m-0{
    margin: 0px;
  }

  .mobile-768\:arh-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .mobile-768\:\!arh-ml-0{
    margin-left: 0px !important;
  }

  .mobile-768\:arh-mb-2{
    margin-bottom: 0.5rem;
  }

  .mobile-768\:arh-ml-0{
    margin-left: 0px;
  }

  .mobile-768\:arh-ml-\[10px\]{
    margin-left: 10px;
  }

  .mobile-768\:arh-mr-0{
    margin-right: 0px;
  }

  .mobile-768\:arh-mt-0{
    margin-top: 0px;
  }

  .mobile-768\:arh-mt-2{
    margin-top: 0.5rem;
  }

  .mobile-768\:arh-mt-4{
    margin-top: 1rem;
  }

  .mobile-768\:arh-mt-\[4\.5rem\]{
    margin-top: 4.5rem;
  }

  .mobile-768\:arh-block{
    display: block;
  }

  .mobile-768\:arh-inline-block{
    display: inline-block;
  }

  .mobile-768\:arh-flex{
    display: flex;
  }

  .mobile-768\:\!arh-hidden{
    display: none !important;
  }

  .mobile-768\:arh-hidden{
    display: none;
  }

  .mobile-768\:\!arh-size-3{
    width: 0.75rem !important;
    height: 0.75rem !important;
  }

  .mobile-768\:arh-size-7{
    width: 1.75rem;
    height: 1.75rem;
  }

  .mobile-768\:arh-h-60{
    height: 15rem;
  }

  .mobile-768\:arh-h-7{
    height: 1.75rem;
  }

  .mobile-768\:arh-h-8{
    height: 2rem;
  }

  .mobile-768\:arh-h-\[1\.1rem\]{
    height: 1.1rem;
  }

  .mobile-768\:arh-h-\[250px\]{
    height: 250px;
  }

  .mobile-768\:arh-h-full{
    height: 100%;
  }

  .mobile-768\:arh-h-screen{
    height: 100vh;
  }

  .mobile-768\:arh-max-h-\[calc\(100\%-15rem\)\]{
    max-height: calc(100% - 15rem);
  }

  .mobile-768\:\!arh-w-\[21rem\]{
    width: 21rem !important;
  }

  .mobile-768\:\!arh-w-full{
    width: 100% !important;
  }

  .mobile-768\:arh-w-16{
    width: 4rem;
  }

  .mobile-768\:arh-w-60{
    width: 15rem;
  }

  .mobile-768\:arh-w-7{
    width: 1.75rem;
  }

  .mobile-768\:arh-w-8{
    width: 2rem;
  }

  .mobile-768\:arh-w-\[1\.1rem\]{
    width: 1.1rem;
  }

  .mobile-768\:arh-w-\[12\%\]{
    width: 12%;
  }

  .mobile-768\:arh-w-\[29\.33\%\]{
    width: 29.33%;
  }

  .mobile-768\:arh-w-\[3\.5rem\]{
    width: 3.5rem;
  }

  .mobile-768\:arh-w-\[88\%\]{
    width: 88%;
  }

  .mobile-768\:arh-w-\[95\%\]{
    width: 95%;
  }

  .mobile-768\:arh-w-full{
    width: 100%;
  }

  .mobile-768\:arh-min-w-12{
    min-width: 3rem;
  }

  .mobile-768\:arh-min-w-24{
    min-width: 6rem;
  }

  .mobile-768\:arh-min-w-full{
    min-width: 100%;
  }

  .mobile-768\:\!arh-max-w-12{
    max-width: 3rem !important;
  }

  .mobile-768\:arh-max-w-3xl{
    max-width: 48rem;
  }

  .mobile-768\:arh-max-w-\[50\%\]{
    max-width: 50%;
  }

  .mobile-768\:arh-max-w-\[90\%\]{
    max-width: 90%;
  }

  .mobile-768\:arh-max-w-full{
    max-width: 100%;
  }

  .mobile-768\:arh-max-w-xs{
    max-width: 20rem;
  }

  .mobile-768\:-arh-translate-x-1\/2{
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .mobile-768\:arh-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .mobile-768\:arh-flex-row{
    flex-direction: row;
  }

  .mobile-768\:arh-flex-col{
    flex-direction: column;
  }

  .mobile-768\:arh-items-start{
    align-items: flex-start;
  }

  .mobile-768\:arh-justify-end{
    justify-content: flex-end;
  }

  .mobile-768\:arh-justify-center{
    justify-content: center;
  }

  .mobile-768\:arh-gap-4{
    gap: 1rem;
  }

  .mobile-768\:arh-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .mobile-768\:arh-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .mobile-768\:arh-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .mobile-768\:arh-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .mobile-768\:arh-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .mobile-768\:arh-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .mobile-768\:arh-overflow-y-auto{
    overflow-y: auto;
  }

  .mobile-768\:arh-overflow-x-hidden{
    overflow-x: hidden;
  }

  .mobile-768\:arh-border{
    border-width: 1px;
  }

  .mobile-768\:arh-border-solid{
    border-style: solid;
  }

  .mobile-768\:arh-border-\[\#F0F0F0\]{
    --tw-border-opacity: 1;
    border-color: rgb(240 240 240 / var(--tw-border-opacity));
  }

  .mobile-768\:\!arh-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  }

  .mobile-768\:arh-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .mobile-768\:arh-p-0{
    padding: 0px;
  }

  .mobile-768\:arh-p-2{
    padding: 0.5rem;
  }

  .mobile-768\:arh-p-4{
    padding: 1rem;
  }

  .mobile-768\:\!arh-px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .mobile-768\:\!arh-px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .mobile-768\:arh-px-0{
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-768\:arh-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .mobile-768\:arh-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .mobile-768\:arh-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .mobile-768\:arh-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .mobile-768\:arh-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .mobile-768\:\!arh-pr-0{
    padding-right: 0px !important;
  }

  .mobile-768\:arh-pb-2{
    padding-bottom: 0.5rem;
  }

  .mobile-768\:arh-pl-0\.5{
    padding-left: 0.125rem;
  }

  .mobile-768\:arh-pl-4{
    padding-left: 1rem;
  }

  .mobile-768\:arh-pt-0{
    padding-top: 0px;
  }

  .mobile-768\:arh-text-left{
    text-align: left;
  }

  .mobile-768\:arh-text-center{
    text-align: center;
  }

  .mobile-768\:arh-text-2xl{
    font-size: 1.5rem;
  }

  .mobile-768\:arh-text-2xs{
    font-size: 0.625rem;
  }

  .mobile-768\:arh-text-3xl{
    font-size: 1.875rem;
  }

  .mobile-768\:arh-text-\[8px\]{
    font-size: 8px;
  }

  .mobile-768\:arh-text-base{
    font-size: 1rem;
  }

  .mobile-768\:arh-text-sm{
    font-size: .875rem;
  }

  .mobile-768\:arh-text-xl{
    font-size: 1.25rem;
  }

  .mobile-768\:arh-text-xs{
    font-size: .75rem;
  }

  .mobile-768\:arh-font-medium{
    font-weight: 500;
  }

  .mobile-768\:arh-shadow-none{
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (max-width: 500px){
  .mobile-500\:\!arh-size-2\.5{
    width: 0.625rem !important;
    height: 0.625rem !important;
  }

  .mobile-500\:arh-size-3{
    width: 0.75rem;
    height: 0.75rem;
  }

  .mobile-500\:arh-size-9{
    width: 2.25rem;
    height: 2.25rem;
  }

  .mobile-500\:arh-h-\[12rem\]{
    height: 12rem;
  }

  .mobile-500\:arh-w-\[150\%\]{
    width: 150%;
  }

  .mobile-500\:arh-w-\[2\.5rem\]{
    width: 2.5rem;
  }

  .mobile-500\:arh-max-w-\[14rem\]{
    max-width: 14rem;
  }

  .mobile-500\:arh-max-w-\[30\%\]{
    max-width: 30%;
  }

  .mobile-500\:arh-max-w-\[50\%\]{
    max-width: 50%;
  }

  .mobile-500\:arh-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mobile-500\:arh-text-\[9px\]{
    font-size: 9px;
  }
}

@media (max-width: 390px){
  .mobile-390\:\!arh-mt-\[8\.5rem\]{
    margin-top: 8.5rem !important;
  }

  .mobile-390\:arh-ml-0{
    margin-left: 0px;
  }

  .mobile-390\:\!arh-size-2{
    width: 0.5rem !important;
    height: 0.5rem !important;
  }

  .mobile-390\:arh-size-4{
    width: 1rem;
    height: 1rem;
  }

  .mobile-390\:arh-size-7{
    width: 1.75rem;
    height: 1.75rem;
  }

  .mobile-390\:\!arh-w-\[18rem\]{
    width: 18rem !important;
  }

  .mobile-390\:arh-w-\[2\.275rem\]{
    width: 2.275rem;
  }

  .mobile-390\:arh-w-full{
    width: 100%;
  }

  .mobile-390\:arh-max-w-\[10rem\]{
    max-width: 10rem;
  }

  .mobile-390\:arh-max-w-\[50\%\]{
    max-width: 50%;
  }

  .mobile-390\:arh-flex-col{
    flex-direction: column;
  }

  .mobile-390\:arh-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .mobile-390\:arh-text-\[7px\]{
    font-size: 7px;
  }
}

@media (min-height: 1024px){
  .custom-lg\:\!arh-mb-\[1\.938rem\]{
    margin-bottom: 1.938rem !important;
  }

  .custom-lg\:\!arh-mt-16{
    margin-top: 4rem !important;
  }

  .custom-lg\:arh-mb-\[2\.75rem\]{
    margin-bottom: 2.75rem;
  }

  .custom-lg\:arh-h-24{
    height: 6rem;
  }

  .custom-lg\:arh-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .custom-lg\:arh-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .custom-lg\:arh-pt-16{
    padding-top: 4rem;
  }

  @media (max-width: 1280px){
    .custom-lg\:desktop-mini\:\!arh-mb-1{
      margin-bottom: 0.25rem !important;
    }

    .custom-lg\:desktop-mini\:\!arh-mt-0{
      margin-top: 0px !important;
    }

    .custom-lg\:desktop-mini\:arh-space-y-0 > :not([hidden]) ~ :not([hidden]){
      --tw-space-y-reverse: 0;
      margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .custom-lg\:desktop-mini\:arh-pt-24{
      padding-top: 6rem;
    }
  }
}

@media (max-width: 810px){
  .ipad\:arh-mt-20{
    margin-top: 5rem;
  }

  .ipad\:arh-w-\[22rem\]{
    width: 22rem;
  }

  .ipad\:arh-flex-col{
    flex-direction: column;
  }

  .ipad\:\!arh-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }
}

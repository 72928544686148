* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  scrollbar-width: thin !important;
}
::-webkit-scrollbar:hover {
  width: 5px;
  height: 5px;
  scrollbar-width: thin !important;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 99px;
  background: #f2f3f7;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 99px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(192, 192, 192, 0.44);
}
#app {
  min-height: 100vh;
}
.d-flex-perso {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.center_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-inline {
  display: inline !important;
}
.d-block {
  display: block !important;
}
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}
.w-30 {
  width: 30% !important;
}
.w-38 {
  width: 38% !important;
}
.w-50 {
  width: 50% !important;
}
.w-42 {
  width: 42% !important;
}
.w-45 {
  width: 45% !important;
}
.w-65 {
  width: 65% !important;
}
.w-75 {
  width: 75% !important;
}
.w-22 {
  width: 22%;
}
.w-20 {
  width: 20%;
}
.w-28 {
  width: 28% !important;
}
.h-100 {
  height: 100%;
}
.h-20 {
  height: 20px !important;
}
.h-3 {
  height: 3rem;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.z-7 {
  z-index: 7;
}
.z-8 {
  z-index: 8;
}
.z-9 {
  z-index: 9;
}
.taux_desktop {
  font-size: 13px;
  color: var(--textClock);
}
.taux_mobile {
  display: none;
}
.uppercase {
  text-transform: uppercase;
}
.bg-gradient-warning {
  background: linear-gradient(87deg, var(--secondColor) 0, var(--secondColor) 100%) !important;
}
.custom-table {
  width: 240px !important;
}
.mt_5_custom {
  margin-top: 5px !important;
}
.page-link {
  background-color: var(--secondColor) !important;
  border-color: var(--secondColor) !important;
}
.bg-orange {
  background-color: var(--secondColor) !important;
}
.text-warning {
  color: var(--secondColor) !important;
}
.b-grey {
  border: 1px solid #D0D2D7 !important;
}
.p-0 {
  padding: 0 !important;
}
.clear {
  opacity: 0;
}
.globals {
  height: 43px !important;
  width: 230px !important;
  margin-bottom: 27px !important;
}
.mi-globals {
  height: 43px !important;
  width: 112px !important;
  margin-bottom: 27px !important;
}
.mr-perso {
  margin-right: 5px !important;
}
.mr_4 {
  margin-right: 4rem !important;
}
.ml-20 {
  margin-left: 20px;
}
.list {
  height: 40px;
  align-items: center;
  background-color: var(--greyColor);
  margin-bottom: 6px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}
.content_list_select_assignation {
  height: 50px;
  align-items: center;
  background-color: var(--greyColor);
  margin-bottom: 6px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.content_list_select_assignation_bg_white {
  height: 50px;
  align-items: center;
  background-color: var(--whiteColor);
  margin-bottom: 6px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.flex-perso {
  margin-top: 40px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.default {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  color: var(--darkColor) !important;
}
.custom_radio {
  background-color: var(--whiteColor);
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin: 12px 19px 11px 14px;
  width: 20px;
  height: 20px;
  /* checkbox aspect */
  /* checked mark aspect */
  /** checkbox checked **/
}
.custom_radio:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  background: transparent;
  border-radius: 50%;
}
.custom_radio:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--secondColor);
  transition: all 0.2s;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.custom_radio.active {
  border: 1px solid var(--secondColor);
}
.custom_radio.active:before {
  border: 0;
}
.custom_radio.active:after {
  /** show checked aspect **/
  opacity: 1;
}
.custom_radio_content .custom_radio_assignation_parent {
  background-color: var(--whiteColor);
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin: 12px 19px 5px 14px;
  width: 24px;
  height: 24px;
  /* checked mark aspect */
  /** checkbox checked **/
}
.custom_radio_content .custom_radio_assignation_parent:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--secondColor);
  transition: all 0.2s;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.custom_radio_content .custom_radio_assignation_parent.active {
  border: 1px solid var(--secondColor);
}
.custom_radio_content .custom_radio_assignation_parent.active:before {
  border: 0;
}
.custom_radio_content .custom_radio_assignation_parent.active:after {
  /** show checked aspect **/
  opacity: 1;
}
.custom_radio_content .custom_radio_assignation_child {
  background-color: var(--whiteColor);
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin: 12px 19px 5px 14px;
  width: 22px;
  height: 22px;
  /* checked mark aspect */
  /** checkbox checked **/
}
.custom_radio_content .custom_radio_assignation_child:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--secondColor);
  transition: all 0.2s;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.custom_radio_content .custom_radio_assignation_child.active {
  border: 1px solid var(--secondColor);
}
.custom_radio_content .custom_radio_assignation_child.active:before {
  border: 0;
}
.custom_radio_content .custom_radio_assignation_child.active:after {
  /** show checked aspect **/
  opacity: 1;
}
.custom_radio_content .custom_radio_task {
  background-color: var(--whiteColor);
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin: 12px 19px 11px 14px;
  width: 18px;
  height: 18px;
  /* checked mark aspect */
  /** checkbox checked **/
}
.custom_radio_content .custom_radio_task:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 8px;
  background-color: var(--secondColor);
  transition: all 0.2s;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.custom_radio_content .custom_radio_task.active {
  border: 1px solid var(--secondColor);
}
.custom_radio_content .custom_radio_task.active:before {
  border: 0;
}
.custom_radio_content .custom_radio_task.active:after {
  /** show checked aspect **/
  opacity: 1;
}
.custom_radio_parcours {
  background-color: var(--whiteColor);
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin: 12px 2px 11px 14px;
  width: 20px;
  height: 20px;
  /* checkbox aspect */
  /* checked mark aspect */
  /** checkbox checked **/
}
.custom_radio_parcours:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  background: transparent;
  border-radius: 50%;
}
.custom_radio_parcours:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--loaderGreen);
  transition: all 0.2s;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.custom_radio_parcours.active {
  border: 1px solid var(--loaderGreen);
}
.custom_radio_parcours.active:before {
  border: 0;
}
.custom_radio_parcours.active:after {
  /** show checked aspect **/
  opacity: 1;
}
.custom_width {
  width: 30%;
  height: 45px;
  margin-bottom: 4rem;
}
.margin_adresse_fiche {
  margin-top: 1rem;
}
.groupes_drop_employer .dropdown_select {
  top: 4.9rem;
}
.dropdown_select .list {
  margin: 0 0 6px;
}
.label_pays_bottom {
  margin-bottom: 4% !important;
}
.margin_bottom_permise {
  margin-top: 5px;
}
.content_input_simple_employer {
  margin-top: 0.3rem;
}
.content_input_simple_employer .input_simple {
  margin-top: 1.6rem;
}
.padding_section_emploi {
  padding: 0 3.313rem 0.3rem;
}
.padding_section_emploi .calendar_icon svg path,
.padding_section_emploi .calendar_icon svg rect {
  fill: var(--darkColor);
}
.margin_salire {
  margin: 1.125rem 0 1.625rem;
}
.employerSalary_Mobile {
  display: none;
}
.input_simple_custom {
  width: 80% !important;
  padding: 9px 15px !important;
  border: 1px solid var(--borderColor) !important;
  border-radius: 2px !important;
  color: var(--textSmallGrey) !important;
  font-size: 13px !important;
}
.custom_image {
  height: 5px !important;
  width: 9px !important;
}
.custom-width-inputGroup {
  width: 95% !important;
}
.mb_bottom {
  margin-bottom: 10px;
}
.bg_blue {
  background-color: var(--greyColor) !important;
}
.custom-group {
  height: 2.563rem;
  width: 2.875rem;
  top: 1px;
  right: 1px;
  line-height: 2;
  text-align: center;
  margin-left: -2.25rem;
  cursor: pointer;
}
.custom-group-error {
  height: 2.5rem;
  width: 5.5rem;
  top: 1px;
  right: 3px;
  line-height: 2;
  text-align: center;
  margin-left: -2.25rem;
  cursor: pointer;
}
.custom-group-error .dropdown_select_devise {
  width: 86px;
}
.margin_globals {
  margin: 3rem 0.438rem 0.438rem !important;
}
.margin_globals_importation {
  margin: 1rem 0.638rem 0.538rem !important;
}
.custom_image_profile {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}
.__react_component_tooltip.show {
  width: 8.563rem;
  opacity: 50;
  margin-top: 0;
  margin-left: 0;
  visibility: visible;
  text-align: center;
  padding: 0.5rem 1.063rem;
  background-color: #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  font-family: "Poppins_Medium";
}
.object_fit_cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
.position_absolute {
  position: absolute !important;
}
.position_relative {
  position: relative !important;
}
.switchEmployer {
  height: 45px;
}
.switchEmployer .MuiFormGroup-root {
  height: 100%;
  align-items: flex-end;
}
.switchEmployer .content-custom-switch-renewal-day {
  margin-top: 15px;
}
.MuiFormControl-root label {
  margin: 0;
}
.MuiFormGroup-root {
  height: 100%;
}
.custom_switch {
  font-family: "Poppins_Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  color: var(--titleTable);
  margin-right: 15px;
}
.regular_fonts_dropdown {
  font-family: 'PoppinsSemibold';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #444444;
}
.regular_fonts {
  font-size: 12px !important;
  color: var(--colorListDropdown);
}
.regular_fonts_assignation {
  font-size: 12px !important;
  color: #393C40 !important;
  line-height: 18px;
  text-transform: capitalize;
}
.medium_fonts {
  font-size: 11px !important;
  color: #393C40 !important;
  line-height: 16px;
  text-transform: capitalize;
}
.result {
  text-align: center;
  color: var(--textGreyColor);
}
.nohours_fontsize {
  font-size: 1rem !important;
}
.width_drop_employer .dropdown_select_pays {
  width: 100%;
}
.drop_ficher_pays .dropdown_select_pays {
  top: 5rem;
}
.items_filter {
  position: relative;
}
.custom_width {
  position: relative;
}
.fade:not(.show) {
  pointer-events: none;
}
.fade {
  transition: none !important;
}
.show {
  display: block;
}
.list_child {
  height: 35px;
  align-items: center;
  cursor: pointer;
}
.dropdown_content {
  width: 18%;
  position: relative;
}
.dropdown_content .list_content_input .input_with_loupe .input_loupe {
  border-radius: 0.625rem !important;
}
.dropdown_content_vacation {
  width: 15.625rem;
  position: relative;
}
.dropdown_content_vacation .list_content_input .input_with_loupe .input_loupe {
  border-radius: 0.625rem !important;
}
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop {
  width: 24.313rem;
  z-index: 10;
  box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 9px;
}
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop .rdrCalendarWrapper .rdrDateDisplayWrapper {
  display: none;
}
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop .rdrCalendarWrapper .rdrMonthAndYearWrapper {
  padding: 0;
  background-color: var(--greyColor);
}
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop .rdrCalendarWrapper .rdrMonthAndYearWrapper {
  height: 50px;
  background-color: var(--greyColor);
  border-radius: 9px;
}
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton,
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextButton {
  background-color: var(--greyColor);
}
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
  justify-content: center;
}
.dropdown_content_vacation .dropdown_hoursWorking .container_date_range_drop .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers select {
  color: var(--darkColor) !important;
  padding-bottom: 12px;
}
.bouton_appliquer {
  width: 12.625rem;
}
.bouton_appliquer .altee_btn {
  min-width: auto;
  width: 100%;
}
.bouton_appliquer .altee_btn--default-outlined {
  border: 1px solid var(--firstColor);
  background: transparent;
  border-radius: 2px;
  color: var(--firstColor);
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.bouton_appliquer .altee_btn--default-outlined:hover {
  background-color: #f8e9e5;
}
.bouton_appliquer .altee_btn--default-outlined svg path {
  stroke: var(--firstColor);
}
.btn-apply {
  width: 100%;
  padding: 0.769rem 0.786rem;
  border: none;
  color: var(--whiteColor);
  border-radius: 0.188rem;
  background-color: var(--firstColor);
  font-size: 0.813rem;
  cursor: pointer;
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.btn-apply:hover {
  background-color: var(--secondColor);
}
.btn_apply_grey {
  width: 100%;
  padding: 0.769rem 0.786rem;
  border: none;
  color: var(--whiteColor);
  border-radius: 3px;
  background-color: #e0e1e1;
  font-size: 13px;
  cursor: pointer;
  letter-spacing: 0.02rem;
}
.btn_apply_greyVacance {
  width: 100%;
  padding: 0.769rem 1.786rem;
  border: none;
  color: var(--whiteColor);
  border-radius: 3px;
  background-color: #c4d0d7;
  font-size: 13px;
  cursor: pointer;
}
.error {
  border: solid 1px var(--redNotif) !important;
}
.input-error {
  border-color: red !important;
}
.MuiFormControl-marginNormal {
  margin-top: 0 !important;
  padding: 3px 0 0 15px !important;
  height: 2.3rem;
  border: solid 1px var(--borderLoginColor);
  border-radius: 2px !important;
}
.MuiFormControl-marginNormal .calendarIconCustom {
  margin-top: -3px;
  margin-right: 10px;
  color: var(--textClock);
}
.MuiFormControl-marginNormal .calendarIconError {
  margin-top: -3px;
  margin-right: 10px;
  color: var(--redNotif);
}
.input_search_drop {
  border-radius: 2px !important;
}
.img_dropdown {
  margin-right: 21px;
}
.view_all_comment {
  cursor: pointer;
}
.name_employe_on_dropdown {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--colorListDropdown);
  font-size: 13px;
  width: 65%;
}
.caret_right {
  width: 30px;
  display: flex;
  justify-content: center;
}
.rotate {
  transform: rotate(90deg);
}
.padding_0 {
  padding: 0 !important;
}
.global_padding {
  padding: 1.125rem 3.313rem 0;
}
.txt_salaire {
  padding: 1.125rem 3.313rem 3.3rem;
  -moz-column-gap: 83px;
  column-gap: 83px;
}
.txt_salaire button {
  width: 13.313rem;
  height: 2.875rem;
}
.name_sous_group {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name_group {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 65%;
}
.filter-btn,
.toolbar-btn,
.filter-add {
  cursor: pointer;
}
.day-head {
  margin: 2rem 0;
}
.item-day {
  width: 32%;
  height: 100%;
  background-color: var(--greyColor);
  align-items: center;
  padding: 5px 10px;
}
.item-day .content_img {
  margin-right: 5%;
}
.item-day .content_txt {
  width: 100%;
  font-size: 15px;
  color: var(--darkColor);
}
.item-day .txt_rest {
  font-size: 11px;
  color: var(--darkColor);
}
.item-day .rest_number {
  font-size: 19px;
  color: var(--darkColor);
}
.item-day .content_total {
  border-left: solid #e9e9e9 1px;
  padding-left: 10px;
}
.dots_statut_drop {
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  bottom: 13px;
  right: 5px;
}
.content_custom_radio {
  display: flex;
  align-items: center;
}
.dropdown_content_vacation_horaire {
  width: 23%;
  position: relative;
}
.content_comment {
  display: flex;
  width: 140px;
  justify-content: space-around;
}
.content_comment .txt_comment {
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: left;
  margin-top: 4px;
}
.content_statut_horaire {
  position: relative;
  display: flex;
  align-items: center;
}
.dots_statut_pos_rel {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.status_green {
  background-color: #92e98f;
}
.status_yellow {
  background-color: #f5d954;
}
.status_grey {
  background-color: #b0bec5;
}
.status_blue {
  background-color: #609cd3;
}
.status_archived {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}
.status_red {
  background-color: #f67c53;
}
.status_purple {
  background-color: #B494FD;
}
.h-100 {
  height: 100% !important;
}
.red {
  color: var(--redNotif) !important;
}
.border_color_red {
  border-color: var(--redNotif) !important;
}
.comment_header {
  margin-right: inherit !important;
  margin-left: 0 !important;
  position: relative;
}
.txt_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
}
.txt_header .ajustday_title {
  margin: 0.563rem 0;
  font-size: 1.125rem;
}
.custom_top_close_modal {
  top: -30px;
}
.comment_body {
  border: 1px solid #ececec;
  padding: 1.5rem;
}
.comment_body .comment_para {
  font-family: PoppinsSemibold;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-size: 13px;
  text-align: justify;
  margin-top: 1rem;
  overflow-wrap: anywhere;
}
.gray_color {
  color: var(--titleTable);
}
.black_color {
  color: black !important;
}
.noBorder {
  border: none !important;
}
.fa-comment-dots,
.content_icon_edit {
  cursor: pointer;
}
.comment_send {
  margin-top: 2rem;
}
.absence {
  margin-top: 1rem;
}
button,
.btnRecurrence {
  cursor: pointer;
}
.dropdown_child {
  width: 100%;
  padding-inline-start: 40px;
}
.dropdown_child .list {
  background: none !important;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.add_link {
  margin-right: 0 !important;
}
.add_link img {
  margin-right: 0 !important;
}
.txt_delete {
  height: 178px;
  display: flex;
  align-items: center;
  padding-top: 2rem;
}
.txt_delete p {
  font-size: 27px;
  text-align: center;
}
.input_name_sous_group {
  margin-top: 1rem;
}
.input_name_sous_group .form-control {
  width: 100%;
  border: 1px solid var(--borderColor);
  font-size: 0.813rem;
}
.input_name_sous_group .form-control:focus {
  color: var(--darkColor);
}
.input_name_sous_group .icones_delete_sousgroupe {
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--darkColor) !important;
}
.padding_left_1rem {
  padding-left: 1rem;
}
.paddin_top {
  padding: 2rem 0;
}
.loading_spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.loading_spinner img {
  width: 65px;
}
.employerSalaryMobile .loading_spinner {
  top: 55%;
  left: 55%;
}
.loading_spinner_ajout img {
  width: 65px;
}
.loading_spinner_hours {
  width: 100%;
  height: 50vh;
}
.loading_spinner_hours img {
  width: 65px;
}
.content_liste {
  height: 520px;
  overflow: auto;
}
.content_liste::-webkit-scrollbar {
  display: none;
}
.content_liste .table-responsive {
  height: 51vh;
}
.content_liste .table-responsive .thead-light {
  border-bottom: 1px solid var(--borderColor);
}
.Toastify .Toastify__toast-container {
  max-width: 41.5rem;
  width: 100%;
}
.Toastify .Toastify__toast-container .Toastify__toast--info {
  border: 1px solid #1170ff;
  background: #d8f0f5;
}
.Toastify .Toastify__toast-container .Toastify__progress-bar {
  background: #1170ff;
  border-radius: 0.375rem;
  height: 0.5rem;
}
.Toastify .Toastify__toast-container .Toastify__toast-body {
  width: 100%;
}
.Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info {
  color: #1170ff;
}
.Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info .info-img {
  margin-bottom: 1.125rem;
}
.Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info img {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
}
.Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info p {
  font-family: "PoppinsSemiBold", sans-serif;
  font-size: 0.938rem;
}
.recruitments .Toastify .Toastify__toast-container {
  max-width: 37rem;
  width: 100%;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast--info {
  border: 1px solid #45bf55;
  border-radius: 6px;
  background: #d8f5dc;
  color: #328b3e;
  font-family: PoppinsSemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  padding: 20px 38px;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__progress-bar {
  background: #45bf55;
  border-radius: 0.375rem;
  height: 0.5rem;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast-body {
  width: 100%;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info {
  color: #1170ff;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info .info-img {
  margin-bottom: 1.125rem;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info img {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info p {
  font-family: "PoppinsSemiBold", sans-serif;
  font-size: 0.938rem;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast-body .info-img {
  margin-left: 16px;
}
.recruitments .Toastify .Toastify__toast-container .Toastify__toast-body p {
  margin-bottom: 0;
}
.calloutMessage--standard .Toastify .Toastify__toast-container {
  width: 700px;
  height: 77px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast--info {
  border: transparent;
  background: transparent;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__progress-bar {
  background: transparent;
  border-radius: 0.375rem;
  height: 0.5rem;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body {
  width: 100%;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-error {
  width: 587px;
  height: 77px;
  border: 1px solid #cc3638;
  border-radius: 6px;
  background-color: #fdeae8;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-error .border-right-error {
  background-color: #ff7868;
  width: 7px;
  height: 100%;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-error .content-notification {
  margin: 26px;
  width: 526px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-error .content-notification .content-message {
  width: 100%;
  color: #f60a20;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-error .content-notification .content-message .content-between {
  width: 90%;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-error .content-notification .content-message .content-between .custom-message-normale {
  font-size: 15px;
  margin-top: 5px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-error .content-notification .content-message .content-between .custom-message-too-long {
  font-size: 15px;
  margin-top: -5px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-success {
  width: 587px;
  height: 77px;
  border: 1px solid transparent;
  border-radius: 6px;
  border: 1px solid #45bf55;
  background-color: #d8f5dc;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-success .border-right-success {
  background-color: #45bf55;
  width: 7px;
  height: 100%;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-success .content-notification {
  margin: 26px;
  width: 526px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-success .content-notification .content-message {
  width: 100%;
  color: #427059;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-success .content-notification .content-message .content-between {
  width: 90%;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-success .content-notification .content-message .content-between .custom-message-normale {
  font-size: 15px;
  margin-top: 5px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-success .content-notification .content-message .content-between .custom-message-too-long {
  font-size: 15px;
  margin-top: -5px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-info {
  width: 587px;
  height: 77px;
  border: 1px solid transparent;
  border-radius: 6px;
  border: 1px solid #1170ff;
  background-color: #d8f0f5;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-info .border-right-info {
  background-color: #1170ff;
  width: 7px;
  height: 100%;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-info .content-notification {
  margin: 26px;
  width: 526px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-info .content-notification .content-message {
  width: 100%;
  color: #1d4e98;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-info .content-notification .content-message .content-between {
  width: 90%;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-info .content-notification .content-message .content-between .custom-message-normale {
  font-size: 15px;
  margin-top: 5px;
}
.calloutMessage--standard .Toastify .Toastify__toast-container .Toastify__toast-body .content-standard-notification-info .content-notification .content-message .content-between .custom-message-too-long {
  font-size: 15px;
  margin-top: -5px;
}
.calloutMessage--standard .Toastify .Toastify__toast {
  box-shadow: none !important;
}
.calloutMessage--standard .Toastify__close-button {
  display: none;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container {
  max-width: 28.5rem;
  width: 100%;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast--info {
  border: 1px solid #45bf55;
  border-left: 7px solid #45bf55;
  border-radius: 6px;
  background: #d8f5dc;
  color: #328b3e;
  font-family: PoppinsSemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 4.375rem;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__progress-bar {
  background: #45bf55;
  border-radius: 0.375rem;
  height: 0.5rem;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast-body {
  width: 100%;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info {
  color: #1170ff;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info .info-img {
  margin-bottom: 1.125rem;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info img {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info p {
  font-family: "PoppinsSemiBold", sans-serif;
  font-size: 0.938rem;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast-body .info-img {
  margin-left: 16px;
}
.calloutMessage--pointage .Toastify .Toastify__toast-container .Toastify__toast-body p {
  margin-bottom: 0;
}
.calloutMessage--pointage .Toastify__close-button {
  display: none;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container {
  max-width: 37rem;
  width: 100%;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast--info {
  border: 1px solid #1170ff;
  border-radius: 6px;
  background: #d8f0f5;
  color: #1d4e98;
  font-family: PoppinsSemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  padding: 25px 38px;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__progress-bar {
  background: #45bf55;
  border-radius: 0.375rem;
  height: 0.5rem;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body {
  width: 100%;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info {
  color: #1170ff;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info .info-img {
  margin-bottom: 1.125rem;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info img {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info p {
  font-family: "PoppinsSemiBold", sans-serif;
  font-size: 0.938rem;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .info-img {
  margin-left: 16px;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .Toastify__toast--info {
  border: 1px solid #1170ff;
  background: #d8f0f5;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .Toastify__progress-bar {
  background: #1170ff;
  border-radius: 0.375rem;
  height: 0.5rem;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .Toastify__toast-body {
  width: 100%;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .Toastify__toast-body .content-toatr-info {
  color: #1170ff;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .Toastify__toast-body .content-toatr-info .info-img {
  margin-bottom: 1.125rem;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .Toastify__toast-body .content-toatr-info img {
  width: 1.75rem;
  height: 1.75rem;
  -o-object-fit: contain;
  object-fit: contain;
}
.recruitments_rejected_candidate .Toastify .Toastify__toast-container .Toastify__toast-body .Toastify__toast-body .content-toatr-info p {
  font-family: "Poppins_SemiBold", sans-serif;
  font-size: 0.938rem;
}
.recruitments_rejected_candidate .Toastify .opacity_0 {
  opacity: 0;
}
.recruitments_rejected_candidate .Toastify .Toastify__close-button {
  display: none;
}
div.rendered-react-keyed-file-browser div.files table td {
  font-family: "Poppins_Medium";
  color: var(--textCollapseGrey);
}
@media only screen and (max-width: 1600px) {
  .item-day .content_txt {
    font-size: 12.5px;
  }
  .item-day .txt_rest {
    font-size: 9px;
  }
  .item-day .rest_number {
    font-size: 15px;
  }
}
.icon_comment {
  cursor: pointer;
}
.cursor_pointer {
  cursor: pointer !important;
}
@media only screen and (max-width: 1919px) {
  .dropdown_select {
    top: 60px;
  }
  .dropdown_select_tab4 {
    top: 50px;
  }
}
@media only screen and (max-width: 1300px) {
  .dropdown_select {
    top: 50px;
  }
  .dropdown_select_tab4 {
    top: 40px;
  }
}
/* 
  **** Custom Modal ****
 */
.modal-dialog.alt_modal_container {
  max-width: 36.125rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.modal-dialog.alt_modal_container .modal-content {
  border-radius: 10px;
  padding: 0;
}
.modal-dialog.alt_modal_container .modalContent p {
  color: black;
  text-align: center;
}
.modal-dialog.alt_modal_container .modal-body {
  padding: 3.4rem;
}
.modal-dialog.alt_modal_container .no_padding_top.modal-body {
  padding-top: 0;
}
.modal-dialog.alt_modal_container .modal-header {
  margin: 0;
}
.modal-dialog.alt_modal_container .modal-header .close > span:not(.sr-only) {
  font-size: 32px;
  color: #5a5a5a;
}
.modal-dialog.alt_modal_container .content_modals_notes {
  padding: 2.5rem;
}
.modal-dialog.alt_modal_container .btn_footer_actions_modals {
  padding: 0;
  border-top: 0;
}
.modal-dialog.alt_modal_container .modal-title {
  color: var(--darkColor);
  font-family: Poppins_SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 100%;
}
.modal-dialog.alt_modal_container--radius .modal-content {
  border-radius: 8px;
}
.modal-dialog.alt_modal_container--radius .modal-content .modal-body .header {
  width: 100%;
  text-align: center;
}
.modal-dialog.alt_modal_container--radius .modal-content .modal-body .header > h1 {
  flex: 1;
  color: var(--darkColor);
  font-family: Poppins_SemiBold, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  margin-bottom: 0;
}
.modal-dialog.alt_modal_container--radius .modal-content .modal-body .header_icon {
  cursor: pointer;
}
.modal-dialog.alt_modal_container--radius .modal-content .modal-body .content {
  margin: 74px 0 50px;
  padding: 0 70px;
}
.modal-dialog.alt_modal_container--radius .modal-content .modal-body .footer {
  background-color: transparent;
  margin-top: 50px;
  padding: 0 70px;
}
.modal-dialog.alt_modal_container--radius .modal-content .modal-body .footer > button {
  width: 100%;
}
.modal-dialog.alt_modal_container .modal_square {
  padding: 3.75rem 4.688rem 4.688rem;
}
.modal-dialog.alt_modal_container .modal_square .modal_header {
  margin-bottom: 8.75rem;
}
.modal-dialog.alt_modal_container .modal_square .modal_header .modal_title {
  color: var(--darkColor);
  font-size: 23px;
  line-height: 35px;
  letter-spacing: 0;
  text-align: center;
}
.modal-dialog.alt_modal_container .modal_square .modal_header > span {
  cursor: pointer;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.modal-dialog.alt_modal_container .modal_square .modalFooterC .btn_footer_actions_modals {
  border-top: none;
}
.modal-dialog.alt_modal_container--rounded .modal-content {
  position: relative;
  border-radius: 8px;
}
.modal-dialog.alt_modal_container--rounded .modal-header .modal-title {
  margin-bottom: 10px;
}
.modal-dialog.alt_modal_container--rounded .modal-header .close {
  position: absolute;
  top: 35px;
  right: 40px;
  color: #5a5a5a;
  font-size: 30px;
  opacity: 1;
}
.modal-dialog.alt_modal_container--rounded .modal-body {
  padding-top: 0;
}
.modal-dialog.alt_modal_container--rounded .modal-body p {
  color: var(--darkColor);
  text-align: center;
  margin-bottom: 0;
  margin-left: -30px;
  margin-right: -30px;
}
.modal-dialog.alt_modal_container--rounded .modal-body .para_confirm_pipeline {
  color: var(--darkColor);
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-bottom: 30px;
}
.para_confirm_pipeline {
  color: var(--darkColor);
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-bottom: 0;
}
.modal-dialog.alt_modal_container.alt_modal_container--mobileNotSupport .title_modal_holidays {
  font-size: 25px;
}
.modal-dialog.alt_modal_container.alt_modal_container--mobileNotSupport .textForgotpassword--activation {
  font-size: 14px;
}
.modal-dialog.alt_modal_container.alt_modal_container--large {
  max-width: 100rem;
  width: 100%;
}
.modal-dialog.alt_modal_container.alt_modal_container--medium {
  max-width: 73rem;
  width: 100%;
}
.modal-dialog.alt_modal_container.alt_modal_container--listColumn {
  max-width: 64rem;
  width: 100%;
}
.modal-dialog.alt_modal_container.alt_modal_container--small {
  max-width: 60rem;
  width: 100%;
}
.modal-dialog.alt_modal_container.alt_modal_container--small .modal-content {
  border-radius: 0.625rem;
}
.modal-dialog.alt_modal_container.alt_modal_container--small .modal-content .modal-btn {
  padding: 0.625rem 1.313rem;
}
.modal-dialog.alt_modal_container.alt_modal_container--low {
  max-width: 47.5rem;
  width: 100%;
}
.modal-dialog.alt_modal_container.alt_modal_container--hours_details {
  max-width: 34rem;
  width: 100%;
}
.modal-dialog.alt_modal_container.alt_modal_candidat .modal-header {
  padding: 60px 45px !important;
}
.modal-dialog.alt_modal_container.alt_modal_candidat .modal-body {
  padding: 55px 95px;
}
.mt-48 {
  margin-top: 3rem !important;
}
.mt-13 {
  margin-top: 0.813rem;
}
/* 
  **** End Custom Modal ****
 */
.w-35 {
  width: 35%;
}
.w-33 {
  width: 33%;
}
.w-23 {
  width: 23%;
}
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.grey_card {
  background-color: var(--greyColor);
  width: 100%;
}
.grey_card--sm_radius {
  border-radius: 0.625rem;
  padding: 2.188rem 4.125rem;
}
.grey_card--md_radius {
  border-radius: 0.938rem;
  padding: 2.375rem;
}
.grey_card--md_padding {
  padding: 1.125rem 2.25rem 2.063rem;
}
.grey_card--gutters {
  margin-top: 3.25rem;
}
.grey_card--sm_padding {
  border-radius: 0.625rem;
  padding: 1.375rem 1.688rem;
}
.grey_card--modal_title {
  padding: 1rem;
  margin-top: 2.188rem;
}
.grey_card--badge_wrapper {
  border-radius: 8px;
  color: #444444;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  white-space: nowrap;
  padding: 0.688rem;
}
.grey_card .list.list_item {
  height: 0;
  margin: 0;
}
.grey_card .list.list_item .content_custom_radio .custom_radio {
  margin: 0 19px 0 0;
}
.grey_card .list.list_item .name_group {
  overflow: unset;
  font-size: 12px;
}
/* Altee Buttons */
.altee_btn {
  border: transparent;
  border-radius: 0.125rem;
  font-size: 0.938rem;
  line-height: 1.438rem;
  letter-spacing: 0.02em;
  text-align: center;
  outline: none;
  padding: 1rem 0;
  min-width: 13.688rem;
  white-space: nowrap;
}
.altee_btn svg {
  display: inline-block;
  margin-right: 9px;
  vertical-align: middle;
}
.altee_btn--black {
  background-color: black;
  color: var(--whiteColor);
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.altee_btn--black:hover {
  background-color: #333333;
  border-color: transparent;
}
.altee_btn--blue {
  min-width: 13.063rem !important;
  background-color: #3D4C63;
  color: var(--whiteColor);
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  margin-top: 0 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
}
.altee_btn--blue:hover {
  background-color: #597092;
  border-color: transparent;
}
.altee_btn--green {
  background-color: #73c7b7;
  border: 1px solid #73c7b7;
  color: var(--whiteColor);
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.altee_btn--green:hover {
  border-color: transparent;
  background-color: #5eb1a2;
}
.altee_btn--largeFix {
  max-width: 300px;
  width: 100%;
}
.altee_btn--medium {
  padding: 0.645rem 1.786rem;
}
.altee_btn--sm {
  font-size: 12px;
  padding: 10px 23px;
  min-width: 6.875rem;
  margin-right: 1.813rem;
}
.altee_btn--sm:last-child {
  margin-right: 0;
}
.altee_btn--sm_fixed {
  padding: 10px 0;
  width: 8.375rem;
  min-width: 8.375rem;
}
.altee_btn--md {
  font-size: 13px;
  padding: 10px 23px;
  min-width: 11.188rem;
  margin-right: 1.813rem;
  border-radius: 3px;
}
.altee_btn--md:last-child {
  margin-right: 0;
}
.altee_btn--md_fixed {
  padding: 10px 0;
  width: 8.375rem;
  min-width: 8.375rem;
}
.altee_btn--whiteIcon svg path {
  fill: white;
}
.altee_btn--green-outlined {
  border: 1px solid var(--greenColor);
  background: transparent;
  border-radius: 2px;
  color: var(--greenColor);
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.altee_btn--green-outlined:hover {
  background-color: #73c7b827;
}
.altee_btn--grey-outlined {
  border: 1px solid #7a858a;
  background: transparent;
  border-radius: 2px;
  color: #7a858a;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  transition: 250ms all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.altee_btn--grey-outlined:hover {
  background-color: var(--greyColor);
}
.altee_btn--recrues {
  font-size: 12px;
  padding: 10px;
  min-width: 6.875rem;
  width: 8.375rem;
}
.altee_btn--inactive {
  background-color: #e0e1e1;
  color: white;
  pointer-events: none;
}
.altee_btn .btn_icon {
  top: 50%;
  right: 5px;
  transform: translateY(-50%) rotate(0);
}
.altee_btn[disabled] {
  color: #5a5a5a;
  background-color: var(--greyColor) !important;
  border: solid 1px var(--borderLoginColor);
  border-radius: 3px;
  cursor: not-allowed;
  opacity: 0.7;
}
/* End Altee Buttons */
.field_gutter {
  margin-top: 0.563rem;
}
.white_card {
  background-color: var(--whiteColor);
  border-radius: 0.938rem;
  padding: 1.875rem;
  width: 100%;
}
.validate_btn {
  display: block;
  margin-top: 30px;
}
.tag_lists {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 10px;
  padding: 0;
  width: 100%;
}
.tag_lists_item {
  display: flex;
  align-items: center;
  background: rgba(115, 199, 183, 0.3);
  border-radius: 99px;
  color: #44796f;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  padding: 8px 12px;
  margin-top: 23px;
}
.tag_lists_item span {
  display: inline-block;
  margin-left: 8px;
  font-size: 20px;
  cursor: pointer;
}
.candidat_info_footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto 29px;
  width: 100%;
}
.candidat_info_footer button {
  max-width: 341px;
  width: 100%;
  margin-bottom: 15px;
}
.candidat_info_footer span {
  color: #636769;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
}
.btn-group button.dots_menu.btn {
  background: transparent;
  border: none;
  cursor: pointer;
  box-shadow: none;
}
.dots_submenu {
  background: var(--whiteColor);
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 28px 33px;
}
.list_bordered {
  border-bottom: 1px solid #ecedef;
  border-radius: 3px;
  color: #757a80;
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  padding: 20px 12px;
  transition: background 0.15s ease;
}
.list_bordered:last-child {
  border: none;
}
.list_bordered:hover {
  background: #ecedef;
}
.blackSvgColor svg path {
  fill: black;
}
.btn_linkedin {
  background: #007bb5;
  border-radius: 3px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0.688rem 1rem;
  margin-right: 1.063rem;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  max-width: 262px;
  width: 100%;
}
.btn_linkedin .icones_linkedin {
  margin-right: 0.813rem;
}
.text_green {
  display: inline-block;
  color: #488b7e;
}
.readonly {
  background-color: #e9ecef !important;
  cursor: inherit;
}
.readonly_border {
  border-color: #e9ecef !important;
}
.hour-worked {
  left: 80% !important;
  margin-left: 55% !important;
}
.hour-planned {
  left: 8px !important;
}
.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
.react-datepicker__day--keyboard-selected:not(:hover).react-datepicker__day--today.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
.content-header-week {
  cursor: default;
}
.text_validation_error {
  display: inline-block;
  color: #f8360b;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0;
  margin-top: 6px;
}
.no_data {
  display: inline-block;
  color: #97a3a9;
  font-family: PoppinsSemibold, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  width: 100%;
  text-align: center;
}
.table-materials.table_employer .title_table_employer {
  background-color: #f9fafc !important;
  font-size: 13px;
  line-height: 19px;
  color: #616a6f !important;
  text-transform: none;
}
.table-materials.table_employer .title_table_employer:first-child {
  position: sticky;
  left: 0;
  top: auto;
  background-color: white !important;
  color: #44444460 !important;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
}
.table-materials.table_employer .title_table_employer_materiel {
  background-color: #f9fafc !important;
  font-size: 13px;
  line-height: 19px;
  color: #616a6f !important;
  text-transform: none;
}
.table-materials.table_employer .tr_head_table_modele {
  border-bottom: 0 !important;
}
.table-materials.table_employer th:first-child,
.table-materials.table_employer td:first-child {
  position: sticky;
  left: 0;
  top: auto;
  background-color: white;
  box-shadow: 3px 0 8px #f5f6f8;
  text-align: center;
}
.table-materials.table_employer th:first-child::before,
.table-materials.table_employer td:first-child::before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  /* do not use top and bottom */
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-shadow: 3px -1px 6px #e0e1e2;
  -webkit-box-shadow: 3px -1px 6px #e0e1e2;
}
.no_background {
  background: none !important;
}
.tips {
  display: inline-block;
  color: #97a3a9;
  font-family: PoppinsSemiBold;
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 5px;
}
.avatar_employer {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  min-width: 2.625rem;
}
.avatar_employer--medium {
  width: 1.875rem;
  height: 1.875rem;
}
.badge_simple {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  min-width: 2.813rem;
  padding: 0.188rem;
  margin-left: 0.575rem;
}
.badge_simple--success {
  background-color: rgba(115, 199, 183, 0.4);
  color: #468579;
}
.badge_simple--danger {
  background-color: rgba(243, 98, 66, 0.2);
  color: #f36242;
}
.badge_simple--warning {
  background-color: rgba(245, 217, 84, 0.4);
  color: #bda018;
}
.badge_simple--random {
  background: rgba(194, 228, 249, 0.3);
  color: #5090b2;
  margin: 0;
  width: fit-content;
  padding: 0.313rem 0.875rem;
}
.badge_simple .badge_icon {
  margin-right: 8px;
}
.badge--circle {
  position: absolute;
  top: -50%;
  right: -50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f64d4d;
  color: #ffffff;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(20%);
  overflow: hidden;
  white-space: nowrap;
}
.badge--circleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f64d4d;
  color: #ffffff;
  font-size: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
}
.empty-table {
  background: var(--greyColor);
  color: #7a858a;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  height: 345px;
  width: 100%;
}
.w-12 {
  width: 12.188rem;
}
.text_align_center {
  text-align: -webkit-center !important;
}
.altee_circle_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.altee_circle_btn > img {
  width: 10.35px;
  height: 10.35px;
}
.altee_circle_btn--medium {
  width: 30px;
  height: 30px;
}
.altee_circle_btn--medium > img {
  width: 10px;
  height: 10px;
}
.altee_circle_btn--large {
  width: 40px;
  height: 40px;
}
.altee_circle_btn--delete {
  margin-left: 0.625rem;
}
.altee_circle_btn--small {
  width: 27px;
  height: 27px;
}
.altee_circle_btn--xsmall {
  width: 24px;
  height: 24px;
}
.altee_circle_btn--xsmall > img {
  width: 8px;
  height: 8px;
}
.altee_circle_btn--orange {
  background-color: var(--firstColor);
}
.altee_circle_btn--orange:hover {
  background-color: var(--secondColor);
}
.altee_circle_btn--green {
  background-color: var(--greenColor);
}
.altee_circle_btn--green:hover {
  background-color: #5eb1a2;
}
.altee_circle_btn--blue {
  background-color: var(--loaderBlue);
}
.altee_circle_btn--blue:hover {
  background-color: var(--loaderParams);
}
.altee_circle_btn--darkBlue {
  background-color: var(--loaderParams);
}
.altee_circle_btn--darkBlue:hover {
  background-color: var(--loaderBlue);
}
.altee_circle_btn--lightGrey {
  background-color: #f5f6f8;
}
.altee_circle_btn--lightGrey:hover {
  background-color: #d5d5d6;
}
.altee_circle_btn--greyDisabled {
  background-color: #D0D0D0;
}
.altee_circle_btn--black {
  background-color: black;
}
.altee_circle_btn--black:hover {
  background-color: #333333;
}
.altee_circle_btn--transparent {
  background-color: transparent;
}
.altee_circle_btn--transparent:hover {
  background-color: lightgrey;
}
.altee_svg_btn {
  border: none;
  cursor: pointer;
  background: transparent;
  width: fit-content;
  transition: 250ms background cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.altee_svg_btn--darkBlue:hover > svg path:first-child,
.altee_svg_btn--darkBlue:hover > svg path:nth-child(8) {
  fill: var(--loaderBlue);
}
.altee_svg_btn .three_dots_loading {
  top: 50%;
  transform: translateY(-50%);
}
.altee_svg_btn--orange > svg {
  width: 100%;
  height: 100%;
}
.altee_svg_btn--orange:hover > svg path:first-child {
  fill: var(--secondColor);
}
.add_new_folder > svg path {
  transition: 250ms fill cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.add_new_folder:hover > svg path:not(:last-child) {
  fill: #5eb1a2;
}
.add_new_folder:hover > svg #path1 {
  fill: #5eb1a2;
}
.td_list_btn .link_folder svg path {
  transition: 250ms fill cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.td_list_btn .link_folder:hover svg path:first-child {
  fill: #5eb1a2;
}
.td_list_btn .link_folder:hover svg #path1 {
  fill: #5eb1a2;
}
.content_icon_comment svg path {
  transition: 250ms fill cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.content_icon_comment svg:hover path:first-child {
  fill: var(--secondColor);
}
.content_icon_comment--green svg:hover path:first-child {
  fill: #5eb1a2;
}
.altee_btn_gris_noDisable {
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 0.875rem;
  line-height: 21px;
  letter-spacing: 0.01em;
  width: auto;
  padding: 15px 2.1rem;
  white-space: nowrap;
  background: #F0F0F0;
  color: #A8A8A8;
  cursor: pointer;
}
.altee_btn_gris_noDisable:hover {
  color: #fff;
  background: #A8A8A8;
}
.altee_btn_random {
  display: flex;
  align-items: center;
  color: #3e7a9a;
  background: #e0f1fc;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 0.875rem;
  line-height: 21px;
  letter-spacing: 0.01em;
  width: auto;
  padding: 15px 22px;
  white-space: nowrap;
}
.altee_btn_random .icon {
  height: fit-content;
  width: auto;
  margin: 0 0 0 0.75rem;
}
.three_dots_loading {
  top: 50%;
  transform: translateY(-50%);
}
.align_initial {
  text-align: initial;
}
.altee_circle_white_btn > svg:hover circle {
  fill: var(--greenColor);
}
.altee_circle_white_btn > svg:hover path {
  fill: white;
  stroke: white;
}
.content-switch-groups {
  margin-top: 7px;
}
.altee_btn_border--black {
  background-color: transparent;
  color: black;
  border: 1px solid black !important;
}
.altee_btn_border--blue {
  min-width: 13.063rem !important;
  margin-top: 0 !important;
  background-color: transparent;
  color: #3D4C63;
  border: 1px solid #3D4C63 !important;
  border-radius: 4px !important;
  font-size: 13px;
}
.mt-23 {
  margin-top: 23px;
}
.mt-25 {
  margin-top: 25px;
}
.size_13 {
  font-size: 13px !important;
}
.size_15 {
  font-size: 15px !important;
}
.p-0-10 {
  padding: 0 10px;
}
.label_meduim {
  font-size: 14px;
  line-height: 26px;
  color: var(--darkColor);
}
.custom_input_with_control {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 2px;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.custom_input_with_control::placeholder {
  font-size: 13px;
  color: var(--darkColor);
}
.google-autocomplete .location-search-input[disabled='true'] {
  background: var(--whiteColor) !important;
  border: 1px solid #EDF2F2;
  border-radius: 10px;
  color: var(--darkColor);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 13px 30px 13px 18px;
  width: 100%;
}
.google-autocomplete #iconEdit {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 1rem;
}
.focus_transparent:focus {
  border: 1px solid transparent !important;
}
.max-height-0 {
  max-height: 0% !important;
}
.modal-content .content_name_and_profil {
  margin-top: 30px;
}
.modal-content .content_leave {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.modal-content .content_leave .items_leave {
  margin-bottom: 1.3rem;
}
.notification-description .title {
  color: #2E445F;
  font-size: 12px;
  line-height: 18px;
}
.notification-description .details {
  font-size: 11px;
  line-height: 16px;
}
.notification-description .details .key {
  color: #3D4B5B;
}
.notification-description .details .content {
  color: var(--textSmallGrey);
}
.rotate-90 {
  transform: rotate(90deg) !important;
}
.table-responsive {
  scrollbar-width: thin;
}

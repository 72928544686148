/* Using example */
:root {
  --greenCalendar: var(--loaderGreen);
}
.headerMenu_Mobile {
  display: none;
}
.headerMenu_Mobile .slider_menuMobiles {
  display: none;
}
.headerMenu_Mobile .slider_menuMobiles .rec-pagination {
  display: none;
}
.slider_menuMobiles .employerColorMenuSlider:hover,
.slider_menuMobiles .employerThemeMenu:hover,
.slider_menuMobiles .employerColorMenuSlider:focus,
.slider_menuMobiles .employerThemeMenu:focus,
.slider_menuMobiles .employerColorMenuSlider.active,
.slider_menuMobiles .employerThemeMenu.active {
  background: var(--loaderGreen) !important;
}
.slider_menuMobiles .employerColorMenuSlider:hover .link_headerMenu,
.slider_menuMobiles .employerThemeMenu:hover .link_headerMenu,
.slider_menuMobiles .employerColorMenuSlider:focus .link_headerMenu,
.slider_menuMobiles .employerThemeMenu:focus .link_headerMenu,
.slider_menuMobiles .employerColorMenuSlider.active .link_headerMenu,
.slider_menuMobiles .employerThemeMenu.active .link_headerMenu {
  color: var(--whiteColor) !important;
  background-color: var(--greenColor) !important;
}
.bouton_pointeuse_fixed {
  display: none;
  bottom: 1.938rem;
  right: 1rem;
  text-decoration: none;
  width: 3.313rem;
  height: 3.313rem;
  border-radius: 50%;
  overflow: hidden;
  background: var(--backgroundMobiles);
  border: 1px solid var(--whiteColor);
  box-shadow: 0 3px 4px rgba(176, 182, 184, 0.41);
  z-index: 4;
}
.padding_29 {
  margin: 1.688rem -1.813rem 4.375rem -1.75rem !important;
}
.input_file_mobile_certification {
  margin: 1.688rem -1.813rem 7.375rem -1.75rem !important;
}
.input_file_mobile_certification .liste_file_upload .item_file_upload {
  display: none;
}
.input_file_mobile_certification .liste_file_upload .item_file_upload:first-child {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.active_certification_mobile {
  display: none;
}
.input_file_mobile {
  display: none;
  background-color: var(--greyColor);
  padding: 0 1.688rem;
  margin: 1.688rem 0 4.375rem;
}
.input_file_mobile .upload {
  color: var(--darkColor);
  font-size: 1rem;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.input_file_mobile .arhUploadField {
  margin: 0;
  height: 4.5rem;
  width: 100%;
}
.input_file_mobile .arhUploadField .filePreview {
  width: 100%;
  top: 33rem;
  left: 1.8rem;
  margin-top: 100px;
}
.input_file_mobile .arhUploadField .progressSize {
  background-color: var(--greenColor) !important;
}
.input_file_mobile .arhUploadField .dzu-dropzone {
  border: none;
  min-height: 4.5rem;
}
.input_file_mobile .arhUploadField .dzu-dropzone .dzu-inputLabel {
  margin: 0;
  font-weight: 500;
}
.input_file_mobile .arhUploadField .dzu-dropzone .dzu-inputLabel .inputLabel {
  font-size: 0.688rem;
  background-color: var(--greenColor);
  padding: 0.5rem 1.063rem;
  border-radius: 3.25rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 155px;
}
.input_file_mobile .arhUploadField .dzu-dropzone .dzu-inputLabel .inputLabel .underlined {
  color: var(--whiteColor);
  text-decoration: none;
}
.modalCalendarMobiles {
  width: 100%;
}
.modal_calendar_mobile {
  max-width: 370px !important;
}
.modal_calendar_mobile .modal-content {
  border-radius: 2px;
  width: 100% !important;
  margin-left: 0 !important;
}
.modal_calendar_mobile .modal-body {
  padding: 0;
}
.modal_calendar_mobile .head_calendar_mobiles {
  width: 100%;
  height: 7.313rem;
  padding: 1.188rem;
  background: var(--greenCalendar);
}
.modal_calendar_mobile .title_calendar_mobiles {
  color: var(--whiteColor);
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.14em;
  margin-bottom: 1.188rem;
}
.modal_calendar_mobile .dates_calendar_mobiles {
  color: var(--whiteColor);
  font-size: 31px;
  line-height: 46px;
}
.modalCalendarMobiles {
  width: 100%;
}
.modal_calendar_mobile .modal-content {
  border-radius: 2px;
}
.modal_calendar_mobile .modal-body {
  padding: 0;
}
.modal_calendar_mobile .head_calendar_mobiles {
  width: 100%;
  padding: 1.188rem;
  background: var(--greenCalendar);
}
.modal_calendar_mobile .title_calendar_mobiles {
  color: var(--whiteColor);
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.14em;
  margin-bottom: 1.188rem;
}
.modal_calendar_mobile .dates_calendar_mobiles {
  color: var(--whiteColor);
  font-size: 31px;
  line-height: 46px;
}
.calendar_mobiles_custom {
  width: 100%;
}
.calendar_mobiles_custom .react-datepicker {
  width: 100%;
  border: 0;
  background: var(--whiteColor);
  border-radius: 0;
  margin-bottom: 1rem;
}
.calendar_mobiles_custom .react-datepicker__month-container {
  width: 100%;
}
.calendar_mobiles_custom .react-datepicker__day-names,
.calendar_mobiles_custom .react-datepicker__week {
  width: 100%;
  font-family: "Poppins_SemiBold";
}
.calendar_mobiles_custom .react-datepicker__day-name,
.calendar_mobiles_custom .react-datepicker__day,
.calendar_mobiles_custom .react-datepicker__time-name {
  width: 2.625rem;
  height: 2.625rem;
  text-transform: capitalize;
  text-align: center;
  padding-top: 0.5rem;
}
.calendar_mobiles_custom .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background: var(--whiteColor);
  border: 0;
}
.calendar_mobiles_custom .react-datepicker__header {
  position: relative;
}
.calendar_mobiles_custom .react-datepicker__current-month,
.calendar_mobiles_custom .react-datepicker-time__header,
.calendar_mobiles_custom .react-datepicker-year-header {
  text-transform: uppercase;
  font-weight: 400;
  position: absolute;
  top: 22%;
  left: 6%;
  font-size: 15px;
}
.calendar_mobiles_custom .react-datepicker__header__dropdown {
  width: 73%;
}
.calendar_mobiles_custom .react-datepicker__year-read-view--down-arrow,
.calendar_mobiles_custom .react-datepicker__month-read-view--down-arrow,
.calendar_mobiles_custom .react-datepicker__month-year-read-view--down-arrow {
  border-width: 0.35rem;
  margin-left: 0;
  margin-right: 1.5rem;
}
.calendar_mobiles_custom .react-datepicker__month-read-view--selected-month,
.calendar_mobiles_custom .react-datepicker__year-read-view--selected-year {
  font-size: 15px;
  font-weight: 400;
}
.calendar_mobiles_custom .react-datepicker__navigation--previous {
  top: calc(3.5rem / 2);
  right: 22%;
  left: inherit;
}
.calendar_mobiles_custom .react-datepicker__navigation--next {
  top: calc(3.5rem / 2);
  right: 6%;
}
.calendar_mobiles_custom .react-datepicker__year-dropdown,
.calendar_mobiles_custom .react-datepicker__month-dropdown,
.calendar_mobiles_custom .react-datepicker__month-year-dropdown {
  width: 100%;
  top: 0;
  left: 0;
  border: 0;
  max-height: 26rem;
}
.calendar_mobiles_custom .react-datepicker__year-option--selected {
  display: none;
}
.calendar_mobiles_custom .react-datepicker__year-option.react-datepicker__year-option--selected_year {
  background: var(--loaderGreen);
  border-radius: 70px;
  color: var(--whiteColor) !important;
}
.calendar_mobiles_custom .react-datepicker__year-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.calendar_mobiles_custom .react-datepicker__year-option,
.calendar_mobiles_custom .react-datepicker__month-option,
.calendar_mobiles_custom .react-datepicker__month-year-option {
  width: 7rem;
  margin: 0.3rem 0;
}
.calendar_mobiles_custom .react-datepicker__day--selected,
.calendar_mobiles_custom .react-datepicker__day--in-selecting-range,
.calendar_mobiles_custom .react-datepicker__day--in-range,
.calendar_mobiles_custom .react-datepicker__month-text--selected,
.calendar_mobiles_custom .react-datepicker__month-text--in-selecting-range,
.calendar_mobiles_custom .react-datepicker__month-text--in-range,
.calendar_mobiles_custom .react-datepicker__quarter-text--selected,
.calendar_mobiles_custom .react-datepicker__quarter-text--in-selecting-range,
.calendar_mobiles_custom .react-datepicker__quarter-text--in-range,
.calendar_mobiles_custom .react-datepicker__year-text--selected,
.calendar_mobiles_custom .react-datepicker__year-text--in-selecting-range,
.calendar_mobiles_custom .react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  color: var(--whiteColor) !important;
}
.calendar_mobiles_custom .react-datepicker__day--keyboard-selected:hover,
.calendar_mobiles_custom .react-datepicker__month-text--keyboard-selected:hover,
.calendar_mobiles_custom .react-datepicker__quarter-text--keyboard-selected:hover,
.calendar_mobiles_custom .react-datepicker__year-text--keyboard-selected:hover {
  border-radius: 50% !important;
}
.calendar_mobiles_custom .react-datepicker__day--keyboard-selected,
.calendar_mobiles_custom .react-datepicker__month-text--keyboard-selected,
.calendar_mobiles_custom .react-datepicker__quarter-text--keyboard-selected,
.calendar_mobiles_custom .react-datepicker__year-text--keyboard-selected {
  border-radius: 50% !important;
}
.calendar_mobiles_custom .react-datepicker__month {
  margin: 0;
}
.btn_liste_calendar_mobiles {
  width: 100%;
  padding: 1.75rem;
}
.btn_liste_calendar_mobiles .btn_calendar_mobiles {
  border: 0;
  background: var(--whiteColor);
  width: auto;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: var(--tableTitleBlack);
}
.btn_liste_calendar_mobiles .btn_calendar_mobiles:first-child {
  margin-right: 3.563rem;
}
.theme_mobiles_calendar_employer .react-datepicker__current-month,
.theme_mobiles_calendar_employer .react-datepicker-time__header,
.theme_mobiles_calendar_employer .react-datepicker-year-header {
  color: var(--greenCalendar);
  font-family: "Poppins_Regular";
}
.theme_mobiles_calendar_employer .react-datepicker__year-read-view--down-arrow,
.theme_mobiles_calendar_employer .react-datepicker__month-read-view--down-arrow,
.theme_mobiles_calendar_employer .react-datepicker__month-year-read-view--down-arrow {
  border-top-color: var(--greenCalendar) !important;
}
.theme_mobiles_calendar_employer .react-datepicker__month-read-view--selected-month,
.theme_mobiles_calendar_employer .react-datepicker__year-read-view--selected-year {
  color: var(--greenCalendar);
  opacity: 0;
}
.theme_mobiles_calendar_employer .react-datepicker__navigation--previous {
  border-right-color: var(--greenCalendar);
}
.theme_mobiles_calendar_employer .react-datepicker__navigation--next {
  border-left-color: var(--greenCalendar);
}
.theme_mobiles_calendar_employer .react-datepicker__month-text--keyboard-selected,
.theme_mobiles_calendar_employer .react-datepicker__quarter-text--keyboard-selected,
.theme_mobiles_calendar_employer .react-datepicker__year-text--keyboard-selected {
  background-color: var(--greenCalendar);
}
.theme_mobiles_calendar_employer .react-datepicker__day--today {
  color: var(--greenCalendar);
}
.theme_mobiles_calendar_employer .react-datepicker__day--today.react-datepicker__day--keyboard-selected {
  color: var(--whiteColor);
}
.theme_mobiles_calendar_employer .react-datepicker__year-option--selected_year,
.theme_mobiles_calendar_employer .react-datepicker__month-option--selected_month {
  color: var(--greenCalendar);
}
.theme_mobiles_calendar_employer .react-datepicker__day--selected,
.theme_mobiles_calendar_employer .react-datepicker__day--in-selecting-range,
.theme_mobiles_calendar_employer .react-datepicker__day--in-range,
.theme_mobiles_calendar_employer .react-datepicker__month-text--selected,
.theme_mobiles_calendar_employer .react-datepicker__month-text--in-selecting-range,
.theme_mobiles_calendar_employer .react-datepicker__month-text--in-range,
.theme_mobiles_calendar_employer .react-datepicker__quarter-text--selected,
.theme_mobiles_calendar_employer .react-datepicker__quarter-text--in-selecting-range,
.theme_mobiles_calendar_employer .react-datepicker__quarter-text--in-range,
.theme_mobiles_calendar_employer .react-datepicker__year-text--selected,
.theme_mobiles_calendar_employer .react-datepicker__year-text--in-selecting-range,
.theme_mobiles_calendar_employer .react-datepicker__year-text--in-range {
  background-color: var(--greenCalendar) !important;
}
.display_Mobile {
  display: none;
}
.alert-mobiles {
  display: none;
}
.alert-desktop {
  display: block;
}
.display_Desktop {
  display: block;
}
@media only screen and (max-width: 1600px) {
  .modal-dialog.alt_modal_container.alt_modal_container--large {
    max-width: 95%;
  }
  .modal-open .modal::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (max-width: 1160px) {
  .content_footer.container-fluid {
    display: none;
  }
  .display_Mobile {
    display: block;
  }
  .display_Desktop {
    display: none;
  }
  .alert-mobiles {
    display: block;
  }
  .alert-desktop {
    display: none;
  }
  .bouton_pointeuse_fixed {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .bouton_pointeuse_fixed .loader {
    background: transparent;
  }
  .bouton_pointeuse_fixed .loader .loaderBlue {
    background: var(--loaderBlue);
  }
  .bouton_pointeuse_fixed .loader .loaderOrange {
    background: var(--loaderOrange);
  }
  .bouton_pointeuse_fixed .loader .loaderGreen {
    background: var(--loaderGreen);
  }
  .bouton_pointeuse_fixed .active {
    margin-bottom: 0.5rem;
  }
  .bouton_pointeuse_fixed .active div {
    animation: loader 0.6s infinite alternate;
  }
  .bouton_pointeuse_fixed .loader > div {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 2px;
  }
  .bouton_pointeuse_fixed .loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  .bouton_pointeuse_fixed .loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }
  @keyframes loader {
    to {
      transform: translate(0, 5px);
    }
  }
  .bouton_pointeuse_fixed.active {
    border-left: 1px solid var(--btnNotifBorder);
    padding: 0.5rem;
  }
  .bouton_pointeuse_fixed.active .loader {
    display: flex;
  }
  .modal-dialog.alt_modal_container.alt_modal_container--mobileNotSupport {
    display: flex;
  }
}
@media only screen and (max-width: 850px) {
  .modal_delete_doc_mobile .txt_delete {
    padding: 0 !important;
  }
  .modal_delete_doc_mobile .txt_delete p {
    width: 100%;
    font-size: 27px;
    margin: 0;
  }
  .text_description {
    font-size: 0.75rem;
  }
  .icones_accept {
    margin-right: 1rem;
  }
  .main-content {
    overflow-x: hidden;
  }
  .main-content .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .headerMenuDesktop {
    display: none;
  }
  .headerMenu_Mobile {
    display: block;
  }
  .headerMenu_Mobile .slider_menuMobiles {
    display: block;
    padding: 1.25rem 1.063rem;
  }
  .headerMenu_Mobile .slider_menuMobiles .rec-carousel {
    height: 3.125rem !important;
    position: relative;
  }
  .headerMenu_Mobile .slider_menuMobiles .rec-carousel:before {
    content: "";
    position: absolute;
    z-index: 2;
    width: 26px;
    height: 76%;
    pointer-events: none;
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    background-color: var(--whiteColor);
    border-right: 20px solid var(--whiteColor);
    right: 0;
  }
  .headerMenu_Mobile .slider_menuMobiles .rec-slider,
  .headerMenu_Mobile .slider_menuMobiles .rec-item-wrapper {
    height: auto !important;
    width: auto !important;
  }
  .headerMenu_Mobile .slider_menuMobiles .form_menuSlider {
    background: var(--greyColor);
    border-radius: 57px;
    padding: 0.563rem 0.688rem;
    margin-right: 0.563rem;
    height: auto;
  }
  .headerMenu_Mobile .slider_menuMobiles .form_menuSlider .link_headerMenu {
    border: none;
    background-color: var(--greyColor);
    white-space: nowrap;
    font-size: 11px;
    line-height: 16px;
    color: var(--darkColor);
  }
  .link_support_arh {
    display: none;
  }
  .modal-body {
    padding: 0;
  }
  .filterDate .arhDatePicker {
    margin: 0 0 0.563rem;
  }
  .filterDate .arhDatePicker .input_datepicker_dropdown {
    width: 100%;
    background: var(--greyColor);
    padding: 0.938rem 3.438rem;
    border: none;
    border-radius: 3.5rem;
    font-size: 0.75rem;
  }
  .filterDate .arhDatePicker .icones_datepicker_dropdown {
    top: 50%;
    transform: translateY(-50%);
  }
  .filterDate .arhDatePicker .icon_shape {
    top: 1.513rem;
    right: 1.5rem;
  }
  .datepicker_dropMobile {
    position: initial !important;
  }
  .datepicker_vavation .react-datepicker__day--keyboard-selected,
  .datepicker_vavation .datepicker_modals_parcours .react-datepicker__month-text--keyboard-selected,
  .datepicker_vavation .datepicker_modals_parcours .react-datepicker__quarter-text--keyboard-selected,
  .datepicker_vavation .datepicker_modals_parcours .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
  }
  .react-datepicker {
    border: none;
  }
  .datepicker_modals_parcours .react-datepicker__month-container {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }
  .input_note_mobile {
    width: 100%;
    height: 6.938rem;
    background: var(--greyColor);
    border: 1px solid var(--borderColor);
    resize: none;
    padding: 1rem;
    border-radius: 0.813rem;
    font-size: 0.75rem;
  }
  .input_file_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .active_certification_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 1.8rem;
    margin-bottom: 0 !important;
  }
  .active_certification_mobile .inputLabel {
    padding: 1rem;
    text-align: center;
  }
  .active_certification_mobile .dzu-dropzone {
    min-height: 150px;
  }
  .input_modal_mobile {
    width: 100%;
    background: var(--greyColor);
    border: none;
    border-radius: 3.5rem;
    padding: 0.938rem 1.375rem;
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
  }
  .input_grey_color {
    width: 100%;
    background: var(--borderColor) !important;
    border: none;
    border-radius: 3.5rem;
    padding: 0.938rem 1.375rem;
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
  }
  .mobile_validator {
    position: relative;
    display: block;
    margin-bottom: 0.625rem;
    margin-top: -0.625rem;
    font-size: 0.75rem;
    margin-left: 0.625rem;
  }
  .main-content .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-x: hidden;
  }
  .modal-content {
    width: 100% !important;
    margin-left: 0;
    border-radius: 1.125rem;
  }
  .modal-body {
    padding: 0;
  }
  .employerSvgColor svg path {
    fill: var(--darkColor);
  }
  /* 
    **** Custom modal ****
   */
  .modal-dialog.alt_modal_container {
    max-width: 398px;
  }
  .modal-dialog.alt_modal_container .modal-content {
    border-radius: 1.125rem;
  }
  .modal-dialog.alt_modal_container .modal-content .modal-body {
    padding: 2.8rem 1.8rem;
  }
  .modal-dialog.alt_modal_container .modalContent {
    padding: 0;
  }
  .modal-dialog.alt_modal_container .modalHeader,
  .modal-dialog.alt_modal_container .comment_header {
    margin: 0;
  }
  .modal-dialog.alt_modal_container .content_modals_notes {
    padding: 0;
    margin-top: 2.8rem;
  }
  .modal-dialog.alt_modal_container .btn_footer_actions_modals {
    padding: 0;
    margin: 2.25rem 0 0;
  }
  .modal-dialog.alt_modal_container .txt_header span {
    padding: 0;
  }
  .modal-dialog.alt_modal_container .txt_delete p {
    font-size: 20px;
  }
  .close_modal,
  .closeButton {
    display: none;
  }
  .modalHeader .title,
  .modalHeader .txt_header span {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2.8rem;
  }
  .btn_footer_actions_modals {
    border-top: 0;
  }
  .modal-content input {
    background: var(--greyColor);
    border-radius: 57px !important;
    border: 0 !important;
  }
  .modalFooter {
    padding: 0;
    border-top: none;
  }
  .modal-btn {
    border-radius: 3.563rem !important;
  }
  /* 
    **** Custom modal ****
   */
  .datepicker_modals_parcours .react-datepicker-popper {
    width: 100%;
  }
  .spaceHeader_webcam .close_modal {
    display: flex;
  }
}
@media only screen and (max-width: 550px) {
  .Toastify .Toastify__toast-container {
    max-width: 95%;
    width: 100%;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .Toastify .Toastify__toast-container .Toastify__toast-body .content-toatr-info p {
    font-family: "Poppins_SemiBold", sans-serif;
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 414px) {
  .modal-dialog.alt_modal_container {
    width: 95%;
  }
}

.sidebar_connexion {
  background-image: linear-gradient(-45deg, #fcb698, #fcd499);
  width: calc(100% / 3);
  height: 100vh;
  position: fixed;
}
.container_background_image {
  width: 100%;
  padding: 3rem 0rem 3rem 3rem;
  height: 100%;
  position: relative;
}
.container_background_image .background_sidebar {
  width: 100%;
}
.content_headerConnexion {
  width: 5.438rem;
  top: 3.75rem;
  right: 4.688rem;
}
.content_headerConnexion .choice_language {
  width: 100%;
}
.content_headerConnexion .select_language {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #73c7b7;
  background-image: url(../../../image/icones/ShapeWhite.svg);
  background-repeat: no-repeat;
  background-position: 86% 50%;
  color: var(--whiteColor);
  fill: var(--whiteColor);
  font-size: 19px;
  border: 0;
  width: 78px;
  height: 38px;
  border-radius: 7px;
  padding: 5px 10px;
}
.content_headerConnexion svg path {
  fill: var(--whiteColor);
}
.content_headerConnexion .css-yk16xz-control {
  cursor: pointer !important;
  background-color: var(--greenColor);
  border: none !important;
  border-radius: 0.438rem !important;
}
.content_headerConnexion .css-yk16xz-control .css-1uccc91-singleValue {
  color: var(--whiteColor);
  font-size: 1.188rem;
}
.content_headerConnexion .form_group_faq .shapeNo_open {
  padding-left: 2px;
}
.content_headerConnexion .css-1pahdxg-control {
  cursor: pointer !important;
  background-color: var(--greenColor);
  border: none !important;
  border-radius: 0.438rem !important;
}
.content_headerConnexion .css-1pahdxg-control .css-1uccc91-singleValue {
  color: var(--whiteColor);
  font-size: 1.188rem;
}
.content_headerConnexion .css-1pahdxg-control:hover {
  cursor: pointer !important;
  background-color: var(--greenColor);
  border: none !important;
  border-radius: 0.438rem !important;
}
.content_headerConnexion .css-1pahdxg-control:hover .css-1uccc91-singleValue {
  color: var(--whiteColor);
  font-size: 1.188rem;
}
.content_headerConnexion .css-1wa3eu0-placeholder {
  text-transform: uppercase;
  color: var(--whiteColor);
  font-size: 1.188rem;
  font-family: "Poppins_medium";
}
.content_headerConnexion .css-26l3qy-menu .css-4ljt47-MenuList {
  border: 1px solid var(--borderColor);
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}
.content_headerConnexion .css-26l3qy-menu .css-4ljt47-MenuList .css-1mjny6b-option {
  padding: 0.5rem !important;
  display: flex;
  font-family: "Poppins_Semibold";
  color: var(--textGreyColor);
  justify-content: center;
}
.content_headerConnexion .css-26l3qy-menu .css-4ljt47-MenuList .css-1azl1kq-option {
  padding: 0.5rem !important;
  display: flex;
  font-family: "Poppins_Semibold";
  color: var(--textGreyColor);
  justify-content: center;
}
.content_headerConnexion .shapeNo_open,
.content_headerConnexion .shape_Open {
  right: 1rem;
}
.content_footerConnexion {
  width: 100%;
  padding: 0.5rem 0 0 0;
  right: 0;
  bottom: 10%;
}
.content_footerConnexion .link_footer {
  font-size: 0.938rem;
  text-align: center;
  text-decoration: underline;
  color: var(--textMediumGray);
}
.content_footerConnexion .link_footer:hover {
  text-decoration: none;
}
.btn_return_state {
  text-decoration: none;
}
.btn_return_state .icones_return {
  width: 21px;
}
.title_register_entreprise {
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.4;
  color: var(--darkColor);
  margin-bottom: 1.438rem;
}
.tabs_register_content {
  margin-bottom: 2.625rem;
}
.container_tabs {
  width: 70px;
  height: 70px;
  border-radius: 12px;
  background-color: var(--greyColor);
  margin-right: 0.5rem;
}
.container_tabs span {
  color: #D0D0D0;
}
.container_tabs.active {
  width: 70px;
  height: 70px;
  border-radius: 12px;
  background-color: #E7FDE6;
  opacity: 1;
  color: #5CAA76;
  font-size: 17px;
  margin-right: 0.5rem;
}
.container_tabs.active span {
  display: block;
}
.sidebar_text {
  font-size: 1.19791667vw;
  color: var(--whiteColor);
  position: absolute;
  top: 5.5vw;
  left: 3.758419vw;
}

.errorPage_container {
  padding: 5rem 10rem;
}
.errorPage_container .logo_Altee {
  margin-bottom: 100px;
}
.errorPage_container .number_error {
  font-size: 96px;
  color: var(--textGreyColor);
}
.errorPage_container .error_description {
  font-size: 20px;
  color: var(--textGreyColor);
}
.errorPage_container .error_logs {
  font-size: 10px;
  color: var(--textGreyColor);
}
.errorPage_container .footer_erreur {
  margin-top: 120px;
}
.errorPage_container .footer_erreur .modal-btn-retour {
  width: 470px;
  height: 2.5rem;
  font-size: 22px;
  border: solid 1px var(--firstColor);
  border-radius: 3px;
}
.errorPage_container .footer_erreur .modal-btn-retour.active-retour {
  color: var(--whiteColor);
  background-color: var(--firstColor);
}
.errorPage_container--success {
  padding: 2rem 17.438rem;
}
.errorPage_container--success .spaceDivider {
  margin: 2.125rem 0 5.375rem 0;
}
.errorPage_container--success .title_description {
  margin-bottom: 30px;
  width: 100%;
  font-size: 23px;
}
.errorPage_container--success .txt_description {
  width: 100%;
  font-size: 15px;
}
.errorPage_container--success .txt_description2 {
  font-size: 17px;
  width: 100%;
  margin-top: 30;
}
.errorPage_container--success .color_description {
  color: #393C40;
}
.errorPage_container--success .btn_accessAppli {
  margin: 3.938rem 0 2.688rem 0;
}
.errorPage_container--success .btn_accessAppli button {
  background: #2C2C31;
  width: 273px;
  padding: 16px 0;
}
.errorPage_container--success .btn_accessAppli .text_btn_accessAppli {
  font-size: 15px;
  color: #FFFFFF;
}

:root {
  --activebuttontele: #FFB1C1;
  --firstColor: #fcb698;
  --secondColor: #fa966b;
  --borderorange: #d98d75;
  --darkColor: #292929;
  --greyColor: #f9fafc;
  --whiteColor: #ffffff;
  --orangeGradient: linear-gradient(180.48deg,
      #fcb698 2.52%,
      var(--secondColor) 94.75%);
  --greyRegular: #727279;
  --greyMeduim: #808D93;
  --yellowColor: #ffd495;
  --btnAddBlack: #292929;
  --textGreyColor: #5a5a5a;
  --logintextColor: #5b5d60;
  --borderLoginColor: #e5e5e5;
  --tableBorderGrey: #ecedef;
  --colorSeletText: #fee4d9;
  --textSmallGrey: #97a3a9;
  --textCollapseGrey: #767676;
  --tableTitleBlack: #444444;
  --blueColor: #7da6d1;
  --greenColor: #73c7b7;
  --greenSwitch: #81dac9;
  --labelTextInput: #636769;
  --textClock: #b0bec5;
  --colorgrey: #ebebeb;
  --selectTextOrange: #fcc398;
  --titleTable: #7a858a;
  --borderColor: rgba(216, 216, 216, 0.5);
  --buttonteletravail: #fff;
  --borderteletravail: #B0BEC5;
  --activebordertele: #ED6581;
  --colorInputMobile: #8d999f;
  --borderSalary: #edf2f2;
  --colorSideBar: #9fa6aa;
  --colorListDropdown: #757a80;
  --colorCalendarFont: #48545a;
  --colorInput: #bfbfbf;
  --textMediumGray: #8f8f8f;

  // poiteuse
  --loaderBlue: #4472a3;
  --loaderOrange: var(--secondColor);
  --loaderGreen: #73c7b7;
  --loaderParams: #1a4e63;
  --loaderBabyblue: #95C5E7;

  // notifications
  --titleNotif: #37474f;
  --titleNotifActive: #aab1b4;
  --tilleDateNotif: #959595;
  --notifGras: #5a5a5a;
  --activeBtnGras: #3d3f3c;
  --redNotif: #e94916;
  --btnNotifBorder: #eeeff1;
  --backActiveBtn: #f3f3f3;
  --notification-title: #2E445F;

  // Color Menu Mobiles

  --backgroundMobiles: #2c2c31;
  --textWhiteUser: #fcfcfc;
  --textMenu: #cfdadf;

  // Color SVG shape mobile
  --colorShapeMobile: #393c40;

  // Coloration graph
  // parites
  --blueParite: #36a2eb;
  --greenParite: #4cc0c0;
  --yellowParite: #ffcc56;

  // Age
  --roseAge: #fdb0c0;

  // Prise congé
  --roseConge: #ffb1c1;
  --blueConge: #99d0f6;
  --yellowConge: #ffe7ab;

  // Anciennete
  --blueAnciennete: #9ad0f6;

  // Salary
  --yellowSalary: #ffe7ab;

  // Roulement
  --blueRoulement: #37a1eb;
  --redRoulement: #fe6383;
  --backgroundInputError: rgba(248, 54, 11, 0.05);
  --borderInputError: rgba(248, 54, 11, 0.45);

  --etiquetteCategorie: #D8E2F5;
  --etiquetteCategorieText: #1A4E63;
  --color_news: #4472A3;
  --rangeDate: #FFE6DC;
  --rangeDateGreen: #D6F5EF;

  --orange-notification: #FFC6AE;
  --orange-notification-background: #FFEFE8;
  --orange-notification-color: #F55611;
  // End Coloration graph
}

//Home page ARH
.containerHomepage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .lockscreen-wrapper {
    width: 50%;
    padding: 3rem;
    background: var(--whiteColor);
    border: 2px solid var(--secondColor);
    border-radius: 5px;
  }

  .lockscreen-logo {
    display: flex;
    background-color: var(--greyColor);
    align-items: center;
    margin-bottom: 3rem;

    .logoHomePage {
      width: 10rem;
    }

    .link_home {
      color: var(--darkColor);
      text-align: center;
      width: 100%;
      font-size: 1.1rem;
    }
  }

  .liste_btn_home {
    width: 100%;
    display: flex;
    flex-direction: column;

    .btn_connection {
      font-weight: bold;
      margin: 1rem 0;
      border-top: 1px solid var(--secondColor);
      color: var(--darkColor);
      padding: 0.5rem;
      border-bottom: 1px solid var(--secondColor);
      background-color: var(--whiteColor);

      &:hover,
      &:focus {
        border: 1px solid #9fa6aa;
        color: var(--whiteColor);
        background-color: var(--secondColor);
      }
    }
  }

  .lockscreen-footer {
    background-color: var(--greyColor);
    padding: 2rem;
    margin-top: 3rem;
    bottom: 0;
  }
}

//End Home page ARH
#sidenav-main {
  overflow-x: hidden;

  .container-fluid {
    position: relative;

    .logo_brand_arh {
      width: 100%;
      height: 8.25rem;
      background: var(--whiteColor);
    }

    .image_logo_arh {
      width: 100%;
      height: 100%;
      max-height: none;
      object-fit: contain;
    }

    .navbar-nav {
      padding: 0 !important;
      margin-bottom: 40%;
    }

    .navbar-collapse {
      &::before {
        margin: 0;
      }
    }
  }
}

.custom_navbar_arh {
  background-color: var(--greyColor);
  width: 100%;

  .text_header_arh {
    color: var(--darkColor) !important;
  }

  .title_header_arh {
    font-size: 25px;
  }

  .icones_notifications_header {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  .icone_make_notifications {
    // margin: 0 2.1rem;
  }

  .notification_exist {
    right: 3px;
    top: 5px;
  }
}

.container_custom_arh {
  background-color: var(--whiteColor);
  margin-top: 5.875rem;
  width: 100.7%;
  padding: 0 2%;
  padding-bottom: 3rem;
}

.header_arh_dashboard {
  padding: 1rem 2.5% 1rem 0 !important;
  position: fixed;
  background-color: var(--greyColor);
  z-index: 6;
  height: 5.875rem;
  width: calc((100% - 250px) - 3.5%);

  .container_header_arh {
    padding: 0 !important;
  }

  .background_language_page {
    background: #fff !important;
  }

  .space_avatar_head {
    margin-right: 2.375rem;
  }
}

.header_mobile {
  display: none;
  padding: 1rem 2rem !important;
  position: fixed;
  background-color: var(--whiteColor);
  z-index: 5;
  height: 5.188rem;
  width: 100%;
}

.container_header_arh_mobile {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.main-content {
  background-color: var(--greyColor);
  width: calc(100% - 250px);
  padding: 0 3%;
}

.headMenu-Mobile {
  display: none;

  .container-fluid {
    padding-bottom: 39px !important;
  }

  .tab-content {
    margin-bottom: 4rem;
  }
}

.content_footer.container-fluid {
  height: 2.5rem;
  position: fixed;
  bottom: 0;
  z-index: 5;
  background-color: var(--greyColor);
  color: #000000;

  .footer_container {

    position: absolute;
    top: 7px;
    right: 22rem;
    font-size: 13px;

    .logo_footer {
      width: 18.78px;
      height: 18.96px;
      margin-right: 11px;
    }
  }
}

.headerMenu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  position: relative;

  .headerMenu_items {
    // margin-right: 3rem;
    // border-bottom: 7px solid transparent;
    // padding: 1.5rem 0;
    padding: 1.5rem 0 31px 0;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */

    .link_headerMenu {
      border: none;
      background-color: var(--whiteColor);

      color: #7a858a;
      font-size: 15px;
      font-weight: bold;
      outline: none;

      ::selection {
        background-color: var(--greenColor);
      }
    }

    &:hover,
    &:focus,
    &.active {
      // border-bottom: 7px solid var(--secondColor);

      .link_headerMenu {
        color: var(--darkColor);

        ::selection {
          background-color: var(--greenColor);
        }
      }
    }

    &:nth-last-child(2) {
      margin-right: 0;
    }
  }

  .employerThemeMenu {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      right: 100%;
      bottom: 0px;
      background: var(--loaderGreen);
      height: 7px;
      -webkit-transition-property: right;
      -o-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      -o-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    &:hover:before,
    &:focus:before,
    &.active:before {
      right: 0;
    }
  }

  // .employerThemeMenu {

  //   &:hover,
  //   &:focus,
  //   &.active {
  //     border-bottom: 7px solid var(--loaderGreen);
  //   }
  // }
  .colorThemeSecond {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      right: 100%;
      bottom: 0px;
      background: var(--secondColor);
      height: 7px;
      -webkit-transition-property: right;
      -o-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      -o-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    &:hover:before,
    &:focus:before,
    &.active:before {
      right: 0;
    }
  }

  .headerMenu_items_simple {
    // margin-right: 8rem;
    margin-right: 3rem;

    &:nth-last-child(2) {
      margin-right: 0;
    }
  }

  .headerMenu_items_simple_ma_fiche {
    // margin-right: 3rem;
  }

  .separations_headerMenu {
    width: 100%;
    border-bottom: 1px solid var(--tableBorderGrey);
    bottom: 3px;
    position: absolute;
  }
}

// Customisation Slider Bar : dahsbord, liste employer, nouvelle, parametreEse

.standarLink {
  svg path {
    fill: var(--secondColor);
  }

  &.active {
    &:before {
      border-left: 9px solid var(--secondColor) !important;
    }
  }
}

.dahsboardLink {
  svg {
    #path1 {
      fill: var(--loaderBlue);
    }

    #path2 {
      fill: var(--secondColor);
    }

    #path3 {
      fill: var(--loaderBlue);
    }

    #path4 {
      fill: var(--loaderGreen);
    }
  }

  &.active {
    &:before {
      border-left: 9px solid var(--secondColor) !important;
    }
  }
}

.myFicheLink {
  svg {
    #path1 {
      fill: var(--loaderGreen);
    }

    #path2 {
      fill: var(--greyColor);
    }
  }

  &.active {
    &:before {
      border-left: 9px solid var(--loaderGreen) !important;
    }
  }
}

.listEmployersLink {
  svg path {
    fill: var(--loaderGreen);
  }

  &.active {
    &:before {
      border-left: 9px solid var(--loaderGreen) !important;
    }
  }
}

.parametersLink {
  svg path {
    fill: var(--loaderParams);
  }

  &.active {
    &:before {
      border-left: 9px solid var(--loaderParams) !important;
    }
  }
}

.newsLink {
  svg path {
    fill: var(--loaderBlue);
  }

  &.active {
    &:before {
      border-left: 9px solid var(--loaderBlue) !important;
    }
  }
}

.sidebar_arh {
  .title_menu_sidebar {
    display: inline-block;
    background-color: var(--greyColor);
    padding: 1rem;
    padding-left: 2rem;
    color: #3b4d56;
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 1rem;
  }

  .title_menu_sidebar_enterprise {
    background-color: var(--greyColor);
    padding: 1rem;
    padding-left: 2rem;
    color: var(--titleNotif);
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 1rem;

    &--enterprise {
      margin-top: 30px;
    }
  }

  .nav-link {
    padding: 0.5rem 0rem 0.5rem 2rem !important;
    font-size: 13px !important;
    margin: 0.5rem 0;

    svg {
      margin-right: 0.5rem;
      width: 20px;
    }

    &.active {
      font-weight: 800;
    }
  }

  .button_logout_footer {
    background: none;
    width: 100%;
    outline: none;
    padding: 0.5rem 1.5rem 0.5rem 0.6rem;
    bottom: 1rem;

    .logout_footer {
      margin-right: 0.5rem;
      width: 20px;
    }

    .log_out_text {
      font-size: 14px;
      line-height: 21px;
      text-transform: capitalize;
      color: var(--btnAddBlack);
    }
  }
}

.table td,
.table th {
  border-bottom: 1px solid #e9ecef;
}

/* .navbar-vertical.navbar-expand-md .navbar-collapse {
  margin-bottom: 3rem;
} */

@media only screen and (max-width: 1600px) {
  .headerMenu .headerMenu_items .link_headerMenu {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1500px) {
  // .main-content {
  //   // padding: 0 2% 2% 2%;
  // }

  .header_arh_dashboard {
    padding: 1rem 0.5% 1rem 0 !important;
  }

  .container_custom_arh {
    // padding: 0 1% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .headerMenu .headerMenu_items .link_headerMenu {
    font-size: 12px;
  }

  .container_custom_arh {
    margin-top: 8%;
    // padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 1280px) {
  .custom_navbar_arh {
    .title_header_arh {
      font-size: 20px;
    }
  }
}
@media only screen and (max-width: 1920px) {
  .react-datepicker-popper {
    width: 100%;
  }
}
@media only screen and (max-width: 1750px) {
  .react-datepicker-popper {
    width: 110%;
  }
  .container_statistiques .item_graph_statistique {
    width: 100% !important;
  }
  .container_statistiques .item_graph_statistique canvas {
    max-width: 628px;
    max-height: 314px;
  }
}
@media only screen and (max-width: 1680px) {
  .dropdown_select {
    width: 105%;
  }
  .dropdown_select_parametre {
    width: 105%;
  }
  .react-datepicker-popper {
    width: 120%;
  }
}
@media only screen and (max-width: 1600px) {
  .dropdown_select {
    width: 110%;
  }
  .dropdown_select_parametre {
    width: 110%;
  }
  .react-datepicker-popper {
    width: 130%;
  }
}
@media only screen and (max-width: 1440px) {
  .dropdown_select {
    width: 120%;
  }
  .dropdown_select_parametre {
    width: 120%;
  }
  .react-datepicker-popper {
    width: 140%;
  }
}
@media only screen and (max-width: 1400px) {
  .dropdown_select {
    width: 130%;
  }
  .dropdown_select_parametre {
    width: 130%;
  }
  .react-datepicker-popper {
    width: 150%;
  }
}
@media only screen and (max-width: 1366px) {
  .dropdown_select {
    width: 140%;
  }
  .dropdown_select_parametre {
    width: 140%;
  }
  .content_select_info .react-datepicker-popper {
    width: 160%;
    top: 28.5rem !important;
    left: auto !important;
    right: 0rem !important;
    transform: translate3d(0px, -407px, 0px) !important;
  }
  .datepicker_modals_parcours .react-datepicker-popper {
    width: 160%;
    top: 28.5rem !important;
    transform: translate3d(0px, -407px, 0px) !important;
  }
}
@media only screen and (max-width: 1280px) {
  .dropdown_select {
    width: 150%;
  }
  .dropdown_select_parametre {
    width: 150%;
  }
  .react-datepicker-popper {
    width: 170%;
  }
}
@media only screen and (max-width: 1160px) {
  .dropdown_select {
    width: 100%;
  }
  .dropdown_select_parametre {
    width: 100%;
  }
  .react-datepicker-popper {
    width: 100%;
  }
}

/**
* Input with loupe image
*/
.inputicone {
  width: 100%;
}
.input_with_loupe .input_loupe {
  padding: 0.719rem 3rem 0.719rem 2rem;
  border-radius: 5px;
}
.input_with_loupe input {
  padding: 0.719rem 3rem 0.719rem 2rem;
  border-radius: 5px;
}
.input_with_loupe input::placeholder {
  color: var(--darkColor);
}
.input_with_loupe .input_pays {
  padding: 0.813rem 2.5rem 0.813rem 0.438rem;
  border-radius: 5px;
}
.input_with_loupe .input_loupe,
.input_with_loupe input {
  width: 100%;
  border: 1px solid var(--borderColor);
  border-radius: 2px;
  color: var(--darkColor);
  font-size: 0.813rem;
  border-radius: 5px;
}
.input_with_loupe .icones_loup_input {
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  width: 1.063rem;
  height: 1.063rem;
  cursor: pointer;
}
.input_with_loupe .icones_loup_input_meduim {
  right: 1.875rem;
  top: 0.813rem;
  width: 1.063rem;
  height: 1.063rem;
  cursor: pointer;
}
.input_with_loupe ::placeholder {
  color: var(--darkColor);
}
.input_number_content {
  width: 100%;
}
.input_number_content input {
  width: 100%;
  border: 1px solid var(--borderColor);
  border-radius: 2px;
  color: var(--darkColor);
  font-size: 0.813rem;
  border-radius: 5px;
}
.input_number_content .input_number {
  padding: 0.719rem 2.5rem 0.719rem 1.2rem;
}
.vacancy_size {
  width: 100%;
}
.label_title_chanmp {
  top: 0;
  left: 0;
}
.content_input_chanmp {
  margin-top: 2.5rem;
}
.vacancy_solde_size {
  width: 100%;
}
.custom_InputVacance {
  width: 17.813rem;
  margin-right: 1.188rem;
}
.shape_Open {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
}
.shape_Open svg {
  transform: none;
}
.shapeNo_open {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
  /* svg path {
    fill: #73c7b7;
  } */
}
.shapeNo_open svg {
  transform: rotate(-90deg);
}
.reset_filter {
  position: absolute;
  cursor: pointer;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.reset_filter svg path {
  fill: #b6c1c6 !important;
}
.shape_number {
  top: 40%;
  transform: translateY(-50%);
  right: 1.438rem;
  height: 100%;
}
.input_simple {
  width: 100%;
  padding: 0.719rem 3rem 0.719rem 2rem;
  border: 1px solid var(--borderColor);
  border-radius: 2px;
  color: var(--darkColor);
  font-size: 0.813rem;
  border-radius: 5px;
}
.input_simple ::placeholder {
  color: var(--darkColor);
}
.items_filter .input_simple {
  margin-top: 0;
}
.focus_green:focus {
  border: 1px solid var(--greenColor) !important;
  caret-color: var(--greenColor);
}
.focus_orange:focus {
  border: 1px solid var(--firstColor) !important;
  caret-color: var(--firstColor);
}
.focus_blue:focus {
  border: 1px solid var(--loaderBlue) !important;
  caret-color: var(--loaderBlue);
}
.focus_blue_param:focus {
  border: 1px solid var(--loaderParams) !important;
  caret-color: var(--loaderParams);
}
.input_with_send {
  width: 0;
}
.input_with_send .input_send {
  width: 100%;
  padding: 5px 25px 5px 10px;
  background: var(--greyColor);
  border: 1px solid var(--borderColor);
  border-radius: 3px;
  color: var(--darkColor);
  font-size: 0.625rem;
  resize: none;
  overflow-wrap: anywhere;
}
.input_with_send .input_send .regular__control textarea {
  border: none;
  padding: 0.5rem;
}
.input_with_send .icon_send {
  height: 100%;
  right: 0.625rem;
}
.input_with_send .icon_send .icone_send_input {
  cursor: pointer;
}
.input_dateTime_horaire input {
  padding: 0.619rem 2rem 0.69rem;
  font-size: 12px;
  width: 100%;
  border: 1px solid var(--borderColor);
  border-radius: 2px;
  color: var(--darkColor);
}
.input_dateTime_horaire .shape_Open svg {
  transform: none !important;
}
.input_dateTime_horaire .icones_shape_date {
  top: 0.65rem;
  right: 1rem;
  transform: rotate(-90deg);
}
.input_dateTime_horaire .icones_calendar_date {
  left: 0.5rem;
}
.datepicker_createHours .container_datePickerDropdown .icones_shape {
  top: 1.1rem !important;
  transform: rotate(-90deg);
}
.input_view {
  right: -2px;
  width: 20%;
  height: 100%;
  cursor: pointer;
}
.location-search-input {
  width: 100%;
  padding: 0.719rem 3rem 0.719rem 2rem;
  border: 1px solid var(--borderColor);
  border-radius: 2px;
  color: var(--darkColor);
  font-size: 0.813rem;
  border-radius: 5px;
}
.icones_loading_loupe {
  width: 1.063rem;
  height: 1.063rem;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.autocomplete-dropdown-container {
  width: 100%;
  background: var(--whiteColor);
  box-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  top: 5rem;
  z-index: 2;
}
.autocomplete-dropdown-container .autocomplete-list {
  width: 100%;
  max-height: 20.188rem;
  height: 100%;
  overflow-y: scroll;
}
.autocomplete-dropdown-container .autocomplete-list::-webkit-scrollbar {
  width: 5px;
}
.autocomplete-dropdown-container .autocomplete-list .suggestion-item {
  padding: 14px 21px;
  font-size: 13px;
  color: #8D8D8D;
}
.autocomplete-dropdown-container .autocomplete-list .suggestion-item--active {
  padding: 14px 21px;
  font-size: 13px;
  color: #8D8D8D;
}
.autocomplete-dropdown-container .autocomplete-list .suggestion-item .prediction {
  color: var(--darkColor);
}
